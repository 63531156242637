@import "variables.scss";

/* forms */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
select,
.form-control {
  height: 60px;
  background-color: transparent;
  box-shadow: none;
  color: $hive-dark;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid rgba(120, 120, 120, 0.5);
  font-family: "Ubuntu", sans-serif;
}

input[type="text"] + .theme_button,
input[type="email"] + .theme_button,
input[type="url"] + .theme_button,
input[type="password"] + .theme_button,
input[type="search"] + .theme_button,
input[type="tel"] + .theme_button,
input[type="number"] + .theme_button,
input[type="date"] + .theme_button,
textarea + .theme_button,
select + .theme_button,
.form-control + .theme_button {
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: $hive-lighter;
  box-shadow: none;
  border-radius: 0 10px 10px 0;
  border: none;
  cursor: pointer;
}

.input-text-center input[type="text"],
.input-text-center input[type="email"],
.input-text-center input[type="url"],
.input-text-center input[type="password"],
.input-text-center input[type="search"],
.input-text-center input[type="tel"],
.input-text-center input[type="number"],
.input-text-center input[type="date"],
.input-text-center textarea,
.input-text-center select,
.input-text-center .form-control {
  text-align: center;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
.select-group,
.form-control {
  border: 1px solid rgba(120, 120, 120, 0.3);
}

/* select styling */
.form-control option {
  color: $hive-dark;
  background-color: $hive-lighter;
}

.form-control.invalid {
  box-shadow: 0 0 0 2px $hive-primary;
}

.form-control:focus {
  border-color: #787878;
  box-shadow: none;
}

.form-control:-moz-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control::-webkit-input-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control:focus:-moz-placeholder {
  color: transparent;
}

.form-control:focus::-moz-placeholder {
  color: transparent;
}

.form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

/*icons for forms */
form label + i,
form label + i {
  position: absolute;
  font-size: 14px !important;
  left: 20px;
  top: 23px;
  width: 1em;
  line-height: 1;
  text-align: center;
}

form label + i + .form-control,
form label + i + .form-control {
  padding-left: 45px;
}

form .with_icon {
  padding-left: 25px;
}

form .with_icon + [class*="fa-"],
form .with_icon + [class*="icon2-"] {
  position: absolute;
  top: 17px;
  left: 2px;
  font-size: 12px;
}

form .with_icon:focus + [class*="fa-"],
form .with_icon:focus + [class*="icon2-"] {
  color: $hive-secondary;
}

label {
  font-weight: inherit;
}

.checkbox label,
.radio label {
  font-weight: inherit;
}

.checkbox label[for],
.radio label[for] {
  font-weight: inherit;
}

.form-control + [class*="icon2-"] {
  position: absolute;
  font-size: 24px;
  left: 20px;
  top: 50%;
  color: $hive-primary;
}

.checkbox label[for] + input[type="checkbox"],
.checkbox label[for] + input[type="radio"],
.radio label[for] + input[type="checkbox"],
.radio label[for] + input[type="radio"] {
  left: 20px;
}

input[type="checkbox"],
input[type="radio"] {
  margin-top: 9px;
}

.form-wrapper.with_background,
.form-wrapper.muted_background,
.form-wrapper.cs,
.form-wrapper.ds,
.form-wrapper.ls {
  padding: 35px;
}

.has-error .form-control {
  border-color: $hive-primary;
}

.has-error label,
.has-error .help-block {
  color: $hive-primary;
}

/* carousel */
.carousel {
  overflow: hidden;
}

/* collapse accordion */
.panel-heading .panel-title {
  font-size: inherit;
  letter-spacing: 0;
  padding: 0;
  position: relative;
  font-family: "Ubuntu", sans-serif;
}

.panel-heading .panel-title > a {
  font-size: 18px;
  line-height: 1.3;
  display: block;
  padding: 18px 60px 19px 20px;
  background: $hive-primary;
  color: $hive-lighter;
  border-radius: 10px 10px 0 0;
  word-wrap: break-word;
}

@media (min-width: 1200px) {
  .panel-heading .panel-title > a {
    padding-left: 35px;
  }
}

.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover {
  color: $hive-lighter;
  background: $hive-primary;
}

.panel-heading .panel-title > a.collapsed:hover i,
.panel-heading .panel-title > a.collapsed:hover:after,
.panel-heading .panel-title > a:hover i,
.panel-heading .panel-title > a:hover:after {
  color: $hive-lighter;
}

.panel-heading .panel-title > a.collapsed {
  color: $hive-dark;
  background: #f2f2f2;
  border-radius: 10px;
}

.panel-heading .panel-title > a.collapsed i {
  color: $hive-primary;
}

.panel-heading .panel-title > a:after {
  content: "-";
  position: absolute;
  text-align: center;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 100;
  transition: all 0.2s ease-in-out 0s;
  color: #fff;
}

.panel-heading .panel-title > a.collapsed:after {
  content: "+";
  color: $hive-dark;
}

.panel-heading .panel-title > a > i {
  position: relative;
  font-size: 16px;
  padding-right: 10px;
  text-align: center;
  display: inline-block;
  line-height: 0;
}

.color2 .panel-heading .panel-title > a {
  background: $hive-secondary;
}

.color2 .panel-title > a.collapsed {
  background: #f2f2f2;
}

.color2 .panel-title > a.collapsed i {
  color: $hive-secondary;
}

.color2 .panel-title > a.collapsed:after {
  color: $hive-secondary;
}

.color2 .panel-title > a.collapsed:hover,
.color2 .panel-title > a:hover {
  background: $hive-secondary;
}

.color2 .panel-title > a.collapsed:hover i,
.color2 .panel-title > a.collapsed:hover:after,
.color2 .panel-title > a:hover i,
.color2 .panel-title > a:hover:after {
  color: #fff;
}

.panel {
  box-shadow: none;
}

.panel-group .panel {
  border-radius: 0;
  background-color: transparent;
}

.panel-default > .panel-heading {
  background-color: transparent;
  color: inherit;
  position: relative;
  border: none;
  border-radius: 0;
  padding: 0;
}

.panel-default {
  border: none;
}

.panel-group .panel + .panel {
  margin-top: 10px;
}

.panel-body {
  border: 1px solid #e4e4e4;
  border-top: none !important;
  background-color: transparent;
  border-radius: 0 0 5px 5px;
}

.panel-body .media-left {
  padding-right: 15px;
}

.panel-body .media-left .img-circle {
  max-width: 50px;
}

.panel-body .media-left img {
  max-width: 80px;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
  padding: 20px;
}

@media (min-width: 400px) {
  .panel-group .panel-heading + .panel-collapse .panel-body {
    padding: 30px 40px;
  }
}

.collapse-unstyled .panel {
  background-color: transparent;
  border: 1px solid #e4e4e4;
  border-width: 1px 0;
}

.collapse-unstyled .panel + .panel {
  margin-top: 0;
  border-top: none;
}

.collapse-unstyled .panel h4 {
  font-size: 18px;
  margin: 0;
}

.collapse-unstyled .panel h4 a {
  position: relative;
  display: block;
  padding: 18px 20px 10px 0;
}

.collapse-unstyled .panel h4 a:after {
  content: "-";
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 100;
  line-height: 56px;
}

.collapse-unstyled .panel h4 a.collapsed {
  padding-bottom: 18px;
}

.collapse-unstyled .panel h4 a.collapsed:after {
  content: "+";
}

@media (min-width: 992px) {
  .collapse-unstyled .panel h4 {
    font-size: 20px;
  }
}

.collapse-unstyled .panel-content {
  padding: 0 0 20px;
}

/* progress bars */
.progress-bar-title {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  margin-right: 50px;
}

.progress {
  overflow: visible;
  height: 5px;
  margin-bottom: 25px;
  position: relative;
  border-radius: 0;
  box-shadow: none;
  background-color: #e4e4e4;
  border-radius: 20px;
}

.ls .with_background .progress {
  background-color: $hive-lighter;
}

.ls.ms .with_background .progress {
  background-color: #e4e4e4;
}

.progress-bar {
  background-color: $hive-primary;
  color: inherit;
  box-shadow: none;
  height: 5px;
  border-radius: 2px;
}

.progress-bar span {
  position: absolute;
  right: 0;
  top: -29px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
}

.progress-bar-success {
  background-color: #72b946;
}

.progress-bar-info {
  background-color: $hive-blue;
}

.progress-bar-warning {
  background-color: $hive-secondary;
}

.progress-bar-danger {
  background-color: $hive-primary;
}

.progress-bar-color2 {
  background-color: $hive-secondary;
}

/* nav and tabs */
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

.nav-unstyled {
  list-style: none;
  padding: 0;
  margin: 0 -12px;
}

.nav-unstyled li {
  display: inline-block;
  margin-bottom: -1px;
}

.nav-unstyled li a {
  display: inline-block;
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
}

.nav-unstyled .active a {
  color: $hive-primary;
}

.nav-unstyled.color2 .active a {
  color: $hive-secondary;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-bottom: none;
}

.nav-tabs {
  position: relative;
  border-bottom: none;
  margin-top: 0px;
  z-index: 1;
}

.nav-tabs.half-width-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.nav-tabs.half-width-tabs > li {
  width: 50%;
  display: -ms-flexbox;
  display: flex;
}

.nav-tabs.half-width-tabs > li a {
  width: 100%;
}

.nav-tabs > li {
  margin-bottom: 0;
  margin-right: 2px;
}

.nav-tabs > li > a {
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 20px;
  margin-right: 0;
  margin-top: 0px;
  border: 1px solid rgba(120, 120, 120, 0.3);
  border-bottom: 0;
  background-color: $hive-lighter;
  color: #787878;
  border-radius: 10px 10px 0 0;
}

.small-tabs .nav-tabs > li > a {
  padding: 10px;
}

.nav-tabs > li > a i {
  position: relative;
  top: 2px;
  padding-right: 10px;
  font-size: 1.6em;
  display: inline-block;
  line-height: 0;
}

@media (min-width: 992px) {
  .nav-tabs > li > a {
    padding: 12px 28px;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .nav-tabs > li > a {
    padding: 19px 45px;
  }
}

@media (max-width: 400px) {
  .small-tabs .nav-tabs > li > a {
    font-size: 10px;
    padding: 8px;
  }
}

.one-third-tabs.nav-tabs {
  display: -ms-flexbox;
  display: flex;
}

.one-third-tabs.nav-tabs:before {
  display: none;
}

.one-third-tabs.nav-tabs > * {
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.one-third-tabs.nav-tabs > *:last-child {
  margin-right: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: $hive-primary;
  background-color: $hive-primary;
  color: $hive-lighter;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
  border-color: $hive-primary;
  background-color: $hive-primary;
  color: $hive-lighter;
}

.color2.nav-tabs > li.active > a,
.color2.nav-tabs > li.active > a:hover,
.color2.nav-tabs > li.active > a:focus {
  border-color: $hive-secondary;
  background-color: $hive-secondary;
  color: $hive-lighter;
}

.color2.nav-tabs > li > a:hover,
.color2.nav-tabs > li > a:focus,
.color2.nav-tabs.nav-justified > .active > a {
  border-color: $hive-secondary;
  background-color: $hive-secondary;
  color: $hive-lighter;
}

.vertical-tabs .nav > li > a {
  font-size: 18px;
  background-color: #f2f2f2;
  padding: 15px 40px 15px 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
  color: $hive-dark;
}

.vertical-tabs .nav > li > a i {
  position: relative;
  top: -2px;
  padding-right: 8px;
  width: 2em;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.vertical-tabs .nav > li > a:after {
  font-family: "rt-icons-2";
  content: "\e7c5";
  position: absolute;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  letter-spacing: 0;
}

.vertical-tabs.no-arrows .nav > li > a:after {
  display: none;
}

.vertical-tabs.no-arrows .nav > li > a {
  padding: 24px 0;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
  background: $hive-primary;
  color: $hive-lighter;
}

.vertical-tabs.color2 .nav > li.active a,
.vertical-tabs.color2 .nav > li > a:hover {
  background: $hive-secondary;
}

@media (min-width: 768px) {
  .vertical-tabs .nav > li.active {
    position: relative;
    z-index: 2;
  }
}

/* modal */
.modal-backdrop.in {
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.modal-content {
  border-radius: 1px;
}

.modal-content [class*="col-"]:last-child {
  margin-bottom: 0;
}

/* bootstrap tables */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 15px 15px;
  border-color: #e4e4e4;
}

.table {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.table td + th,
.table th + td,
.table th + th,
.table td + td {
  border-left: 1px solid #e4e4e4;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-bottom-width: 1px;
}

.table > tbody > tr > td.media-middle {
  vertical-align: middle;
}

.table-responsive {
  border: none;
}

/* bootstrap buttons */
.btn {
  padding: 22px 30px 22px;
  margin: 0 0 4px 0;
  font-weight: 700;
  border: none;
  border-radius: 0;
  outline: none;
  transition: all 0.3s ease-in-out 0s;
}

.btn:hover,
.btn:focus {
  outline: none;
}

/* media elements */
.media {
  margin-top: 17px;
}

.media .media-object {
  max-width: 100px;
}

li.media:before,
li.media:after {
  display: block;
  content: "";
  clear: both;
}

.media-right,
.media > .pull-right {
  padding-left: 20px;
}

.media-left,
.media > .pull-left {
  padding-right: 20px;
}

.media-left img,
.media-right img {
  max-width: 85px;
}

@media (min-width: 768px) {
  .big-left-media .media-left img,
  .big-left-media .media-right img {
    max-width: 100px;
  }
}

.small-media {
  margin-top: 10px;
}

.small-media .media-left {
  padding-right: 7px;
}

.small-media .media-right {
  padding-left: 7px;
}

.small-media .media-left img,
.small-media .media-right img {
  max-width: 40px;
}

@media (max-width: 499px) {
  .xxs-media-left {
    text-align: center;
  }

  .xxs-media-left .media-left {
    display: block;
    margin: 0 auto 20px;
    padding-right: 0;
  }
}

td .media-body {
  width: auto;
}

/* bootstrap dropdown menu */
.dropdown-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: none;
  z-index: 1001;
  font-size: 14px;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}

[class*="right"] .dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-menu > li > a {
  font-weight: 700;
  padding: 7px 20px;
}

.nav .open > .header-button,
.nav .open > .header-button:hover,
.nav .open > .header-button:focus {
  background-color: transparent;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: $hive-dark;
  background-color: transparent;
}

.dropdwon-menu-title {
  padding: 10px 30px;
}

.well {
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.embed-responsive-3by2 {
  padding-bottom: 66.666%;
}

.embed-responsive-1by1 {
  padding-bottom: 100%;
}

.embed-responsive iframe {
  background-color: #000;
}

.embed-placeholder:after {
  content: "\f04b";
  font-family: "FontAwesome";
  position: absolute;
  color: $hive-lighter;
  font-size: 24px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  text-align: center;
  padding-left: 5px;
  background-color: rgba(232, 82, 66, 0.9);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
  transition: all 0.2s ease 0s;
}

.embed-placeholder:hover:after {
  color: $hive-dark;
}

.alert {
  border-radius: 10px;
  border-width: 0;
  padding: 15px 30px;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Ubuntu", sans-serif;
}

.alert strong {
  font-weight: 500;
}

.alert i {
  position: relative;
  padding-right: 10px;
  font-size: 14px;
}

.alert:before {
  font-family: FontAwesome;
  font-size: 14px;
  margin-right: 10px;
}

.alert-success:before {
  content: "\f058";
}

.alert-info:before {
  content: "\f06a";
}

.alert-warning:before {
  content: "\f071";
}

.alert-danger:before {
  content: "\f057";
}

.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
  color: $hive-lighter;
}

.alert-success {
  background-color: #72b946;
}

.alert-info {
  background-color: $hive-blue;
}

.alert-warning {
  background-color: $hive-secondary;
}

.alert-danger {
  background-color: $hive-primary;
}

button.close {
  margin-top: 3px;
}

/* small in headings */
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  color: inherit;
  font-size: 80%;
  font-weight: inherit;
}

.media h1 small,
.media h2 small,
.media h3 small,
.media h4 small,
.media h5 small,
.media h6 small {
  display: block;
}

/* labels */
.label-success {
  color: $hive-lighter;
  background-color: #72b946;
}

.label-info {
  color: $hive-lighter;
  background-color: $hive-blue;
}

.label-warning {
  color: $hive-lighter;
  background-color: $hive-secondary;
}

.label-danger {
  color: $hive-lighter;
  background-color: $hive-primary;
}

/*caret*/
.caret {
  border: none;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid #e4e4e4;
  text-align: center;
  margin-left: 5px;
  vertical-align: baseline;
}

.caret:before {
  content: "\f107";
  font-family: FontAwesome;
}

/* helpers */
@media (max-width: 499px) {
  .hidden-xxs {
    display: none !important;
  }
}

/*
** General Styles for HTML tags
*/
html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
  font-size: 16px;
}

::-moz-selection {
  color: $hive-lighter;
  background-color: $hive-primary;
}

::selection {
  color: $hive-lighter;
  background-color: $hive-primary;
}

::-moz-selection {
  color: $hive-lighter;
  background-color: $hive-primary;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  line-height: 1.875rem;
  font-size: 16px;
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.1;
  margin-top: 1.1em;
  margin-bottom: 0.8em;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

p {
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

img,
figure {
  max-width: 100%;
  height: auto;
}

figcaption,
.entry-caption {
  padding: 5px 0 0;
}

figcaption p,
.entry-caption p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;
}

a:hover {
  color: $hive-secondary;
  text-decoration: none;
  transition: all 0.2s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
  outline: medium none;
  text-decoration: none;
}

a > img {
  transition: all 0.2s ease-in-out 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

a:hover > img {
  opacity: 0.8;
}

hr {
  border: none;
  height: 1px;
  margin-left: 0;
  margin-right: 0;
  background-color: #e4e4e4;
}

iframe {
  border: none;
  max-width: 100%;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

table {
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
}

table td,
table th {
  padding: 8px 10px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #e4e4e4;
}

table th {
  color: $hive-dark;
  font-weight: normal;
  vertical-align: middle;
  line-height: 1;
}

.table_template thead {
  background-color: $hive-primary;
  color: $hive-dark;
}

.table_template thead.light {
  background-color: $hive-lighter;
}

.table_template th {
  font-weight: 700;
}

.table_template td,
.table_template th {
  padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
  background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
  background-color: rgba(17, 17, 17, 0.05);
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

p .fa {
  vertical-align: middle;
}

/*
** Styled Lists
*/
ul,
ol {
  padding-left: 30px;
  margin-bottom: 20px;
}

.list1 ul,
ul.list1 {
  list-style: none;
  padding: 0;
}

.padding_30 .list1 ul,
.padding_30 ul.list1 {
  margin: 0;
}

.list1 li {
  position: relative;
  padding: 9px 0 10px 20px;
  border-bottom: 1px solid rgba(120, 120, 120, 0.2);
}

.muted_background .list1 li {
  border-color: rgba(255, 255, 255, 0.4);
}

.list1 li > .media {
  margin: 3px 0;
}

@media (min-width: 992px) {
  .list1 li {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.list1.big-padding li {
  padding-top: 22px;
  padding-bottom: 21px;
}

.list1.huge-padding li {
  padding-top: 37px;
  padding-bottom: 36px;
}

.list1.no-bullets li {
  padding-left: 0;
}

.list1.no-bullets li:before {
  display: none;
}

.list1 li:first-child {
  border-top: 1px solid rgba(120, 120, 120, 0.2);
}

.list1.no-top-border li:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.list1.no-bottom-border li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.list1 li:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: 12px;
  color: $hive-primary;
  position: absolute;
  left: 0;
  top: 10px;
  width: 7px;
  height: 7px;
}

.list2 ul,
ul.list2 {
  list-style: none;
  padding-left: 20px;
}

.list2 li {
  position: relative;
  padding: 5px 0 5px 0;
}

.big-v-spacing.list2 li {
  padding: 7px 0;
}

.list2 li:before {
  content: "\f0da";
  font-family: FontAwesome;
  position: absolute;
  font-size: 14px;
  line-height: 1em;
  left: -20px;
  top: 14px;
  color: $hive-primary;
}

.list2.color2 li:before {
  color: $hive-secondary;
}

.list2.no-bullets {
  padding-left: 0 !important;
}

.list2.no-bullets li:before {
  display: none;
}

.list2.checklist {
  padding-left: 25px;
}

.list2.checklist li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 1em;
  left: -25px;
  color: $hive-primary;
  width: auto;
  height: auto;
  background-color: transparent;
}

.list2.checklist.color2 li:before {
  color: $hive-secondary;
}

.list3 ol,
ol.list3 {
  counter-reset: li;
  list-style: none outside none;
  padding: 0;
}

.list3 li {
  position: relative;
  padding: 6px 0 6px 25px;
}

.list3 li.active:after {
  color: $hive-secondary;
}

.list3 li:after {
  content: counter(li) ".";
  counter-increment: li;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  color: $hive-primary;
}

.list3.color li:after {
  color: $hive-primary;
}

.list3.color2 li:after {
  color: $hive-secondary;
}

.list-unstyled .media {
  margin-top: 0;
  margin-bottom: 10px;
}

.list-unstyled .media:last-child {
  margin-bottom: 0;
}

.list-unstyled li:last-child > .media {
  margin-bottom: 0;
}

.list-unstyled .media-left {
  padding-right: 10px;
}

.list-unstyled .media-left img {
  border-radius: 50%;
}

.list-unstyled .media-right {
  padding-left: 10px;
}

.list-unstyled .media-right img {
  border-radius: 50%;
}

.list-unstyled .media-body .pull-right {
  margin: 0 20px;
}

.inline-list {
  list-style: none;
  margin: 0 -13px;
  padding: 0;
}

.inline-list > li {
  display: inline-block;
  padding: 0 13px;
}

.inline-list.small-padding {
  margin: 0 -8px;
}

.inline-list.small-padding > li {
  padding: 8px;
}

.four-cols-text ul {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .four-cols-text {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .four-cols-text {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .four-cols-text {
    column-count: 4;
  }
}

.three-cols-text ul {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .three-cols-text {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .three-cols-text {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .three-cols-text {
    column-count: 3;
  }
}

/*
** Buttons
*/
.theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type="submit"] {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 18px 30px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: $hive-lighter;
  background-color: $hive-dark;
  border-radius: 10px;
  border: none;
  transition: all 0.4s linear 0s;
  z-index: 1;
}

@media (min-width: 1600px) {
  .theme_buttons a,
  .theme_button,
  .wc-proceed-to-checkout .checkout-button,
  .buttons a,
  button,
  input[type="submit"] {
    padding: 23px 40px;
  }
}

.theme_button,
.theme_buttons a {
  margin-bottom: 10px;
  margin-right: 6px;
}

.theme_button:last-child,
.theme_buttons a:last-child {
  margin-right: 0;
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus,
input[type="submit"]:hover,
input[type="submit"]:active,
input[type="submit"]:focus,
.wc-proceed-to-checkout .checkout-button:hover {
  color: $hive-primary;
  opacity: 1;
  text-decoration: none;
  outline: none;
}

button:hover,
button:active,
button:focus,
input[type="submit"]:hover,
input[type="submit"]:active,
input[type="submit"]:focus,
.theme_buttons a:hover,
.theme_buttons a:active,
.theme_buttons a:focus,
.theme_button:hover,
.theme_button:active,
.theme_button:focus,
a.button:hover,
a.button:active,
a.button:focus {
  transition: all 0.05s linear 0s;
}

.theme_button[type="reset"] {
  display: none;
}

.theme_buttons.inverse a,
.theme_button.inverse {
  color: $hive-dark;
  background-color: transparent !important;
  border: 2px solid $hive-dark;
  padding: 16px 28px;
}

.theme_buttons.inverse a:hover,
.theme_buttons.inverse a:focus,
.theme_buttons.inverse a:active,
.theme_button.inverse:hover,
.theme_button.inverse:focus,
.theme_button.inverse:active {
  color: $hive-primary;
}

@media (min-width: 1600px) {
  .theme_buttons.inverse a,
  .theme_button.inverse {
    padding: 21px 38px;
  }
}

.theme_buttons.color1 a,
.theme_button.color1,
.button.alt,
.btn-accent,
.wc-proceed-to-checkout .checkout-button,
.form-submit [type="submit"] {
  color: $hive-lighter;
  background-color: $hive-primary;
}

.theme_buttons.color1 a:hover,
.theme_buttons.color1 a:focus,
.theme_buttons.color1 a:active,
.theme_button.color1:hover,
.theme_button.color1:focus,
.theme_button.color1:active,
.button.alt:hover,
.button.alt:focus,
.button.alt:active,
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active,
.form-submit [type="submit"]:hover,
.form-submit [type="submit"]:focus,
.form-submit [type="submit"]:active {
  color: $hive-dark;
}

.theme_buttons[class*="color"],
.theme_button[class*="color"] {
  color: $hive-lighter;
}

.theme_buttons[class*="color"]:hover,
.theme_buttons[class*="color"]:focus,
.theme_buttons[class*="color"]:active,
.theme_button[class*="color"]:hover,
.theme_button[class*="color"]:focus,
.theme_button[class*="color"]:active {
  color: $hive-blue;
}

.theme_buttons.color2 a,
.theme_button.color2 {
  background-color: $hive-secondary;
  border-color: $hive-secondary;
}

.theme_buttons.inverse[class*="color"]:hover,
.theme_buttons.inverse[class*="color"]:active,
.theme_buttons.inverse[class*="color"]:focus,
.theme_button.inverse[class*="color"]:hover,
.theme_button.inverse[class*="color"]:active,
.theme_button.inverse[class*="color"]:focus {
  color: $hive-dark;
}

.theme_buttons.color1.inverse a,
.theme_button.color1.inverse {
  color: $hive-primary;
  border-color: $hive-primary;
}

.theme_buttons.color2.inverse a,
.theme_button.color2.inverse {
  color: $hive-secondary;
  border-color: $hive-secondary;
}

.theme_buttons a:active,
.theme_button:active {
  top: 1px;
}

.muted_buttons a,
.muted_button {
  opacity: 0.2;
}

.small_buttons a,
.small_button {
  padding: 7px 10px;
  text-transform: uppercase;
  letter-spacing: 0;
}

.square_buttons a,
.square_button {
  padding: 0;
  width: 50px;
  line-height: 50px;
  margin: 0;
}

.round_buttons a,
.round_button {
  padding: 0;
  width: 60px;
  line-height: 60px;
  border-radius: 30px;
}

a.inactive {
  pointer-events: none;
}

.block_buttons a,
.block_button {
  display: block;
  width: 100%;
  text-align: center;
}

.min_width_button {
  min-width: 170px;
}

@media (min-width: 1600px) {
  .min_width_button {
    min-width: 200px;
  }
}

.two_lines_button {
  padding: 11px 35px;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"] {
  position: relative;
  top: 0.08em;
  padding-right: 0.1em;
}

p > i {
  font-size: 1.1em;
  line-height: 0;
}

p > i + [class*="rt-icon"] {
  margin-left: -0.75em;
}

.theme_buttons a > [class*="rt-icon"],
.theme_buttons a > [class*="fa-"],
.theme_button > [class*="rt-icon"],
.theme_button > [class*="fa-"] {
  line-height: 0;
  font-size: 1.5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.05em;
}

.form-inline [type="submit"],
.form-inline .btn,
.form-inline .theme_button {
  margin-bottom: 0;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 16px 16px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
}

.more-link,
.view-more {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
}

.more-link:after {
  content: "\f149";
  font-family: FontAwesome;
  padding-left: 4px;
}

.more-link:after {
  content: "\f149";
  position: relative;
  top: 0;
  transition: top 0.2s ease 0s;
  margin-top: 0px;
}

.more-link:hover:after {
  top: 2px;
}

.view-more:after {
  content: "\f178";
  position: relative;
  left: 0;
  transition: left 0.2s ease 0s;
  margin-left: 5px;
}

.view-more:hover:after {
  left: 5px;
}

:target:before {
  content: "";
  display: block;
  height: 150px;
  /* fixed header height*/
  margin: -150px 0 0;
  /* negative fixed header height */
}

/* vertical alignmetn in columns */
.table_section .row {
  min-width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  aside[class*="col-"] {
    margin-top: 60px !important;
  }
}

@media (min-width: 768px) {
  .table_section_sm .container {
    height: 100%;
  }

  .table_section.table_section_sm .row {
    display: table;
    width: calc(100% + 30px);
  }

  .table_section.table_section_sm > [class*="container"] > .row > [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

@media (min-width: 992px) {
  .table_section_md .container {
    height: 100%;
  }

  .table_section.table_section_md .row {
    display: table;
    width: calc(100% + 30px);
  }

  .table_section.table_section_md > [class*="container"] > .row > [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .offset-image {
    margin-top: -150px;
  }
}

@media screen and (min-width: 1200px) {
  .table_section_lg .container {
    height: 100%;
  }

  .table_section.table_section_lg .row {
    display: table;
    width: calc(100% + 30px);
  }

  .table_section.table_section_lg > [class*="container"] > .row > [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

@media screen and (min-width: 992px) {
  /* overlap featured person */
  .top-overlap {
    margin-top: -100px;
    max-width: 125%;
    margin-left: -25%;
  }

  .top-overlap-small {
    margin-top: -40px;
    max-width: 140%;
    margin-right: -20%;
    margin-left: -20%;
  }
}

@media (min-width: 1200px) {
  .top-overlap {
    margin-top: -180px;
  }
}

.section_full_height {
  min-height: 100vh;
}

.flex-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-row > [class*="col-"] {
  min-width: 300px;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
}

.flex-row > [class*="col-"] > * {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .flex-row > [class*="col-"].col-md-6 {
    -ms-flex: auto;
    flex: auto;
    width: 50%;
  }
}

@media (max-width: 991px) {
  .flex-row > [class*="col-"].col-sm-12 {
    -ms-flex: auto;
    flex: auto;
    width: 100%;
  }
}

.flex-row.flex-row-vertical-centered > [class*="col-"] > * {
  -ms-flex-pack: center;
  justify-content: center;
}

/* float clear fixes */
@media (min-width: 768px) and (max-width: 991px) {
  .clear-sm {
    clear: both;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .clear-md {
    clear: both;
  }
}

@media (min-width: 1200px) {
  .clear-lg {
    clear: both;
  }
}

@media (max-width: 499px) {
  .col-xxs-12 {
    width: 100%;
  }
}

@media (max-width: 499px) {
  .text-xxs-center {
    text-align: center;
  }
}

/*
** Common Sections Styles
*/
/*parallax*/
.image_cover,
.background_cover,
.parallax {
  background-size: cover;
}

.background_cover {
  background-position: center;
}

.image_cover {
  display: block;
  position: absolute;
  width: 50%;
  z-index: 2;
  top: 0;
  bottom: 0;
  background-position: 50% 50%;
}

.image_cover > img {
  visibility: hidden;
}

.image_cover > a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.image_cover.image_cover_right {
  right: 0;
}

.image_cover.small_width {
  width: 42%;
}

@media (max-width: 991px) {
  .image_cover {
    height: 0;
    padding-bottom: 66%;
    margin-bottom: -30px;
    width: 100%;
    position: relative;
  }

  .image_cover.small_width {
    width: 100%;
    padding-bottom: 100%;
  }
}

.image_cover + [class*="container"] [class*="col-"] > img,
.image_cover + [class*="container"] [class*="col-"] > a:after,
.image_cover + [class*="container"] [class*="col-"] > a > img {
  display: none;
}

.half_section .image_cover_left + .container .col-md-6 {
  padding-right: 0;
}

.half_section .image_cover_right + .container .col-md-6 {
  padding-left: 0;
}

.parallax {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 1199px) {
  .parallax {
    background-attachment: scroll !important;
    background-size: cover !important;
    background-position: center !important;
  }
}

.overlay_color.ls:after {
  background-color: $hive-lighter;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.overlay_color.ls.ms:after {
  background-color: #f7f7f7;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.overlay_color.cs:after {
  background-color: $hive-primary;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.overlay_color.cs.main_color2:after {
  background-color: $hive-secondary;
}

.overlay_color.ds:after {
  background-color: $hive-dark;
  opacity: 0.85;
}

.overlay_color.ds:after {
  background-color: $hive-dark;
  opacity: 0.9;
}

.overlay_color.ds.ms:after {
  background-color: #1f232b;
  opacity: 0.9;
}

.overlay_color.ds.bs:after {
  background-color: #00202b;
  opacity: 0.67;
}

.overlay_color.ds.black:after {
  background-color: #000;
  opacity: 0.6;
}

.texture_bg {
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

.muted_section:before {
  background-color: rgba(58, 61, 68, 0.1);
  opacity: 1;
}

.muted_section > *,
.radial_gradient > *,
.gradient > *,
.vertical_gradient > *,
.darken_gradient > *,
.diagonal_section > *,
.overlay_color > * {
  z-index: 4;
  position: relative;
}

.radial_gradient {
  position: relative;
  overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.darken_gradient,
.overlay_color {
  position: relative;
}

.muted_section:before,
.muted_section:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.darken_gradient:before,
.overlay_color:after,
.darken_gradient:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.muted_section:before,
.muted_section:after,
.overlay_color:after {
  opacity: 0.8;
}

.darken_gradient:before {
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
}

.overflow_hidden {
  overflow: hidden;
  position: relative;
}

.with_top_border,
.with_bottom_border,
.with_top_border_container .container,
.with_bottom_border_container .container {
  position: relative;
}

.with_top_border:before,
.with_bottom_border:after,
.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(120, 120, 120, 0.2);
  z-index: 1;
}

.ds .with_top_border:before,
.ds .with_bottom_border:after,
.ds .with_top_border_container .container:before,
.ds .with_bottom_border_container .container:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.row.with_top_border:before,
.row.width_bottom_border:after {
  left: 15px;
  right: 15px;
}

.ds.with_top_border:before,
.ds.with_bottom_border:after,
.ds.with_top_border_container .container:before,
.ds.with_bottom_border_container .container:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
  right: 15px;
  left: 15px;
}

.with_bottom_border:after,
.with_bottom_border_container .container:after,
.with_bottom_border_two_colors:after {
  top: auto;
  bottom: 0;
}

h2.section_header {
  margin-bottom: 0.5em;
  font-size: 32px;
  position: relative;
  word-wrap: break-word;
  line-height: 1;
  border-left: 2px solid $hive-primary;
  padding: 10px 0 10px 20px;
}

h2.section_header.hive-secondary-blc {
  border-left-color: $hive-secondary;
}

h2.section_header.hive-primary-blc {
  border-left-color: $hive-primary;
}

h2.section_header.dark {
  border-left-color: $hive-blue;
}

.text-center h2.section_header,
h2.section_header.text-center {
  border-left: none;
  padding: 0;
}

@media (min-width: 1200px) {
  h2.section_header {
    font-size: 40px;
    padding: 15px 0 15px 30px;
    margin-bottom: 0.75em;
  }
}

h2.section_header + .section-excerpt {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  h2.section_header + .section-excerpt {
    margin-top: 30px;
  }
}

h2.section_header:last-child {
  margin-bottom: 0;
}

.section-excerpt {
  font-size: 20px;
  font-style: italic;
}

/* animated elements */
.to_animate {
  visibility: hidden;
}

.no-js .to_animate,
.lt-ie10 .to_animate,
.lt-ie9 .to_animate {
  visibility: visible;
}

@media (min-width: 1230px) {
  .container {
    width: 1200px;
  }
}

.section_404 {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

/*
**utilites
*/
/*
**Helper Utilites
*/
/* layout */
.clear,
.clearfix {
  clear: both;
}

.alignleft,
.alignright {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .alignleft {
    float: left;
    margin: 5px 30px 15px 0;
    max-width: 50%;
  }

  .alignright {
    float: right;
    margin: 5px 0 15px 30px;
    max-width: 50%;
  }

  .alignright.one-third,
  .alignleft.one-third {
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .alignright.one-third,
  .alignleft.one-third {
    max-width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  .col-xs-12 .alignleft {
    margin: 5px 50px 30px 0;
  }

  .col-xs-12 .alignright {
    margin: 5px 0 30px 50px;
  }
}

.round {
  border-radius: 50%;
}

.rounded {
  border-radius: 10px;
}

.top_rounded {
  border-radius: 10px 10px 0 0;
}

.bottom_rounded {
  border-radius: 0 0 10px 10px;
}

.overflow_hidden {
  overflow: hidden;
  z-index: 1;
}

.inline-block {
  display: inline-block;
  max-width: 100%;
}

.display-block {
  display: block;
}

.with_padding {
  padding: 20px;
}

@media (min-width: 400px) {
  .with_padding {
    padding: 35px;
  }
}

@media (min-width: 400px) {
  .with_padding.small_padding {
    padding: 25px;
  }
}

@media (min-width: 1200px) {
  .with_padding.big-padding {
    padding: 55px 60px;
  }
}

.with_background {
  background-color: #f2f2f2;
}

.with_background .with_background {
  background-color: $hive-lighter;
}

.muted_background {
  background-color: #f2f2f2;
}

.with-border {
  border: 1px solid #e4e4e4;
}

.with-border.thick_border {
  border-width: 10px;
}

.with_shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.with_big_shadow {
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.1);
}

.no_top_border {
  border-top: none;
}

.no_bottom_border {
  border-bottom: none;
}

.bottom-color-border {
  position: relative;
}

.bottom-color-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: $hive-primary;
  z-index: 1;
}

.bottom-color-border.with-border:after {
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.bottom-color-border.color2:after {
  background-color: $hive-secondary;
}

.top_color_border {
  border-top: 3px solid $hive-primary;
}

@media (max-width: 399px) {
  .with_abs_button.with_padding {
    padding-bottom: 35px;
  }
}

.bottom-border {
  position: relative;
}

.bottom-border:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover {
  overflow: hidden;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
  position: relative;
  z-index: 2;
}

.before_cover:before,
.after_cover:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-corner {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}

.big {
  font-size: 32px;
  line-height: 1;
}

strong.big {
  font-size: 24px;
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
}

/* borders, colors and backgrouns */
.response,
.highlight,
.highlight1 {
  color: $hive-primary;
  border-color: $hive-primary;
}

.highlight2 {
  color: $hive-secondary;
  border-color: $hive-secondary;
}

.highlight3 {
  color: $hive-darker;
  border-color: $hive-darker;
}

.highlight4 {
  color: $hive-blue;
  border-color: $hive-blue;
}

.red,
.required {
  color: #fa5c5d;
}

.lightgrey {
  color: #bfbfbf;
}

.black {
  color: #1f232b;
  border-color: #1f232b;
}

.grey {
  color: $hive-dark;
  border-color: $hive-dark;
}

.light {
  color: $hive-lighter;
}

.fontcolor {
  color: #787878;
}

.greylinks a {
  color: #787878;
}

.greylinks a.social-icon {
  color: rgba(120, 120, 120, 0.6);
}

.greylinks a.social-icon:hover,
.greylinks a.social-icon:focus,
.greylinks a.social-icon:active {
  color: $hive-primary;
}

.darklinks a {
  color: $hive-dark;
}

.darklinks a:hover {
  color: $hive-primary;
}

.darklinks.color2 a:hover {
  color: $hive-secondary;
}

.colorlinks a {
  color: $hive-secondary;
}

.colorlinks a:hover {
  color: $hive-primary;
}

.underlined-links a {
  text-decoration: underline;
}

a.underline-link {
  text-decoration: underline;
}

.thin {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.weight-black {
  font-weight: 900;
}

.success_color {
  color: #72b946;
}

.info_color {
  color: $hive-blue;
}

.warning_color {
  color: $hive-secondary;
}

.danger_color {
  color: $hive-primary;
}

.light_bg_color {
  background-color: $hive-lighter;
}

.transp_bg {
  background-color: transparent !important;
}

.theme_bg {
  background-color: $hive-lighter;
}

/*dividers*/
.divider_20 {
  margin: 20px 0;
}

.divider_30 {
  margin: 30px 0;
}

.divider_40 {
  margin: 40px 0;
}

.divider_60_2 {
  width: 60px;
  height: 2px;
}

.text-center hr[class*="divider_"],
.text-center hr[class*="_divider"] {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.fontsize_12 {
  font-size: 12px;
}

.fontsize_14 {
  font-size: 14px;
}

.fontsize_16 {
  font-size: 16px;
}

.fontsize_18 {
  font-size: 18px;
}

.fontsize_20 {
  font-size: 20px;
  line-height: 30px;
}

.fontsize_24 {
  font-size: 24px;
}

.fontsize_26 {
  font-size: 26px;
}

.fontsize_28 {
  font-size: 28px;
}

.fontsize_30 {
  font-size: 30px;
}

.fontsize_32 {
  font-size: 32px;
}

/*alignment*/
.display_table {
  display: table;
  min-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.display_table_cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
  overflow: hidden;
  zoom: 1;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .pull-sm-right {
    float: right;
  }

  .pull-sm-left {
    float: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-left {
    text-align: left;
  }

  .text-sm-center {
    text-align: center;
  }

  .display_table_sm {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_sm {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
    zoom: 1;
    box-sizing: border-box;
  }
}

@media (min-width: 992px) {
  .pull-md-right {
    float: right;
  }

  .pull-md-left {
    float: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-left {
    text-align: left;
  }

  .text-md-center {
    text-align: center;
  }

  .display_inline_md {
    display: inline;
  }

  .display_table_md {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_md {
    display: table-cell;
    vertical-align: middle;
    float: none;
    zoom: 1;
    box-sizing: border-box;
  }
}

@media (min-width: 1200px) {
  .pull-lg-right {
    float: right;
  }

  .pull-lg-left {
    float: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-left {
    text-align: left;
  }

  .text-lg-center {
    text-align: center;
  }

  .display_table_lg {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_lg {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
    zoom: 1;
    box-sizing: border-box;
  }
}

@media (max-width: 767px) {
  .text-xs-right {
    text-align: right;
  }

  .text-xs-left {
    text-align: left;
  }

  .text-xs-center {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .hidden-xxs {
    display: none;
  }
}

.inline-dropdown {
  list-style: none;
  margin: 0;
}

.inline-dropdown > li {
  display: inline-block;
  margin: 0 -2px;
}

.small-text {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.small-text.small {
  font-size: 12px;
}

.hero-text {
  font-size: 40px;
  font-weight: bold;
  line-height: 0.8em;
}

@media (min-width: 992px) {
  .hero-text {
    font-size: 80px;
  }
}

.nowrap {
  white-space: nowrap;
}

.media.inline-block {
  display: inline-block;
}

.media.inline-block .media-body {
  width: auto;
}

.content-justify {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-justify.content-margins > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-justify.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap:before,
.flex-wrap:after {
  display: none;
}

.row.flex-wrap.v-center {
  -ms-flex-align: center;
  align-items: center;
}

.row.flex-wrap.v-center-content {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row.flex-wrap.v-center-content > [class*="col-"] {
  display: -ms-flexbox;
  display: flex;
}

.row.flex-wrap.v-center-content > [class*="col-"] > * {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.inline-content {
  margin-right: -9px;
  margin-left: -9px;
}

.inline-content > * {
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
  margin-bottom: 0;
  margin-left: 9px;
}

.inline-content > *.media {
  margin-top: 10px;
  margin-bottom: 10px;
}

.inline-content.v-spacing > * {
  margin-bottom: 10px;
}

.inline-content.v-spacing > *:last-child {
  margin-right: 5px;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .inline-content.big-spacing {
    margin-right: -13px;
    margin-left: -13px;
  }

  .inline-content.big-spacing > * {
    margin-right: 13px;
    margin-left: 13px;
  }
}

@media (min-width: 1600px) {
  .inline-content.big-spacing {
    margin-right: -21px;
    margin-left: -21px;
  }

  .inline-content.big-spacing > * {
    margin-right: 21px;
    margin-left: 21px;
  }
}

.item-meta .inline-content > * {
  margin-right: 10px;
  margin-left: 10px;
}

.display-flex {
  display: -ms-flexbox;
  display: flex;
}

.v-center {
  -ms-flex-align: center;
  align-items: center;
}

.content-3lines-ellipsis {
  display: block;
  display: -webkit-box;
  font-size: 1rem;
  height: 5.625rem;
  -webkit-line-clamp: 3;
  line-height: 1.875em;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .md-content-3lines-ellipsis {
    display: block;
    display: -webkit-box;
    font-size: 1rem;
    height: 5.625rem;
    -webkit-line-clamp: 3;
    line-height: 1.875em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .two-cols-text {
    column-count: 2;
  }

  .two-cols-text ul {
    margin: 0;
  }
}

/* media items - common side and vertical items */
.with-border .item-media,
.with-border .entry-thumbnail {
  margin: -1px;
  z-index: 2;
}

.with-border .item-media + header,
.with-border .entry-thumbnail + header {
  margin: 0 -1px;
}

.with-border.full-padding .item-media {
  margin: 0;
}

/* side and vertical items paddings */
.side-item .row {
  margin: 0;
}

.side-item .row [class*="col-"] {
  padding: 0;
  margin: 0;
}

.side-item.side-sm .item-content {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .side-item.side-sm .row {
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  .side-item.side-md .row {
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-md .row [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .side-item.side-md .col-lg-4 {
    width: 36%;
  }

  .side-item.side-md .col-lg-8 {
    width: 64%;
  }
}

@media (min-width: 992px) {
  .side-item.side-md.bottom-color-border:after {
    top: -1px;
    height: auto;
    width: 3px;
  }

  .side-item.side-md.bottom-color-border.left:after {
    left: auto;
  }
}

@media (min-width: 992px) {
  .side-item.side-md.right {
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-md.right [class*="col-"]:first-child {
    -ms-flex-order: 2;
    order: 2;
  }

  .side-item.side-md.right [class*="col-"]:nth-child(2) {
    -ms-flex-order: 1;
    order: 1;
  }
}

/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
  .no-content-padding [class*="col-xs-"][class*="col-xs-pull"] .item-content,
  .full-padding [class*="col-xs-"][class*="col-xs-pull"] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-xs-12 .item-content,
  .full-padding .col-xs-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
  .no-content-padding [class*="col-sm-"] .item-content,
  .full-padding [class*="col-sm-"] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*="col-sm-"][class*="col-sm-pull"] .item-content,
  .full-padding [class*="col-sm-"][class*="col-sm-pull"] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-sm-12 .item-content,
  .full-padding .col-sm-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
  .no-content-padding [class*="col-md-"] .item-content,
  .full-padding [class*="col-md-"] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*="col-md-"][class*="col-md-pull"] .item-content,
  .full-padding [class*="col-md-"][class*="col-md-pull"] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-md-12 .item-content,
  .full-padding .col-md-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
  .no-content-padding [class*="col-lg-"] .item-content,
  .full-padding [class*="col-lg-"] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*="col-lg-"][class*="col-lg-pull"] .item-content,
  .full-padding [class*="col-lg-"][class*="col-lg-pull"] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-lg-12 .item-content,
  .full-padding .col-lg-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

.vertical-item .item-content {
  position: relative;
}

.vertical-item.post .item-content {
  padding-top: 0;
}

.vertical-item.content-padding .item-content {
  padding: 30px 20px;
}

@media (min-width: 400px) {
  .vertical-item.content-padding .item-content {
    padding: 35px 40px;
  }
}

@media (min-width: 1200px) {
  .vertical-item.big-padding .item-content {
    padding: 55px;
  }
}

@media (min-width: 1200px) {
  .vertical-item.big-vertical-padding .item-content {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.vertical-item.content-absolute {
  position: relative;
}

.vertical-item.content-absolute .item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 2;
}

@media (min-width: 400px) {
  .vertical-item.content-absolute .item-content {
    padding: 35px 40px;
  }
}

@media (min-width: 1200px) {
  .vertical-item.content-absolute .item-content.big-padding {
    padding: 55px 60px;
  }
}

.vertical-item.content-absolute .item-content.ds {
  background-color: rgba(31, 35, 43, 0.8);
}

.vertical-item.content-absolute.v-center .item-content {
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.vertical-item.content-absolute.hover-content .item-content {
  transform: scaleY(0);
  transition: transform 0.3s ease 0s;
}

.vertical-item.content-absolute.hover-content:hover .item-content {
  transform: scaleY(1);
}

.vertical-item + .item-title {
  padding: 10px 30px;
}

.vertical-item + .item-title h2,
.vertical-item + .item-title h3,
.vertical-item + .item-title h4 {
  margin: 0;
}

.side-item.content-padding .item-content {
  padding: 25px 20px;
}

@media (min-width: 400px) {
  .side-item.content-padding .item-content {
    padding: 35px 40px;
  }
}

@media (min-width: 1200px) {
  .side-item.big-padding .item-content {
    padding: 55px 60px;
  }
}

.item-media-wrap {
  position: relative;
}

/* item media links */
.item-media-wrap {
  line-height: 0;
}

.item-media-wrap > * {
  line-height: 1.625rem;
}

.item-media {
  position: relative;
  overflow: hidden;
}

.item-media img {
  width: auto;
}

.media-links {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent !important;
}

.media-links div {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -25px;
  z-index: 4;
}

.media-links:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 35, 43, 0.6);
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.2s linear;
}

.media-links.color-overlay:before {
  opacity: 1;
}

[class*="-item"]:hover .media-links.color-overlay:before {
  opacity: 0;
}

[class*="-item"]:hover .media-links:before {
  opacity: 1;
}

.media-links.no-overlay:before {
  display: none;
}

.media-links.inverse:before {
  opacity: 1;
}

[class*="-item"]:hover .media-links.inverse:before {
  opacity: 0;
}

.media-links a.abs-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

[class*="-item"]:hover .media-links a.abs-link {
  opacity: 1;
}

[class*="-item"] .links-wrap a,
[class*="-item"] .links-wrap > span {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: -100px;
  border-radius: 30px;
  display: inline-block;
  z-index: 5;
  font-size: 16px;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"] .links-wrap a.p-view {
  color: $hive-lighter;
}

[class*="-item"] .links-wrap a.p-view:hover {
  color: $hive-primary;
}

[class*="-item"] .links-wrap a.p-link {
  color: $hive-dark;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

[class*="-item"] .links-wrap a.p-link:hover {
  color: $hive-primary;
}

[class*="-item"] .links-wrap a:before {
  font-family: "FontAwesome";
  content: "\f002";
}

[class*="-item"] .links-wrap a.p-link:before {
  content: "\f067";
  font-size: 14px;
}

[class*="-item"] .links-wrap > span {
  font-size: 24px;
}

.media-links .bottom-links {
  top: auto;
  bottom: -20px;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"]:hover .links-wrap a,
[class*="-item"]:hover .links-wrap > span {
  top: 0px;
  filter: alpha(opacity=100);
  opacity: 1;
}

[class*="-item"]:hover .media-links div {
  filter: alpha(opacity=100);
  opacity: 1;
}

[class*="-item"]:hover .media-links .bottom-links {
  bottom: 30px;
  opacity: 1;
}

.featured-post {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 0 0 100% 0;
}

.featured-post i {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #fff;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .featured-post {
    width: 60px;
    height: 60px;
  }

  .featured-post i {
    top: 19px;
    left: 19px;
  }
}

/*
** Header
*/
.header_white {
  color: #787878;
  background-color: $hive-lighter;
}

.header_white.affix {
  box-shadow: 0px 1px 15px 0 rgba(0, 0, 0, 0.07);
}

.header_darkgrey {
  color: $hive-lighter;
  background-color: $hive-dark;
}

.header_darkgrey.affix {
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
}

.header_darkgrey a[class*="socicon-"] {
  color: $hive-lighter;
}

.header_darkgrey .grey,
.header_darkgrey .black {
  color: $hive-lighter;
}

.header_darkgrey.with_top_border:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds:not(.ms) + .header_darkgrey > header:not(.ms) {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.affix.page_header [class*="container"]:before {
  background-color: transparent !important;
}

.header_color {
  background-color: $hive-primary;
}

@media (min-width: 768px) {
  .header_transparent_wrap {
    position: relative;
    height: 0 !important;
  }

  .header_transparent_wrap .header_transparent:not(.affix).header_darkgrey {
    background-color: rgba(31, 35, 43, 0.75);
  }

  .transparent_wrapper {
    position: relative;
    height: 0 !important;
  }

  .transparent_wrapper > *:not(.page_header_wrapper) {
    position: relative;
    z-index: 3;
    background-color: transparent;
  }

  .transparent_wrapper .page_header:not(.affix) {
    background-color: transparent;
  }
}

.page_header {
  transition: background 0.2s ease-in-out 0s, margin 0.6s ease-in-out 0s;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  z-index: 1000;
  margin: 0;
  padding: 0;
  height: auto;
}

.page_header.affix {
  position: fixed;
  z-index: 1001;
}

.page_header.mobile-active {
  z-index: 1002;
}

.page_header.affix-bottom {
  top: 0 !important;
  position: fixed;
}

.page_header [class*="display_table_cell"] {
  overflow: visible;
}

.page_header [class*="col-"] {
  min-height: 0;
}

@media (max-width: 1599px) {
  .page_header .header_right_buttons h5 {
    font-size: 16px;
  }

  .page_header .header_right_buttons .big {
    font-size: 24px;
  }
}

.page_header .header-button {
  line-height: 60px;
}

.header_right_buttons .inline-list.small-padding > li {
  padding-top: 0;
  padding-bottom: 0;
}

/* header floats */
.page_header:before,
.page_header:after {
  content: "";
  display: block;
  clear: both;
}

.page_header {
  height: auto;
}

.header_mainmenu {
  overflow: visible;
  width: 10000px;
  padding: 0 15px;
}

.header_right_buttons {
  white-space: nowrap;
  padding: 0 15px;
  text-align: right;
  overflow: visible;
}

.header_right_buttons.ls {
  background-color: transparent !important;
}

/*
** Side Header
*/
/* side header layout */
body {
  position: relative;
  left: 0;
}

body:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
}

body,
body:after,
.page_header_side {
  transition: all 0.6s ease-in-out;
}

body {
  transition-property: left, right;
}

.page_header_side {
  z-index: 1002;
  position: fixed;
  width: 270px;
  left: -270px;
  top: 0;
  bottom: 0;
}

.page_header_side.active-slide-side-header {
  box-shadow: 0px 0px 4px 3px rgba(50, 50, 50, 0.05);
}

.page_header_side ~ * .toggle_menu {
  display: none;
}

.page_header_side .widget {
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.page_header_side .social-icons {
  display: block;
  margin: 20px 30px 0;
}

.side_header_inner {
  padding: 10px 0 60px;
}

.toggle_menu_side {
  position: absolute;
  top: 5px;
  right: -65px;
  background-color: $hive-lighter;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px rgba(50, 50, 50, 0.05);
  border-radius: 0;
  z-index: 2;
}

.toggle_menu_side:before,
.toggle_menu_side span,
.toggle_menu_side span:before,
.toggle_menu_side span:after {
  content: "";
  display: block;
  width: 24px;
  position: absolute;
  background-color: $hive-dark;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  height: 2px;
}

.toggle_menu_side:before,
.toggle_menu_side span {
  left: 18px;
  top: 50%;
}

.toggle_menu_side span:before {
  opacity: 1;
  top: -6px;
}

.toggle_menu_side span:after {
  opacity: 1;
  top: 6px;
}

.active-slide-side-header .toggle_menu_side:before {
  transform: rotate(45deg);
}

.active-slide-side-header .toggle_menu_side span {
  transform: rotate(-45deg);
}

.active-slide-side-header .toggle_menu_side span:before,
.active-slide-side-header .toggle_menu_side span:after {
  opacity: 0;
}

.ds .toggle_menu_side {
  background-color: #3d3d47;
}

.ds .toggle_menu_side span,
.ds .toggle_menu_side span:before,
.ds .toggle_menu_side span:after,
.ds .toggle_menu_side:before {
  background-color: $hive-lighter;
}

.bs .toggle_menu_side {
  background-color: #00202b;
}

@media (max-width: 350px) {
  .toggle_menu_side {
    top: 0;
    right: -50px;
  }
}

.page_header_side.page_header_side_sticked .toggle_menu_side {
  transition: 0.6s ease-in-out;
  right: -65px;
  top: 5px;
}

.page_header_side.active-slide-side-header {
  left: 0;
}

.container {
  max-width: 100%;
}

@media (max-width: 767px) {
  .container {
    max-width: 500px;
  }
}

@media (min-width: 600px) {
  .page_header_side.page_header_side_sticked.active-slide-side-header {
    left: 0;
  }

  .page_header_side_sticked ~ * {
    transition: margin 0.6s ease-in-out;
  }

  .page_header_side_sticked ~ div .page_header {
    padding-left: 35px;
  }

  .page_header_side_sticked.active-slide-side-header ~ div .page_header {
    padding-left: 0;
    margin-left: 270px;
  }

  .page_header_side_sticked.active-slide-side-header ~ * {
    margin-left: 270px;
  }

  .page_header_side_sticked ~ .page_header_wrapper {
    margin-left: 0;
  }
}

.ts {
  background-color: rgba(0, 0, 0, 0);
}

/*
** Light Section - .ls
*/
.ls {
  background-color: $hive-lighter;
  color: #787878;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
  color: #1f232b;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a {
  color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover {
  color: $hive-primary;
}

.ls [class*="color_bg"] .highlight {
  color: $hive-lighter;
}

.ls .light_bg_color {
  color: $hive-dark;
}

.ls a {
  color: $hive-primary;
}

.ls a:hover {
  color: $hive-dark;
}

.ls .grey {
  color: $hive-dark;
}

.ls .highlight,
.ls .highlight1 {
  color: $hive-primary;
  border-color: $hive-primary;
}

.ls .highlight2 {
  color: $hive-secondary;
  border-color: $hive-secondary;
}

.ls .greylinks a {
  color: #787878;
}

.ls .greylinks a:hover,
.ls .greylinks a:focus,
.ls .greylinks a:active {
  color: $hive-primary;
}

.ls .greylinks a.social-icon:not(:hover) {
  color: #bfbfbf;
}

.ls .greylinks.color2 a:hover,
.ls .greylinks.color2 a:focus,
.ls .greylinks.color2 a:active {
  color: $hive-secondary;
}

.ls .darklinks a {
  color: $hive-dark;
}

.ls .darklinks a:hover {
  color: $hive-primary;
}

.ls .color2.darklinks a:hover {
  color: $hive-secondary;
}

.ls .highlightlinks a,
.ls .highlightlink {
  color: $hive-primary;
}

.ls .highlightlinks a:hover,
.ls .highlightlink:hover {
  color: $hive-dark;
}

.ls .highlight2links a,
.ls .highlight2link {
  color: $hive-secondary;
}

.ls .highlight2links a:hover,
.ls .highlight2link:hover {
  color: $hive-dark;
}

.ls .highlight3links a,
.ls .highlight3link {
  color: $hive-primary;
}

.ls .highlight3links a:hover,
.ls .highlight3link:hover {
  color: $hive-dark;
}

.ls .highlight4links a,
.ls .highlight4link {
  color: $hive-blue;
}

.ls .highlight4links a:hover,
.ls .highlight4link:hover {
  color: $hive-dark;
}

.ls .colorlinks a {
  color: $hive-secondary;
}

.ls .colorlinks a:hover {
  color: $hive-primary;
}

.ls .breadcrumb > li + li:before {
  color: #787878;
}

.ls .breadcrumb > .active {
  color: $hive-dark;
}

.ls .nav-unstyled .active a {
  color: $hive-primary;
}

.ls .nav-unstyled.color2 .active a {
  color: $hive-secondary;
}

.ls .theme_buttons a,
.ls .theme_button {
  color: $hive-lighter;
}

.ls .theme_buttons a:hover,
.ls .theme_buttons a:focus,
.ls .theme_buttons a:active,
.ls .theme_button:hover,
.ls .theme_button:focus,
.ls .theme_button:active {
  color: $hive-primary;
}

.ls .theme_buttons.inverse a,
.ls .theme_button.inverse {
  color: $hive-dark;
}

.ls .theme_buttons.inverse a:hover,
.ls .theme_buttons.inverse a:focus,
.ls .theme_buttons.inverse a:active,
.ls .theme_button.inverse:hover,
.ls .theme_button.inverse:focus,
.ls .theme_button.inverse:active {
  color: $hive-primary;
}

.ls .theme_buttons.color1.iverse a,
.ls .theme_button.color1.inverse {
  color: $hive-primary;
}

.ls .theme_buttons.color2.iverse a,
.ls .theme_button.color2.inverse {
  color: $hive-secondary;
}

.ls .theme_buttons[class*="color"],
.ls .theme_button[class*="color"] {
  color: $hive-lighter;
}

.ls .theme_buttons[class*="color"]:hover,
.ls .theme_buttons[class*="color"]:focus,
.ls .theme_buttons[class*="color"]:active,
.ls .theme_button[class*="color"]:hover,
.ls .theme_button[class*="color"]:focus,
.ls .theme_button[class*="color"]:active {
  color: $hive-dark;
}

.ls:not(.ms) + .page_copyright.ls {
  background-color: #f7f7f7;
}

.ls .select-group:hover select + .theme_button,
.ls .select-group:hover select + i {
  color: $hive-primary;
}

/*
** Grey Section - light section, muted section - .ls.ms
*/
.ls.ms {
  background-color: #f7f7f7;
}

.ls.ms.dark {
  background-color: #eaeaea;
}

.ls.ms .with_background {
  background-color: $hive-lighter;
}

.ls.ms .theme_bg .with_background {
  background-color: #f2f2f2;
}

.ls.ms hr {
  background-color: rgba(120, 120, 120, 0.2);
}

.ls.ms .with_background input[type="text"],
.ls.ms .with_background input[type="email"],
.ls.ms .with_background input[type="url"],
.ls.ms .with_background input[type="password"],
.ls.ms .with_background input[type="search"],
.ls.ms .with_background input[type="tel"],
.ls.ms .with_background input[type="number"],
.ls.ms .with_background textarea,
.ls.ms .with_background select,
.ls.ms .with_background .form-control {
  border-color: rgba(120, 120, 120, 0.2);
}

.ls.ms td,
.ls.ms th,
.ls.ms .with-border {
  border-color: #d7d7d7;
}

.ls.ms .bottom-color-border {
  border-bottom-color: $hive-primary;
}

.ls.ms .bottom_color2_border {
  border-bottom-color: $hive-secondary;
}

.ls.ms .widget_calendar tbody {
  background-color: $hive-lighter;
}

.ls.ms .owl-nav > div {
  background-color: #fff;
}

/*
** Dark Section and Dark Muted sections - .ds and .ds.ms
*/
/* darkgrey section - .ds */
.ds {
  // background-color: $hive-dark;
  // color: #e0e0e1;
  background-color: $hive-dark;
  background-color: darken($hive-dark, 15%);
  // background-color: $hive-dark;
  color: $hive-light;
}

.ds.parallax .theme-divider,
.ds.background_cover .theme-divider {
  // background-color: #fff;
  background-color: $hive-lighter;
}

/* dark section - .ds.ms */
.ds.ms {
  background-color: #1f232b;
}

/* dark section blue - .ds.bs */
.ds.bs {
  background-color: #00202b;
}

.ls .inverse-bg {
  background-color: $hive-dark;
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
  color: $hive-lighter;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
  color: inherit;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
  color: $hive-primary;
}

.ds .ls {
  background-color: $hive-lighter;
  color: #787878;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
  color: $hive-dark;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
  color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
  color: $hive-primary;
}

.ds .ls a {
  color: $hive-primary;
}

.ds .ls a:hover {
  color: $hive-dark;
}

.ds .ls .darklinks a {
  color: $hive-dark;
}

.ds .ls .darklinks a:hover {
  color: $hive-primary;
}

.ds .ls .greylinks a {
  color: #787878;
}

.ds .ls .greylinks a:hover {
  color: $hive-secondary;
}

.ds .ls .highlightlinks a:hover {
  color: $hive-dark;
}

.ds .ls hr {
  background-color: #e4e4e4;
}

.ds .ls .highlight {
  color: $hive-primary;
}

.ds .ls .grey {
  color: $hive-dark;
}

.ds label {
  color: $hive-lighter;
}

.ds a {
  color: $hive-lighter;
}

.ds a:hover {
  color: $hive-primary;
}

.ds hr {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .social-icon.color-icon:hover {
  color: $hive-lighter;
}

.ds .muted_background,
.ds .with_background,
.ds .well {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .inverse-bg {
  background-color: $hive-lighter;
}

.ds .theme_bg {
  background-color: #000;
}

.ds.muted_section:before {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .with-border {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .highlight,
.ds .highlight1 {
  color: $hive-primary;
  border-color: $hive-primary;
}

.ds .highlight2 {
  color: $hive-secondary;
  border-color: $hive-secondary;
}

.ds .grey,
.ds .black {
  color: $hive-lighter;
  border-color: $hive-lighter;
}

.ds .lightgrey {
  color: #e0e0e1;
}

.ds .darkgrey {
  color: #787878;
}

.ds .colorlinks a {
  color: $hive-secondary;
}

.ds .colorlinks a:hover {
  color: $hive-primary;
}

.ds .greylinks a {
  color: #e0e0e1;
}

.ds .greylinks a:hover {
  color: $hive-primary;
}

.ds .greylinks a.social-icon {
  color: #e0e0e1;
}

.ds .darklinks a,
.ds .darklink {
  color: $hive-lighter;
}

.ds .darklinks a:hover,
.ds .darklink:hover {
  color: $hive-primary;
}

.ds .highlightlinks a,
.ds .highlightlink {
  color: $hive-primary;
}

.ds .highlightlinks a:hover,
.ds .highlightlink:hover {
  color: $hive-lighter;
}

.ds .highlight2links a,
.ds .highlight2link {
  color: $hive-secondary;
}

.ds .highlight2links a:hover,
.ds .highlight2link:hover {
  color: $hive-lighter;
}

.ds.with_top_border:before,
.ds.with_bottom_border:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds input[type="number"],
.ds textarea,
.ds select,
.ds .form-control,
.ds .select-group {
  color: $hive-lighter;
  border-color: rgba(255, 255, 255, 0.5);
}

.ds .with_background .widget_search [type="text"],
.ds .with_background .widget_search [type="search"],
.ds .with_background .widget_product_search [type="search"],
.ds .with_background .widget_mailchimp [type="email"] {
  background-color: $hive-dark;
  border-color: rgba(255, 255, 255, 0.2);
}

.ds form label + [class*="icon-"] {
  color: #787878;
}

.ds option {
  color: $hive-dark;
}

.ds .form-group-select:before {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .form-control:-moz-placeholder {
  color: #e0e0e1;
}

.ds .form-control::-moz-placeholder {
  color: #e0e0e1;
}

.ds .form-control:-ms-input-placeholder {
  color: #e0e0e1;
}

.ds .form-control::-webkit-input-placeholder {
  color: #e0e0e1;
}

.ds .form-control:focus:-moz-placeholder {
  color: transparent;
}

.ds .form-control:focus::-moz-placeholder {
  color: transparent;
}

.ds .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.ds .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.ds .contact-form.transparent-background .form-control {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td,
.ds table th,
.ds table td {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds table th {
  color: $hive-lighter;
}

.ds .table_template th,
.ds .table_template td {
  border-color: transparent;
}

.ds .table_template th {
  color: inherit;
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon:hover {
  color: $hive-lighter;
}

.ds .color-bg-icon:hover {
  background-color: $hive-primary;
}

.ds .nav-unstyled {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .nav-tabs > li > a {
  border-color: $hive-lighter;
  color: $hive-lighter;
}

.ds .nav-tabs > li > a:hover,
.ds .nav-tabs > li.active > a,
.ds .nav-tabs > li.active > a:hover,
.ds .nav-tabs > li.active > a:focus {
  border-color: transparent;
  background-color: #787878;
  color: $hive-lighter;
}

.ds .price-table.style1 {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .plan-price p {
  color: $hive-lighter;
}

.ds .features-list .disabled:before {
  color: $hive-lighter;
}

.ds .features-list li + li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds ul.list1 li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .testimonials-carousel .media + p {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .post-adds .share_button > i,
.ds .post-adds .like_button > i {
  color: $hive-lighter;
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_nav_menu ul ul {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_popular_entries .media-heading {
  color: $hive-lighter;
}

.ds .tweet_list li + li .tweet_right {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_tag_cloud a {
  color: $hive-lighter;
}

.ds .widget_shopping_cart li a + a {
  color: $hive-lighter;
}

.ds .widget_shopping_cart li a + a:hover {
  color: $hive-primary;
}

.ds .widget .woocommerce-Price-amount {
  color: $hive-lighter;
}

.ds .summary .price {
  color: $hive-lighter;
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .summary .product_meta {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_price_filter .price_label {
  color: $hive-lighter;
}

.ds .widget_calendar table {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar tfoot td a {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar caption {
  color: $hive-lighter;
}

.ds .widget_calendar thead {
  background-color: #f2f2f2;
}

.ds .widget_calendar tbody {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar th {
  color: $hive-dark;
}

.ds .widget_calendar tbody td a:hover {
  color: $hive-lighter;
}

.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_pages li,
.ds .widget_nav_menu li,
.ds .widget_popular_entries li,
.ds .widget_recent_posts li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_recent_entries > ul > li:last-child,
.ds .widget_recent_comments > ul > li:last-child,
.ds .widget_archive > ul > li:last-child,
.ds .widget_categories > ul > li:last-child,
.ds .widget_meta > ul > li:last-child,
.ds .widget_popular_entries > ul > li:last-child,
.ds .widget_nav_menu ul.menu > li:last-child,
.ds .widget_pages > ul > li:last-child {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.ds .grid-view h3:before,
.ds .grid-view h3:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel .owl-nav > div {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel .owl-nav > div:after {
  color: $hive-lighter;
}

.ds .owl-carousel .owl-nav > div:hover:after {
  color: $hive-primary;
}

.ds .ui-slider {
  background-color: $hive-lighter;
}

.ds .ui-slider .ui-slider-handle {
  background-color: $hive-lighter;
}

.ds .widget_layered_nav .color-filters a:before {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .table.cart a {
  color: $hive-lighter;
}

.ds .thumbnail {
  background-color: transparent;
}

.ds .thumbnail h3 a {
  color: $hive-lighter;
}

.ds .thumbnail .caption {
  border-color: transparent;
  background-color: rgba(252, 252, 252, 0.05);
}

.ds .progress {
  background-color: #f7f7f7;
}

.ds .panel-body {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds div .fw-shortcode-calendar-wrapper .page-header h3,
.ds div .btn-group button[data-calendar-nav],
.ds div .btn-group button[data-calendar-nav*="today"] {
  color: $hive-lighter;
}

.ds .text-nav .owl-nav > div {
  background-color: $hive-dark;
}

.ds .text-nav .owl-nav > div:after {
  color: $hive-lighter;
}

.ds .text-nav .owl-nav > div:hover:after {
  color: $hive-secondary;
}

.ds a.border-icon {
  border-color: #787878;
}

.ds .nav-unstyled .active a {
  color: $hive-lighter;
}

.ds .nav-unstyled .active a {
  color: $hive-primary;
}

.ds .theme_buttons a,
.ds .theme_button {
  color: $hive-lighter;
  background-color: #515660;
}

.ds .theme_buttons a:hover,
.ds .theme_buttons a:focus,
.ds .theme_buttons a:active,
.ds .theme_button:hover,
.ds .theme_button:focus,
.ds .theme_button:active {
  color: $hive-primary;
}

.ds .theme_buttons.inverse a,
.ds .theme_button.inverse {
  color: #515660;
  background-color: $hive-lighter;
  border-color: #515660;
}

.ds .theme_buttons.inverse a:hover,
.ds .theme_buttons.inverse a:focus,
.ds .theme_buttons.inverse a:active,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus,
.ds .theme_button.inverse:active {
  color: $hive-primary;
}

.ds .theme_buttons.color1 a,
.ds .theme_button.color1,
.ds .button.alt,
.ds .btn-accent,
.ds .wc-proceed-to-checkout .checkout-button,
.ds .form-submit [type="submit"] {
  color: $hive-lighter;
  background-color: $hive-primary;
  border-color: $hive-primary;
}

.ds .theme_buttons.color1 a:hover,
.ds .theme_buttons.color1 a:focus,
.ds .theme_buttons.color1 a:active,
.ds .theme_button.color1:hover,
.ds .theme_button.color1:focus,
.ds .theme_button.color1:active,
.ds .button.alt:hover,
.ds .button.alt:focus,
.ds .button.alt:active,
.ds .btn-accent:hover,
.ds .btn-accent:focus,
.ds .btn-accent:active,
.ds .wc-proceed-to-checkout .checkout-button:hover,
.ds .wc-proceed-to-checkout .checkout-button:focus,
.ds .wc-proceed-to-checkout .checkout-button:active,
.ds .form-submit [type="submit"]:hover,
.ds .form-submit [type="submit"]:focus,
.ds .form-submit [type="submit"]:active {
  color: $hive-dark;
}

.ds .theme_buttons.color1.inverse a,
.ds .theme_button.color1.inverse {
  color: $hive-primary;
  background-color: $hive-lighter;
}

.ds .theme_buttons.color1.inverse a:hover,
.ds .theme_buttons.color1.inverse a:focus,
.ds .theme_buttons.color1.inverse a:active,
.ds .theme_button.color1.inverse:hover,
.ds .theme_button.color1.inverse:focus,
.ds .theme_button.color1.inverse:active {
  color: $hive-lighter;
  background-color: $hive-primary;
}

.ds .theme_buttons.color2 a,
.ds .theme_button.color2 {
  background-color: $hive-secondary;
}

.ds .theme_buttons.color2.inverse a,
.ds .theme_button.color2.inverse {
  color: $hive-secondary;
  border-color: $hive-secondary;
}

.ds .theme_buttons[class*="color"]:hover,
.ds .theme_buttons[class*="color"]:active,
.ds .theme_buttons[class*="color"]:focus,
.ds .theme_button[class*="color"]:hover,
.ds .theme_button[class*="color"]:active,
.ds .theme_button[class*="color"]:focus {
  color: $hive-light;
}

.ds .theme_buttons.inverse[class*="color"]:hover,
.ds .theme_buttons.inverse[class*="color"]:active,
.ds .theme_buttons.inverse[class*="color"]:focus,
.ds .theme_button.inverse[class*="color"]:hover,
.ds .theme_button.inverse[class*="color"]:active,
.ds .theme_button.inverse[class*="color"]:focus {
  color: $hive-lighter;
}

.ds .dropdown-menu {
  background-color: $hive-dark;
}

.ds .content-padding footer:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .item-content + footer.item-meta > div + div,
.ds .item-content + footer.item-meta {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .teaser .dashed_border {
  background-image: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.ds .format-chat.with_background .entry-content p:nth-child(2n) {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds h2.section_header + hr + .section-excerpt {
  font-weight: 300;
  color: $hive-lighter;
}

.ds .media_items_list .media {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .media_items_list .media .media-body {
  border-color: rgba(255, 255, 255, 0.2);
}

/*
** Color Section - .cs
*/
.cs.main_color2 {
  background-color: $hive-secondary;
}

.cs.main_color2 .highlight {
  color: $hive-primary;
}

.cs.main_color2 .theme_button.color2 {
  color: $hive-secondary;
  background-color: #fff;
}

.cs.main_color2 .theme_button.color2:hover,
.cs.main_color2 .theme_button.color2:focus,
.cs.main_color2 .theme_button.color2:active {
  color: $hive-dark;
}

.cs.gradient {
  background: linear-gradient(-90deg, $hive-secondary, $hive-primary);
}

.cs.gradient .breadcrumb > .active {
  color: $hive-dark;
}

.cs {
  background-color: $hive-primary;
  color: #fff;
}

.cs.parallax {
  color: rgba(255, 255, 255, 0.8);
}

.cs .ls .greylinks a {
  color: #787878;
}

.cs .ls .greylinks a:hover,
.cs .ls .greylinks a:focus,
.cs .ls .greylinks a:active {
  color: #787878;
}

.cs .ls h1 a:hover,
.cs .ls h2 a:hover,
.cs .ls h3 a:hover,
.cs .ls h4 a:hover,
.cs .ls h5 a:hover,
.cs .ls h6 a:hover {
  color: $hive-primary;
}

.cs .ds .greylinks a {
  color: #787878;
}

.cs .ds .greylinks a:hover,
.cs .ds .greylinks a:focus,
.cs .ds .greylinks a:active {
  color: #787878;
}

.cs .ds h1,
.cs .ds h2,
.cs .ds h3,
.cs .ds h4,
.cs .ds h5,
.cs .ds h6 {
  color: $hive-lighter;
}

.cs .ds h1 a,
.cs .ds h2 a,
.cs .ds h3 a,
.cs .ds h4 a,
.cs .ds h5 a,
.cs .ds h6 a {
  color: inherit;
}

.cs .ds h1 a:hover,
.cs .ds h2 a:hover,
.cs .ds h3 a:hover,
.cs .ds h4 a:hover,
.cs .ds h5 a:hover,
.cs .ds h6 a:hover {
  color: $hive-primary;
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6 {
  color: $hive-lighter;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a {
  color: inherit;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover {
  color: $hive-dark;
}

.cs h2.section_header {
  border-left-color: #fff;
}

.cs a {
  color: $hive-lighter;
}

.cs a:hover,
.cs a:active,
.cs a:focus {
  color: rgba(255, 255, 255, 0.5);
}

.cs .with_background {
  background-color: rgba(255, 255, 255, 0.23);
}

.cs .with-border {
  border-color: rgba(255, 255, 255, 0.23);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after {
  background-color: #ee7c6f;
}

.cs .greylinks a,
.cs .greylinks a.social-icon {
  color: $hive-dark;
}

.cs .greylinks a:hover,
.cs .greylinks a.social-icon:hover {
  color: $hive-lighter;
}

.cs .darklinks a {
  color: $hive-lighter;
}

.cs .darklinks a:hover {
  color: $hive-secondary;
}

.cs .highlightlinks a,
.cs .highlightlinks a.social-icon {
  color: rgba(255, 255, 255, 0.5);
}

.cs .black {
  border-color: $hive-lighter;
  color: $hive-lighter;
}

.cs .grey {
  border-color: $hive-dark;
  color: $hive-dark;
}

.cs .lightgrey {
  color: rgba(255, 255, 255, 0.6);
}

.cs .response,
.cs .highlight {
  color: #fff;
}

.cs .breadcrumb > .active,
.cs .breadcrumb > li:last-child {
  color: $hive-dark;
}

.cs .light_bg_color .highlight,
.cs .light_bg_color.highlight {
  color: $hive-primary;
}

.cs ul.list1 li {
  border-color: rgba(255, 255, 255, 0.23);
}

.cs [class*="socicon-"] {
  border-color: rgba(255, 255, 255, 0.23);
}

.cs .color-icon.bg-icon {
  background-color: rgba(255, 255, 255, 0.23);
}

.cs a.social-icon.color-bg-icon:hover {
  background-color: $hive-dark;
}

.cs .theme_buttons a:hover,
.cs .theme_buttons a:focus,
.cs .theme_buttons a:active,
.cs .theme_button:hover,
.cs .theme_button:focus,
.cs .theme_button:active {
  color: $hive-primary;
}

.cs .theme_buttons.inverse a,
.cs .theme_button.inverse {
  color: $hive-dark;
  border-color: $hive-dark;
}

.cs .theme_buttons.inverse a:hover,
.cs .theme_buttons.inverse a:focus,
.cs .theme_buttons.inverse a:active,
.cs .theme_button.inverse:hover,
.cs .theme_button.inverse:focus,
.cs .theme_button.inverse:active {
  color: $hive-lighter;
}

.cs .theme_buttons[class*="color"],
.cs .theme_button[class*="color"] {
  color: $hive-lighter;
}

.cs .theme_buttons[class*="color"]:hover,
.cs .theme_buttons[class*="color"]:focus,
.cs .theme_buttons[class*="color"]:active,
.cs .theme_button[class*="color"]:hover,
.cs .theme_button[class*="color"]:focus,
.cs .theme_button[class*="color"]:active {
  color: $hive-dark;
}

.cs .theme_buttons.color1 a,
.cs .theme_button.color1 {
  color: $hive-primary;
  background-color: $hive-lighter;
}

.cs .theme_buttons.color1 a:hover,
.cs .theme_buttons.color1 a:focus,
.cs .theme_buttons.color1 a:active,
.cs .theme_button.color1:hover,
.cs .theme_button.color1:focus,
.cs .theme_button.color1:active {
  color: $hive-dark;
}

.cs .theme_buttons.color1.inverse a,
.cs .theme_button.color1.inverse {
  color: $hive-lighter;
  border-color: $hive-lighter;
}

.cs .theme_buttons.color1.inverse a:hover,
.cs .theme_buttons.color1.inverse a:focus,
.cs .theme_buttons.color1.inverse a:active,
.cs .theme_button.color1.inverse:hover,
.cs .theme_button.color1.inverse:focus,
.cs .theme_button.color1.inverse:active {
  color: $hive-dark;
}

.cs .theme_buttons.color2.inverse a,
.cs .theme_button.color2.inverse {
  color: $hive-secondary;
  border-color: $hive-secondary;
}

.cs .theme_buttons.color2.inverse a:hover,
.cs .theme_buttons.color2.inverse a:focus,
.cs .theme_buttons.color2.inverse a:active,
.cs .theme_button.color2.inverse:hover,
.cs .theme_button.color2.inverse:focus,
.cs .theme_button.color2.inverse:active {
  color: $hive-lighter;
  background-color: $hive-secondary;
}

.cs .list3 li:after {
  color: #fff;
}

.cs .filters a,
.cs .filters a.selected,
.cs .filters a:hover {
  color: $hive-lighter;
}

.cs .filters a:after,
.cs .filters a.selected:after,
.cs .filters a:hover:after {
  background-color: $hive-lighter;
}

.cs.with_top_border:before,
.cs.with_bottom_border:after,
.cs.with_top_border_container .container:before,
.cs.with_bottom_border_container .container:after {
  background-color: rgba(255, 255, 255, 0.23);
}

.cs .flex-control-nav.owl-carousel:after {
  background-color: $hive-lighter;
}

/* title section (breadcrumbs) styles */
.page_breadcrumbs .breadcrumbs_logo {
  margin: 0 0 15px;
}

.page_breadcrumbs .breadcrumbs_logo img {
  opacity: 0.3;
}

.page_breadcrumbs h2 {
  display: inline;
  font-size: 30px;
  font-weight: 500;
  line-height: 0.9em;
  word-wrap: break-word;
}

.page_breadcrumbs h2 + .breadcrumb {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .page_breadcrumbs h2 {
    font-size: 40px;
  }
}

.page_breadcrumbs h2.small {
  font-size: 30px;
}

@media screen and (min-width: 992px) {
  .page_breadcrumbs .display_table_md {
    width: auto;
    min-width: 0;
  }

  .page_breadcrumbs h2.display_table_cell_md {
    padding-right: 20px;
    padding-left: 15px;
  }

  .page_breadcrumbs .breadcrumb.display_table_cell_md {
    border-left: 1px solid rgba(255, 255, 255, 0.23);
    padding-left: 25px;
  }
}

.page_breadcrumbs .bottom_breadcrumbs {
  text-align: center;
  float: right;
  padding: 4px 20px 0;
  margin: 0 0 -25px 0;
}

@media (max-width: 1199px) {
  .page_breadcrumbs.section_padding_top_65.section_padding_bottom_65 > [class*="container"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

/* template parallax sections */

.page_breadcrumbs.background_cover {
  background-image: url(/assets/images/parallax/breadcrumbs.jpg);
}

.page_footer.parallax,
.page_footer .parallax.parallax,
.page_copyright.parallax,
.page_contacts.parallax {
  background-image: url(/assets/images/parallax/bottom.jpg);
}

@media (max-width: 1199px) {
  .page_footer.parallax + .page_copyright.parallax {
    background-image: none;
  }

  .page_footer.parallax + .page_copyright.parallax > [class*="container"]:before,
  .page_footer.parallax + .page_copyright.parallax:before {
    display: none !important;
  }
}

/* Other */
@media (min-width: 1200px) and (max-width: 1599px) {
  .section_intro_overlap .teaser[class*="_bg_color"],
  .section_intro_overlap .teaser[class*="with_"] {
    padding: 30px;
  }

  .section_intro_overlap .teaser_icon.thick_border_icon[class*="_bg_color"].big_wrapper,
  .section_intro_overlap .teaser_icon.thick_border_icon.with_background.big_wrapper {
    width: calc(2em + 20px);
    height: calc(2em + 20px);
  }

  .section_intro_overlap .teaser_icon.thick_border_icon[class*="_bg_color"].big_wrapper > *,
  .section_intro_overlap .teaser_icon.thick_border_icon.with_background.big_wrapper > * {
    line-height: 2em;
  }
}

/*
** Shortcodes
*/
/*
** Teasers
*/
.media.inline-block {
  margin-top: 10px;
}

.media.inline-block.teaser:first-child {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .media.topmargin_40 {
    margin-top: 80px;
  }
}

.media-left i,
.meida-right i {
  display: block;
  width: 1.1em;
  line-height: 1.625;
}

.media-left i.fontsize_24,
.meida-right i.fontsize_24 {
  line-height: 1;
}

.inline-teasers-wrap {
  display: inline-block;
  margin-left: -15px;
  margin-right: -15px;
}

.inline-teasers-wrap > * {
  vertical-align: middle;
  margin: 10px 15px;
}

@media (min-width: 1200px) {
  .inline-teasers-wrap {
    margin-left: -20px;
    margin-right: -20px;
  }

  .inline-teasers-wrap > * {
    margin: 10px 20px;
  }
}

/* teaser */
.teaser {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.teaser.media h2,
.teaser.media h3,
.teaser.media h4,
.teaser.media h5,
.teaser.media h6 {
  line-height: 1em;
}

.teaser h2,
.teaser h3,
.teaser h4,
.teaser h5,
.teaser h6 {
  margin-bottom: 0.4em;
}

.teaser[class*="_bg_color"],
.teaser[class*="with_"] {
  padding: 30px 20px;
}

@media (min-width: 400px) {
  .teaser[class*="_bg_color"],
  .teaser[class*="with_"] {
    padding: 40px;
  }
}

@media (min-width: 1600px) {
  .teaser[class*="_bg_color"].big-padding,
  .teaser[class*="with_"].big-padding {
    padding: 50px;
  }
}

.teaser i {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

.teaser .media-body {
  line-height: 1.5em;
}

.teaser .media-body h3 {
  margin-bottom: 10px;
}

.teaser h3[class*="count"] {
  font-size: 48px;
  font-weight: 700;
  margin: 10px 0 0;
}

.teaser h3[class*="count"] + p {
  font-size: 20px;
  font-weight: 600;
}

.teaser .theme_button {
  margin: 22px 0 0;
}

.teaser .counter-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 48%;
  font-size: 180px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.1;
  transform: translateY(-50%);
}

.teaser .icon-background {
  position: absolute;
  opacity: 0.1;
  top: 50%;
  left: 50%;
  right: 0;
  font-size: 300px;
  transform: translate(-50%, -50%);
  z-index: -1;
  max-width: 100%;
  width: 100%;
}

.teaser .icon-background.small {
  font-size: 84px;
}

.teaser i.icon-background {
  margin-top: 0;
  margin-bottom: 0;
}

.teaser.font-icon-background-teaser {
  padding: 48px 25px 48px;
  overflow: hidden;
}

.teaser.counter-background-teaser {
  overflow: hidden;
  padding: 98px 25px 98px;
}

.teaser.counter-background-teaser .small-text {
  line-height: 1.4em;
}

.teaser.inner-border:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.teaser_icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  transition: all 0.2s ease-in-out 0s;
}

.teaser_icon[class*="_bg_color"],
.teaser_icon.with_background {
  width: 2em;
  height: 2em;
  text-align: center;
  vertical-align: bottom;
}

.teaser_icon[class*="_bg_color"] > *,
.teaser_icon.with_background > * {
  line-height: 2em;
}

.teaser_icon[class*="_bg_color"].big_wrapper,
.teaser_icon.with_background.big_wrapper {
  width: 2.6667em;
  height: 2.6667em;
}

.teaser_icon[class*="_bg_color"].big_wrapper > *,
.teaser_icon.with_background.big_wrapper > * {
  line-height: 2.6667em;
}

.teaser_icon.border_icon {
  width: 2em;
  height: 2em;
  border-width: 2px;
  border-style: solid;
}

.teaser_icon.border_icon + h3,
.teaser_icon.border_icon + h4 {
  margin-top: 28px;
}

.teaser_icon.border_icon * {
  line-height: 2em;
  margin-top: -2px;
}

.teaser_icon[class*="label-"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  vertical-align: bottom;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
}

.teaser_icon + h2 {
  margin-top: 40px;
}

.teaser_icon.big_wrapper + h4 {
  margin-top: 33px;
}

.teaser_icon + p {
  margin-top: 27px;
}

.teaser_icon.thick_border_icon {
  border: 10px solid rgba(31, 35, 43, 0.07);
  background-clip: content-box;
}

.teaser_icon.thick_border_icon[class*="_bg_color"],
.teaser_icon.thick_border_icon.with_background {
  width: calc(2em + 20px);
  height: calc(2em + 20px);
}

.teaser_icon.thick_border_icon[class*="_bg_color"].big_wrapper,
.teaser_icon.thick_border_icon.with_background.big_wrapper {
  width: calc(2.6667em + 20px);
  height: calc(2.6667em + 20px);
}

.size_small {
  font-size: 30px;
}

[class*="color_bg"] {
  color: $hive-lighter;
}

.teaser.media.after_cover {
  padding: 25px 30px;
}

.teaser.media.after_cover i {
  opacity: 0.3;
}

.teaser.media.after_cover p {
  text-transform: uppercase;
}

/* contact forms */
.contact-form {
  position: relative;
  z-index: 3;
}

.contact-form label[for] {
  display: none;
}

@media (min-width: 768px) {
  .contact-form-message textarea {
    min-height: 200px;
  }
}

.contact-form.parallax {
  padding: 15px 20px 5px;
  background-image: url(/assets/images/parallax/map.jpg);
}

@media (min-width: 400px) {
  .contact-form.parallax {
    padding: 35px 40px 25px;
  }
}

@media (min-width: 1200px) {
  .contact-form.parallax {
    padding: 55px 60px 45px;
  }
}

.contact-form-respond {
  display: block;
  font-weight: 800;
}

.contact-form-respond:before {
  font-family: "rt-icons-2";
  content: "\e71a";
  padding-right: 10px;
}

.contact-form p.form-group {
  margin-bottom: 15px;
}

.contact-form [class*="col-"] .form-group {
  margin: 0;
}

.contact-form [class*="col-"] .form-group + .form-group {
  margin-top: 10px;
}

.contact-form.transparent-background .form-control {
  background-color: transparent;
}

.contact-email {
  font-size: 40px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .contact-email {
    font-size: 20px;
    margin-bottom: 6px;
  }

  .contact-form.parallax {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
}

.light-shadow-text {
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

.dark-shadow-text {
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 1);
}

.clickable {
  cursor: pointer;
}

.clickable-text {
  cursor: pointer;
}
