@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.hive-dark {
  color: #595d6f !important;
}

.hive-dark-darken5 {
  color: #4e5161 !important;
}

.hive-dark-darken10 {
  color: #424553 !important;
}

.hive-dark-darken15 {
  color: #373945 !important;
}

.hive-dark-lighten5 {
  color: #64697d !important;
}

.hive-dark-lighten10 {
  color: #70758b !important;
}

.hive-dark-lighten15 {
  color: #7d8297 !important;
}

.hive-dark-bg {
  background-color: #595d6f !important;
}

.hive-dark-bg-darken5 {
  background-color: #4e5161 !important;
}

.hive-dark-bg-darken10 {
  background-color: #424553 !important;
}

.hive-dark-bg-darken15 {
  background-color: #373945 !important;
}

.hive-dark-bg-lighten5 {
  background-color: #64697d !important;
}

.hive-dark-bg-lighten10 {
  background-color: #70758b !important;
}

.hive-dark-bg-lighten15 {
  background-color: #7d8297 !important;
}

.hive-darker {
  color: #0d163d !important;
}

.hive-darker-darken5 {
  color: #090e28 !important;
}

.hive-darker-darken10 {
  color: #040713 !important;
}

.hive-darker-darken15 {
  color: black !important;
}

.hive-darker-lighten5 {
  color: #111e52 !important;
}

.hive-darker-lighten10 {
  color: #162567 !important;
}

.hive-darker-lighten15 {
  color: #1a2d7c !important;
}

.hive-darker-bg {
  background-color: #0d163d !important;
}

.hive-darker-bg-darken5 {
  background-color: #090e28 !important;
}

.hive-darker-bg-darken10 {
  background-color: #040713 !important;
}

.hive-darker-bg-darken15 {
  background-color: black !important;
}

.hive-darker-bg-lighten5 {
  background-color: #111e52 !important;
}

.hive-darker-bg-lighten10 {
  background-color: #162567 !important;
}

.hive-darker-bg-lighten15 {
  background-color: #1a2d7c !important;
}

.hive-light {
  color: #b3b3bb !important;
}

.hive-light-darken5 {
  color: #a6a6af !important;
}

.hive-light-darken10 {
  color: #9898a3 !important;
}

.hive-light-darken15 {
  color: #8b8b97 !important;
}

.hive-light-lighten5 {
  color: #c0c0c7 !important;
}

.hive-light-lighten10 {
  color: #ceced3 !important;
}

.hive-light-lighten15 {
  color: #dbdbdf !important;
}

.hive-light-bg {
  background-color: #b3b3bb !important;
}

.hive-light-bg-darken5 {
  background-color: #a6a6af !important;
}

.hive-light-bg-darken10 {
  background-color: #9898a3 !important;
}

.hive-light-bg-darken15 {
  background-color: #8b8b97 !important;
}

.hive-light-bg-lighten5 {
  background-color: #c0c0c7 !important;
}

.hive-light-bg-lighten10 {
  background-color: #ceced3 !important;
}

.hive-light-bg-lighten15 {
  background-color: #dbdbdf !important;
}

.hive-lighter {
  color: #ffffff !important;
}

.hive-lighter-darken5 {
  color: #f2f2f2 !important;
}

.hive-lighter-darken10 {
  color: #e6e6e6 !important;
}

.hive-lighter-darken15 {
  color: #d9d9d9 !important;
}

.hive-lighter-lighten5 {
  color: white !important;
}

.hive-lighter-lighten10 {
  color: white !important;
}

.hive-lighter-lighten15 {
  color: white !important;
}

.hive-lighter-bg {
  background-color: #ffffff !important;
}

.hive-lighter-bg-darken5 {
  background-color: #f2f2f2 !important;
}

.hive-lighter-bg-darken10 {
  background-color: #e6e6e6 !important;
}

.hive-lighter-bg-darken15 {
  background-color: #d9d9d9 !important;
}

.hive-lighter-bg-lighten5 {
  background-color: white !important;
}

.hive-lighter-bg-lighten10 {
  background-color: white !important;
}

.hive-lighter-bg-lighten15 {
  background-color: white !important;
}

.hive-primary {
  color: #e7a93b !important;
}

.hive-primary-darken5 {
  color: #e49f24 !important;
}

.hive-primary-darken10 {
  color: #d5921a !important;
}

.hive-primary-darken15 {
  color: #be8217 !important;
}

.hive-primary-lighten5 {
  color: #eab352 !important;
}

.hive-primary-lighten10 {
  color: #edbd68 !important;
}

.hive-primary-lighten15 {
  color: #efc77f !important;
}

.hive-primary-bg {
  background-color: #e7a93b !important;
}

.hive-primary-bg-darken5 {
  background-color: #e49f24 !important;
}

.hive-primary-bg-darken10 {
  background-color: #d5921a !important;
}

.hive-primary-bg-darken15 {
  background-color: #be8217 !important;
}

.hive-primary-bg-lighten5 {
  background-color: #eab352 !important;
}

.hive-primary-bg-lighten10 {
  background-color: #edbd68 !important;
}

.hive-primary-bg-lighten15 {
  background-color: #efc77f !important;
}

.hive-secondary {
  color: #d26308 !important;
}

.hive-secondary-darken5 {
  color: #b95707 !important;
}

.hive-secondary-darken10 {
  color: #a14c06 !important;
}

.hive-secondary-darken15 {
  color: #884005 !important;
}

.hive-secondary-lighten5 {
  color: #eb6f09 !important;
}

.hive-secondary-lighten10 {
  color: #f67b17 !important;
}

.hive-secondary-lighten15 {
  color: #f7892f !important;
}

.hive-secondary-bg {
  background-color: #d26308 !important;
}

.hive-secondary-bg-darken5 {
  background-color: #b95707 !important;
}

.hive-secondary-bg-darken10 {
  background-color: #a14c06 !important;
}

.hive-secondary-bg-darken15 {
  background-color: #884005 !important;
}

.hive-secondary-bg-lighten5 {
  background-color: #eb6f09 !important;
}

.hive-secondary-bg-lighten10 {
  background-color: #f67b17 !important;
}

.hive-secondary-bg-lighten15 {
  background-color: #f7892f !important;
}

.hive-white {
  color: #ffffff !important;
}

.hive-white-darken5 {
  color: #f2f2f2 !important;
}

.hive-white-darken10 {
  color: #e6e6e6 !important;
}

.hive-white-darken15 {
  color: #d9d9d9 !important;
}

.hive-white-lighten5 {
  color: white !important;
}

.hive-white-lighten10 {
  color: white !important;
}

.hive-white-lighten15 {
  color: white !important;
}

.hive-white-bg {
  background-color: #ffffff !important;
}

.hive-white-bg-darken5 {
  background-color: #f2f2f2 !important;
}

.hive-white-bg-darken10 {
  background-color: #e6e6e6 !important;
}

.hive-white-bg-darken15 {
  background-color: #d9d9d9 !important;
}

.hive-white-bg-lighten5 {
  background-color: white !important;
}

.hive-white-bg-lighten10 {
  background-color: white !important;
}

.hive-white-bg-lighten15 {
  background-color: white !important;
}

.hive-blue {
  color: #003b53 !important;
}

.hive-blue-darken5 {
  color: #00293a !important;
}

.hive-blue-darken10 {
  color: #001720 !important;
}

.hive-blue-darken15 {
  color: #000507 !important;
}

.hive-blue-lighten5 {
  color: #004d6d !important;
}

.hive-blue-lighten10 {
  color: #005f86 !important;
}

.hive-blue-lighten15 {
  color: #0071a0 !important;
}

.hive-blue-bg {
  background-color: #003b53 !important;
}

.hive-blue-bg-darken5 {
  background-color: #00293a !important;
}

.hive-blue-bg-darken10 {
  background-color: #001720 !important;
}

.hive-blue-bg-darken15 {
  background-color: #000507 !important;
}

.hive-blue-bg-lighten5 {
  background-color: #004d6d !important;
}

.hive-blue-bg-lighten10 {
  background-color: #005f86 !important;
}

.hive-blue-bg-lighten15 {
  background-color: #0071a0 !important;
}

/* forms */
input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=tel],
input[type=number],
input[type=date],
textarea,
select,
.form-control {
  height: 60px;
  background-color: transparent;
  box-shadow: none;
  color: #595d6f;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid rgba(120, 120, 120, 0.5);
  font-family: "Ubuntu", sans-serif;
}

input[type=text] + .theme_button,
input[type=email] + .theme_button,
input[type=url] + .theme_button,
input[type=password] + .theme_button,
input[type=search] + .theme_button,
input[type=tel] + .theme_button,
input[type=number] + .theme_button,
input[type=date] + .theme_button,
textarea + .theme_button,
select + .theme_button,
.form-control + .theme_button {
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #ffffff;
  box-shadow: none;
  border-radius: 0 10px 10px 0;
  border: none;
  cursor: pointer;
}

.input-text-center input[type=text],
.input-text-center input[type=email],
.input-text-center input[type=url],
.input-text-center input[type=password],
.input-text-center input[type=search],
.input-text-center input[type=tel],
.input-text-center input[type=number],
.input-text-center input[type=date],
.input-text-center textarea,
.input-text-center select,
.input-text-center .form-control {
  text-align: center;
}

input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=tel],
input[type=number],
input[type=date],
textarea,
.select-group,
.form-control {
  border: 1px solid rgba(120, 120, 120, 0.3);
}

/* select styling */
.form-control option {
  color: #595d6f;
  background-color: #ffffff;
}

.form-control.invalid {
  box-shadow: 0 0 0 2px #e7a93b;
}

.form-control:focus {
  border-color: #787878;
  box-shadow: none;
}

.form-control:-moz-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control::-webkit-input-placeholder {
  color: rgba(120, 120, 120, 0.7);
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control:focus:-moz-placeholder {
  color: transparent;
}

.form-control:focus::-moz-placeholder {
  color: transparent;
}

.form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

/*icons for forms */
form label + i,
form label + i {
  position: absolute;
  font-size: 14px !important;
  left: 20px;
  top: 23px;
  width: 1em;
  line-height: 1;
  text-align: center;
}

form label + i + .form-control,
form label + i + .form-control {
  padding-left: 45px;
}

form .with_icon {
  padding-left: 25px;
}

form .with_icon + [class*=fa-],
form .with_icon + [class*=icon2-] {
  position: absolute;
  top: 17px;
  left: 2px;
  font-size: 12px;
}

form .with_icon:focus + [class*=fa-],
form .with_icon:focus + [class*=icon2-] {
  color: #d26308;
}

label {
  font-weight: inherit;
}

.checkbox label,
.radio label {
  font-weight: inherit;
}

.checkbox label[for],
.radio label[for] {
  font-weight: inherit;
}

.form-control + [class*=icon2-] {
  position: absolute;
  font-size: 24px;
  left: 20px;
  top: 50%;
  color: #e7a93b;
}

.checkbox label[for] + input[type=checkbox],
.checkbox label[for] + input[type=radio],
.radio label[for] + input[type=checkbox],
.radio label[for] + input[type=radio] {
  left: 20px;
}

input[type=checkbox],
input[type=radio] {
  margin-top: 9px;
}

.form-wrapper.with_background,
.form-wrapper.muted_background,
.form-wrapper.cs,
.form-wrapper.ds,
.form-wrapper.ls {
  padding: 35px;
}

.has-error .form-control {
  border-color: #e7a93b;
}

.has-error label,
.has-error .help-block {
  color: #e7a93b;
}

/* carousel */
.carousel {
  overflow: hidden;
}

/* collapse accordion */
.panel-heading .panel-title {
  font-size: inherit;
  letter-spacing: 0;
  padding: 0;
  position: relative;
  font-family: "Ubuntu", sans-serif;
}

.panel-heading .panel-title > a {
  font-size: 18px;
  line-height: 1.3;
  display: block;
  padding: 18px 60px 19px 20px;
  background: #e7a93b;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  word-wrap: break-word;
}

@media (min-width: 1200px) {
  .panel-heading .panel-title > a {
    padding-left: 35px;
  }
}
.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover {
  color: #ffffff;
  background: #e7a93b;
}

.panel-heading .panel-title > a.collapsed:hover i,
.panel-heading .panel-title > a.collapsed:hover:after,
.panel-heading .panel-title > a:hover i,
.panel-heading .panel-title > a:hover:after {
  color: #ffffff;
}

.panel-heading .panel-title > a.collapsed {
  color: #595d6f;
  background: #f2f2f2;
  border-radius: 10px;
}

.panel-heading .panel-title > a.collapsed i {
  color: #e7a93b;
}

.panel-heading .panel-title > a:after {
  content: "-";
  position: absolute;
  text-align: center;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 100;
  transition: all 0.2s ease-in-out 0s;
  color: #fff;
}

.panel-heading .panel-title > a.collapsed:after {
  content: "+";
  color: #595d6f;
}

.panel-heading .panel-title > a > i {
  position: relative;
  font-size: 16px;
  padding-right: 10px;
  text-align: center;
  display: inline-block;
  line-height: 0;
}

.color2 .panel-heading .panel-title > a {
  background: #d26308;
}

.color2 .panel-title > a.collapsed {
  background: #f2f2f2;
}

.color2 .panel-title > a.collapsed i {
  color: #d26308;
}

.color2 .panel-title > a.collapsed:after {
  color: #d26308;
}

.color2 .panel-title > a.collapsed:hover,
.color2 .panel-title > a:hover {
  background: #d26308;
}

.color2 .panel-title > a.collapsed:hover i,
.color2 .panel-title > a.collapsed:hover:after,
.color2 .panel-title > a:hover i,
.color2 .panel-title > a:hover:after {
  color: #fff;
}

.panel {
  box-shadow: none;
}

.panel-group .panel {
  border-radius: 0;
  background-color: transparent;
}

.panel-default > .panel-heading {
  background-color: transparent;
  color: inherit;
  position: relative;
  border: none;
  border-radius: 0;
  padding: 0;
}

.panel-default {
  border: none;
}

.panel-group .panel + .panel {
  margin-top: 10px;
}

.panel-body {
  border: 1px solid #e4e4e4;
  border-top: none !important;
  background-color: transparent;
  border-radius: 0 0 5px 5px;
}

.panel-body .media-left {
  padding-right: 15px;
}

.panel-body .media-left .img-circle {
  max-width: 50px;
}

.panel-body .media-left img {
  max-width: 80px;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
  padding: 20px;
}

@media (min-width: 400px) {
  .panel-group .panel-heading + .panel-collapse .panel-body {
    padding: 30px 40px;
  }
}
.collapse-unstyled .panel {
  background-color: transparent;
  border: 1px solid #e4e4e4;
  border-width: 1px 0;
}

.collapse-unstyled .panel + .panel {
  margin-top: 0;
  border-top: none;
}

.collapse-unstyled .panel h4 {
  font-size: 18px;
  margin: 0;
}

.collapse-unstyled .panel h4 a {
  position: relative;
  display: block;
  padding: 18px 20px 10px 0;
}

.collapse-unstyled .panel h4 a:after {
  content: "-";
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 100;
  line-height: 56px;
}

.collapse-unstyled .panel h4 a.collapsed {
  padding-bottom: 18px;
}

.collapse-unstyled .panel h4 a.collapsed:after {
  content: "+";
}

@media (min-width: 992px) {
  .collapse-unstyled .panel h4 {
    font-size: 20px;
  }
}
.collapse-unstyled .panel-content {
  padding: 0 0 20px;
}

/* progress bars */
.progress-bar-title {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  margin-right: 50px;
}

.progress {
  overflow: visible;
  height: 5px;
  margin-bottom: 25px;
  position: relative;
  border-radius: 0;
  box-shadow: none;
  background-color: #e4e4e4;
  border-radius: 20px;
}

.ls .with_background .progress {
  background-color: #ffffff;
}

.ls.ms .with_background .progress {
  background-color: #e4e4e4;
}

.progress-bar {
  background-color: #e7a93b;
  color: inherit;
  box-shadow: none;
  height: 5px;
  border-radius: 2px;
}

.progress-bar span {
  position: absolute;
  right: 0;
  top: -29px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
}

.progress-bar-success {
  background-color: #72b946;
}

.progress-bar-info {
  background-color: #003b53;
}

.progress-bar-warning {
  background-color: #d26308;
}

.progress-bar-danger {
  background-color: #e7a93b;
}

.progress-bar-color2 {
  background-color: #d26308;
}

/* nav and tabs */
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

.nav-unstyled {
  list-style: none;
  padding: 0;
  margin: 0 -12px;
}

.nav-unstyled li {
  display: inline-block;
  margin-bottom: -1px;
}

.nav-unstyled li a {
  display: inline-block;
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
}

.nav-unstyled .active a {
  color: #e7a93b;
}

.nav-unstyled.color2 .active a {
  color: #d26308;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-bottom: none;
}

.nav-tabs {
  position: relative;
  border-bottom: none;
  margin-top: 0px;
  z-index: 1;
}

.nav-tabs.half-width-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.nav-tabs.half-width-tabs > li {
  width: 50%;
  display: -ms-flexbox;
  display: flex;
}

.nav-tabs.half-width-tabs > li a {
  width: 100%;
}

.nav-tabs > li {
  margin-bottom: 0;
  margin-right: 2px;
}

.nav-tabs > li > a {
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 20px;
  margin-right: 0;
  margin-top: 0px;
  border: 1px solid rgba(120, 120, 120, 0.3);
  border-bottom: 0;
  background-color: #ffffff;
  color: #787878;
  border-radius: 10px 10px 0 0;
}

.small-tabs .nav-tabs > li > a {
  padding: 10px;
}

.nav-tabs > li > a i {
  position: relative;
  top: 2px;
  padding-right: 10px;
  font-size: 1.6em;
  display: inline-block;
  line-height: 0;
}

@media (min-width: 992px) {
  .nav-tabs > li > a {
    padding: 12px 28px;
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .nav-tabs > li > a {
    padding: 19px 45px;
  }
}
@media (max-width: 400px) {
  .small-tabs .nav-tabs > li > a {
    font-size: 10px;
    padding: 8px;
  }
}
.one-third-tabs.nav-tabs {
  display: -ms-flexbox;
  display: flex;
}

.one-third-tabs.nav-tabs:before {
  display: none;
}

.one-third-tabs.nav-tabs > * {
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.one-third-tabs.nav-tabs > *:last-child {
  margin-right: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: #e7a93b;
  background-color: #e7a93b;
  color: #ffffff;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
  border-color: #e7a93b;
  background-color: #e7a93b;
  color: #ffffff;
}

.color2.nav-tabs > li.active > a,
.color2.nav-tabs > li.active > a:hover,
.color2.nav-tabs > li.active > a:focus {
  border-color: #d26308;
  background-color: #d26308;
  color: #ffffff;
}

.color2.nav-tabs > li > a:hover,
.color2.nav-tabs > li > a:focus,
.color2.nav-tabs.nav-justified > .active > a {
  border-color: #d26308;
  background-color: #d26308;
  color: #ffffff;
}

.vertical-tabs .nav > li > a {
  font-size: 18px;
  background-color: #f2f2f2;
  padding: 15px 40px 15px 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
  color: #595d6f;
}

.vertical-tabs .nav > li > a i {
  position: relative;
  top: -2px;
  padding-right: 8px;
  width: 2em;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.vertical-tabs .nav > li > a:after {
  font-family: "rt-icons-2";
  content: "";
  position: absolute;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  letter-spacing: 0;
}

.vertical-tabs.no-arrows .nav > li > a:after {
  display: none;
}

.vertical-tabs.no-arrows .nav > li > a {
  padding: 24px 0;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
  background: #e7a93b;
  color: #ffffff;
}

.vertical-tabs.color2 .nav > li.active a,
.vertical-tabs.color2 .nav > li > a:hover {
  background: #d26308;
}

@media (min-width: 768px) {
  .vertical-tabs .nav > li.active {
    position: relative;
    z-index: 2;
  }
}
/* modal */
.modal-backdrop.in {
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.modal-content {
  border-radius: 1px;
}

.modal-content [class*=col-]:last-child {
  margin-bottom: 0;
}

/* bootstrap tables */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 15px 15px;
  border-color: #e4e4e4;
}

.table {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.table td + th,
.table th + td,
.table th + th,
.table td + td {
  border-left: 1px solid #e4e4e4;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-bottom-width: 1px;
}

.table > tbody > tr > td.media-middle {
  vertical-align: middle;
}

.table-responsive {
  border: none;
}

/* bootstrap buttons */
.btn {
  padding: 22px 30px 22px;
  margin: 0 0 4px 0;
  font-weight: 700;
  border: none;
  border-radius: 0;
  outline: none;
  transition: all 0.3s ease-in-out 0s;
}

.btn:hover,
.btn:focus {
  outline: none;
}

/* media elements */
.media {
  margin-top: 17px;
}

.media .media-object {
  max-width: 100px;
}

li.media:before,
li.media:after {
  display: block;
  content: "";
  clear: both;
}

.media-right,
.media > .pull-right {
  padding-left: 20px;
}

.media-left,
.media > .pull-left {
  padding-right: 20px;
}

.media-left img,
.media-right img {
  max-width: 85px;
}

@media (min-width: 768px) {
  .big-left-media .media-left img,
.big-left-media .media-right img {
    max-width: 100px;
  }
}
.small-media {
  margin-top: 10px;
}

.small-media .media-left {
  padding-right: 7px;
}

.small-media .media-right {
  padding-left: 7px;
}

.small-media .media-left img,
.small-media .media-right img {
  max-width: 40px;
}

@media (max-width: 499px) {
  .xxs-media-left {
    text-align: center;
  }

  .xxs-media-left .media-left {
    display: block;
    margin: 0 auto 20px;
    padding-right: 0;
  }
}
td .media-body {
  width: auto;
}

/* bootstrap dropdown menu */
.dropdown-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: none;
  z-index: 1001;
  font-size: 14px;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}

[class*=right] .dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-menu > li > a {
  font-weight: 700;
  padding: 7px 20px;
}

.nav .open > .header-button,
.nav .open > .header-button:hover,
.nav .open > .header-button:focus {
  background-color: transparent;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #595d6f;
  background-color: transparent;
}

.dropdwon-menu-title {
  padding: 10px 30px;
}

.well {
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.embed-responsive-3by2 {
  padding-bottom: 66.666%;
}

.embed-responsive-1by1 {
  padding-bottom: 100%;
}

.embed-responsive iframe {
  background-color: #000;
}

.embed-placeholder:after {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  color: #ffffff;
  font-size: 24px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  text-align: center;
  padding-left: 5px;
  background-color: rgba(232, 82, 66, 0.9);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
  transition: all 0.2s ease 0s;
}

.embed-placeholder:hover:after {
  color: #595d6f;
}

.alert {
  border-radius: 10px;
  border-width: 0;
  padding: 15px 30px;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Ubuntu", sans-serif;
}

.alert strong {
  font-weight: 500;
}

.alert i {
  position: relative;
  padding-right: 10px;
  font-size: 14px;
}

.alert:before {
  font-family: FontAwesome;
  font-size: 14px;
  margin-right: 10px;
}

.alert-success:before {
  content: "";
}

.alert-info:before {
  content: "";
}

.alert-warning:before {
  content: "";
}

.alert-danger:before {
  content: "";
}

.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
  color: #ffffff;
}

.alert-success {
  background-color: #72b946;
}

.alert-info {
  background-color: #003b53;
}

.alert-warning {
  background-color: #d26308;
}

.alert-danger {
  background-color: #e7a93b;
}

button.close {
  margin-top: 3px;
}

/* small in headings */
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  color: inherit;
  font-size: 80%;
  font-weight: inherit;
}

.media h1 small,
.media h2 small,
.media h3 small,
.media h4 small,
.media h5 small,
.media h6 small {
  display: block;
}

/* labels */
.label-success {
  color: #ffffff;
  background-color: #72b946;
}

.label-info {
  color: #ffffff;
  background-color: #003b53;
}

.label-warning {
  color: #ffffff;
  background-color: #d26308;
}

.label-danger {
  color: #ffffff;
  background-color: #e7a93b;
}

/*caret*/
.caret {
  border: none;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid #e4e4e4;
  text-align: center;
  margin-left: 5px;
  vertical-align: baseline;
}

.caret:before {
  content: "";
  font-family: FontAwesome;
}

/* helpers */
@media (max-width: 499px) {
  .hidden-xxs {
    display: none !important;
  }
}
/*
** General Styles for HTML tags
*/
html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
  font-size: 16px;
}

::-moz-selection {
  color: #ffffff;
  background-color: #e7a93b;
}

::selection {
  color: #ffffff;
  background-color: #e7a93b;
}

::-moz-selection {
  color: #ffffff;
  background-color: #e7a93b;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  line-height: 1.875rem;
  font-size: 16px;
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.1;
  margin-top: 1.1em;
  margin-bottom: 0.8em;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

p {
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

img,
figure {
  max-width: 100%;
  height: auto;
}

figcaption,
.entry-caption {
  padding: 5px 0 0;
}

figcaption p,
.entry-caption p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;
}

a:hover {
  color: #d26308;
  text-decoration: none;
  transition: all 0.2s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
  outline: medium none;
  text-decoration: none;
}

a > img {
  transition: all 0.2s ease-in-out 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

a:hover > img {
  opacity: 0.8;
}

hr {
  border: none;
  height: 1px;
  margin-left: 0;
  margin-right: 0;
  background-color: #e4e4e4;
}

iframe {
  border: none;
  max-width: 100%;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

table {
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
}

table td,
table th {
  padding: 8px 10px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #e4e4e4;
}

table th {
  color: #595d6f;
  font-weight: normal;
  vertical-align: middle;
  line-height: 1;
}

.table_template thead {
  background-color: #e7a93b;
  color: #595d6f;
}

.table_template thead.light {
  background-color: #ffffff;
}

.table_template th {
  font-weight: 700;
}

.table_template td,
.table_template th {
  padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
  background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
  background-color: rgba(17, 17, 17, 0.05);
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

p .fa {
  vertical-align: middle;
}

/*
** Styled Lists
*/
ul,
ol {
  padding-left: 30px;
  margin-bottom: 20px;
}

.list1 ul,
ul.list1 {
  list-style: none;
  padding: 0;
}

.padding_30 .list1 ul,
.padding_30 ul.list1 {
  margin: 0;
}

.list1 li {
  position: relative;
  padding: 9px 0 10px 20px;
  border-bottom: 1px solid rgba(120, 120, 120, 0.2);
}

.muted_background .list1 li {
  border-color: rgba(255, 255, 255, 0.4);
}

.list1 li > .media {
  margin: 3px 0;
}

@media (min-width: 992px) {
  .list1 li {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.list1.big-padding li {
  padding-top: 22px;
  padding-bottom: 21px;
}

.list1.huge-padding li {
  padding-top: 37px;
  padding-bottom: 36px;
}

.list1.no-bullets li {
  padding-left: 0;
}

.list1.no-bullets li:before {
  display: none;
}

.list1 li:first-child {
  border-top: 1px solid rgba(120, 120, 120, 0.2);
}

.list1.no-top-border li:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.list1.no-bottom-border li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.list1 li:before {
  content: "";
  font-family: FontAwesome;
  font-size: 12px;
  color: #e7a93b;
  position: absolute;
  left: 0;
  top: 10px;
  width: 7px;
  height: 7px;
}

.list2 ul,
ul.list2 {
  list-style: none;
  padding-left: 20px;
}

.list2 li {
  position: relative;
  padding: 5px 0 5px 0;
}

.big-v-spacing.list2 li {
  padding: 7px 0;
}

.list2 li:before {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  font-size: 14px;
  line-height: 1em;
  left: -20px;
  top: 14px;
  color: #e7a93b;
}

.list2.color2 li:before {
  color: #d26308;
}

.list2.no-bullets {
  padding-left: 0 !important;
}

.list2.no-bullets li:before {
  display: none;
}

.list2.checklist {
  padding-left: 25px;
}

.list2.checklist li:before {
  content: "";
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 1em;
  left: -25px;
  color: #e7a93b;
  width: auto;
  height: auto;
  background-color: transparent;
}

.list2.checklist.color2 li:before {
  color: #d26308;
}

.list3 ol,
ol.list3 {
  counter-reset: li;
  list-style: none outside none;
  padding: 0;
}

.list3 li {
  position: relative;
  padding: 6px 0 6px 25px;
}

.list3 li.active:after {
  color: #d26308;
}

.list3 li:after {
  content: counter(li) ".";
  counter-increment: li;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  color: #e7a93b;
}

.list3.color li:after {
  color: #e7a93b;
}

.list3.color2 li:after {
  color: #d26308;
}

.list-unstyled .media {
  margin-top: 0;
  margin-bottom: 10px;
}

.list-unstyled .media:last-child {
  margin-bottom: 0;
}

.list-unstyled li:last-child > .media {
  margin-bottom: 0;
}

.list-unstyled .media-left {
  padding-right: 10px;
}

.list-unstyled .media-left img {
  border-radius: 50%;
}

.list-unstyled .media-right {
  padding-left: 10px;
}

.list-unstyled .media-right img {
  border-radius: 50%;
}

.list-unstyled .media-body .pull-right {
  margin: 0 20px;
}

.inline-list {
  list-style: none;
  margin: 0 -13px;
  padding: 0;
}

.inline-list > li {
  display: inline-block;
  padding: 0 13px;
}

.inline-list.small-padding {
  margin: 0 -8px;
}

.inline-list.small-padding > li {
  padding: 8px;
}

.four-cols-text ul {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .four-cols-text {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .four-cols-text {
    column-count: 3;
  }
}
@media (min-width: 1200px) {
  .four-cols-text {
    column-count: 4;
  }
}
.three-cols-text ul {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .three-cols-text {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .three-cols-text {
    column-count: 3;
  }
}
@media (min-width: 1200px) {
  .three-cols-text {
    column-count: 3;
  }
}
/*
** Buttons
*/
.theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type=submit] {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 18px 30px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  background-color: #595d6f;
  border-radius: 10px;
  border: none;
  transition: all 0.4s linear 0s;
  z-index: 1;
}

@media (min-width: 1600px) {
  .theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type=submit] {
    padding: 23px 40px;
  }
}
.theme_button,
.theme_buttons a {
  margin-bottom: 10px;
  margin-right: 6px;
}

.theme_button:last-child,
.theme_buttons a:last-child {
  margin-right: 0;
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus,
input[type=submit]:hover,
input[type=submit]:active,
input[type=submit]:focus,
.wc-proceed-to-checkout .checkout-button:hover {
  color: #e7a93b;
  opacity: 1;
  text-decoration: none;
  outline: none;
}

button:hover,
button:active,
button:focus,
input[type=submit]:hover,
input[type=submit]:active,
input[type=submit]:focus,
.theme_buttons a:hover,
.theme_buttons a:active,
.theme_buttons a:focus,
.theme_button:hover,
.theme_button:active,
.theme_button:focus,
a.button:hover,
a.button:active,
a.button:focus {
  transition: all 0.05s linear 0s;
}

.theme_button[type=reset] {
  display: none;
}

.theme_buttons.inverse a,
.theme_button.inverse {
  color: #595d6f;
  background-color: transparent !important;
  border: 2px solid #595d6f;
  padding: 16px 28px;
}

.theme_buttons.inverse a:hover,
.theme_buttons.inverse a:focus,
.theme_buttons.inverse a:active,
.theme_button.inverse:hover,
.theme_button.inverse:focus,
.theme_button.inverse:active {
  color: #e7a93b;
}

@media (min-width: 1600px) {
  .theme_buttons.inverse a,
.theme_button.inverse {
    padding: 21px 38px;
  }
}
.theme_buttons.color1 a,
.theme_button.color1,
.button.alt,
.btn-accent,
.wc-proceed-to-checkout .checkout-button,
.form-submit [type=submit] {
  color: #ffffff;
  background-color: #e7a93b;
}

.theme_buttons.color1 a:hover,
.theme_buttons.color1 a:focus,
.theme_buttons.color1 a:active,
.theme_button.color1:hover,
.theme_button.color1:focus,
.theme_button.color1:active,
.button.alt:hover,
.button.alt:focus,
.button.alt:active,
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active,
.form-submit [type=submit]:hover,
.form-submit [type=submit]:focus,
.form-submit [type=submit]:active {
  color: #595d6f;
}

.theme_buttons[class*=color],
.theme_button[class*=color] {
  color: #ffffff;
}

.theme_buttons[class*=color]:hover,
.theme_buttons[class*=color]:focus,
.theme_buttons[class*=color]:active,
.theme_button[class*=color]:hover,
.theme_button[class*=color]:focus,
.theme_button[class*=color]:active {
  color: #003b53;
}

.theme_buttons.color2 a,
.theme_button.color2 {
  background-color: #d26308;
  border-color: #d26308;
}

.theme_buttons.inverse[class*=color]:hover,
.theme_buttons.inverse[class*=color]:active,
.theme_buttons.inverse[class*=color]:focus,
.theme_button.inverse[class*=color]:hover,
.theme_button.inverse[class*=color]:active,
.theme_button.inverse[class*=color]:focus {
  color: #595d6f;
}

.theme_buttons.color1.inverse a,
.theme_button.color1.inverse {
  color: #e7a93b;
  border-color: #e7a93b;
}

.theme_buttons.color2.inverse a,
.theme_button.color2.inverse {
  color: #d26308;
  border-color: #d26308;
}

.theme_buttons a:active,
.theme_button:active {
  top: 1px;
}

.muted_buttons a,
.muted_button {
  opacity: 0.2;
}

.small_buttons a,
.small_button {
  padding: 7px 10px;
  text-transform: uppercase;
  letter-spacing: 0;
}

.square_buttons a,
.square_button {
  padding: 0;
  width: 50px;
  line-height: 50px;
  margin: 0;
}

.round_buttons a,
.round_button {
  padding: 0;
  width: 60px;
  line-height: 60px;
  border-radius: 30px;
}

a.inactive {
  pointer-events: none;
}

.block_buttons a,
.block_button {
  display: block;
  width: 100%;
  text-align: center;
}

.min_width_button {
  min-width: 170px;
}

@media (min-width: 1600px) {
  .min_width_button {
    min-width: 200px;
  }
}
.two_lines_button {
  padding: 11px 35px;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*=rt-icon],
h2 > [class*=rt-icon],
h3 > [class*=rt-icon],
h4 > [class*=rt-icon],
h5 > [class*=rt-icon],
h6 > [class*=rt-icon] {
  position: relative;
  top: 0.08em;
  padding-right: 0.1em;
}

p > i {
  font-size: 1.1em;
  line-height: 0;
}

p > i + [class*=rt-icon] {
  margin-left: -0.75em;
}

.theme_buttons a > [class*=rt-icon],
.theme_buttons a > [class*=fa-],
.theme_button > [class*=rt-icon],
.theme_button > [class*=fa-] {
  line-height: 0;
  font-size: 1.5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.05em;
}

.form-inline [type=submit],
.form-inline .btn,
.form-inline .theme_button {
  margin-bottom: 0;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 16px 16px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
}

.more-link,
.view-more {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
}

.more-link:after {
  content: "";
  font-family: FontAwesome;
  padding-left: 4px;
}

.more-link:after {
  content: "";
  position: relative;
  top: 0;
  transition: top 0.2s ease 0s;
  margin-top: 0px;
}

.more-link:hover:after {
  top: 2px;
}

.view-more:after {
  content: "";
  position: relative;
  left: 0;
  transition: left 0.2s ease 0s;
  margin-left: 5px;
}

.view-more:hover:after {
  left: 5px;
}

:target:before {
  content: "";
  display: block;
  height: 150px;
  /* fixed header height*/
  margin: -150px 0 0;
  /* negative fixed header height */
}

/* vertical alignmetn in columns */
.table_section .row {
  min-width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  aside[class*=col-] {
    margin-top: 60px !important;
  }
}
@media (min-width: 768px) {
  .table_section_sm .container {
    height: 100%;
  }

  .table_section.table_section_sm .row {
    display: table;
    width: calc(100% + 30px);
  }

  .table_section.table_section_sm > [class*=container] > .row > [class*=col-] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}
@media (min-width: 992px) {
  .table_section_md .container {
    height: 100%;
  }

  .table_section.table_section_md .row {
    display: table;
    width: calc(100% + 30px);
  }

  .table_section.table_section_md > [class*=container] > .row > [class*=col-] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .offset-image {
    margin-top: -150px;
  }
}
@media screen and (min-width: 1200px) {
  .table_section_lg .container {
    height: 100%;
  }

  .table_section.table_section_lg .row {
    display: table;
    width: calc(100% + 30px);
  }

  .table_section.table_section_lg > [class*=container] > .row > [class*=col-] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}
@media screen and (min-width: 992px) {
  /* overlap featured person */
  .top-overlap {
    margin-top: -100px;
    max-width: 125%;
    margin-left: -25%;
  }

  .top-overlap-small {
    margin-top: -40px;
    max-width: 140%;
    margin-right: -20%;
    margin-left: -20%;
  }
}
@media (min-width: 1200px) {
  .top-overlap {
    margin-top: -180px;
  }
}
.section_full_height {
  min-height: 100vh;
}

.flex-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-row > [class*=col-] {
  min-width: 300px;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
}

.flex-row > [class*=col-] > * {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .flex-row > [class*=col-].col-md-6 {
    -ms-flex: auto;
    flex: auto;
    width: 50%;
  }
}
@media (max-width: 991px) {
  .flex-row > [class*=col-].col-sm-12 {
    -ms-flex: auto;
    flex: auto;
    width: 100%;
  }
}
.flex-row.flex-row-vertical-centered > [class*=col-] > * {
  -ms-flex-pack: center;
  justify-content: center;
}

/* float clear fixes */
@media (min-width: 768px) and (max-width: 991px) {
  .clear-sm {
    clear: both;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .clear-md {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .clear-lg {
    clear: both;
  }
}
@media (max-width: 499px) {
  .col-xxs-12 {
    width: 100%;
  }
}
@media (max-width: 499px) {
  .text-xxs-center {
    text-align: center;
  }
}
/*
** Common Sections Styles
*/
/*parallax*/
.image_cover,
.background_cover,
.parallax {
  background-size: cover;
}

.background_cover {
  background-position: center;
}

.image_cover {
  display: block;
  position: absolute;
  width: 50%;
  z-index: 2;
  top: 0;
  bottom: 0;
  background-position: 50% 50%;
}

.image_cover > img {
  visibility: hidden;
}

.image_cover > a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.image_cover.image_cover_right {
  right: 0;
}

.image_cover.small_width {
  width: 42%;
}

@media (max-width: 991px) {
  .image_cover {
    height: 0;
    padding-bottom: 66%;
    margin-bottom: -30px;
    width: 100%;
    position: relative;
  }

  .image_cover.small_width {
    width: 100%;
    padding-bottom: 100%;
  }
}
.image_cover + [class*=container] [class*=col-] > img,
.image_cover + [class*=container] [class*=col-] > a:after,
.image_cover + [class*=container] [class*=col-] > a > img {
  display: none;
}

.half_section .image_cover_left + .container .col-md-6 {
  padding-right: 0;
}

.half_section .image_cover_right + .container .col-md-6 {
  padding-left: 0;
}

.parallax {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 1199px) {
  .parallax {
    background-attachment: scroll !important;
    background-size: cover !important;
    background-position: center !important;
  }
}
.overlay_color.ls:after {
  background-color: #ffffff;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.overlay_color.ls.ms:after {
  background-color: #f7f7f7;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.overlay_color.cs:after {
  background-color: #e7a93b;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.overlay_color.cs.main_color2:after {
  background-color: #d26308;
}

.overlay_color.ds:after {
  background-color: #595d6f;
  opacity: 0.85;
}

.overlay_color.ds:after {
  background-color: #595d6f;
  opacity: 0.9;
}

.overlay_color.ds.ms:after {
  background-color: #1f232b;
  opacity: 0.9;
}

.overlay_color.ds.bs:after {
  background-color: #00202b;
  opacity: 0.67;
}

.overlay_color.ds.black:after {
  background-color: #000;
  opacity: 0.6;
}

.texture_bg {
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

.muted_section:before {
  background-color: rgba(58, 61, 68, 0.1);
  opacity: 1;
}

.muted_section > *,
.radial_gradient > *,
.gradient > *,
.vertical_gradient > *,
.darken_gradient > *,
.diagonal_section > *,
.overlay_color > * {
  z-index: 4;
  position: relative;
}

.radial_gradient {
  position: relative;
  overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.darken_gradient,
.overlay_color {
  position: relative;
}

.muted_section:before,
.muted_section:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.darken_gradient:before,
.overlay_color:after,
.darken_gradient:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.muted_section:before,
.muted_section:after,
.overlay_color:after {
  opacity: 0.8;
}

.darken_gradient:before {
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
}

.overflow_hidden {
  overflow: hidden;
  position: relative;
}

.with_top_border,
.with_bottom_border,
.with_top_border_container .container,
.with_bottom_border_container .container {
  position: relative;
}

.with_top_border:before,
.with_bottom_border:after,
.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(120, 120, 120, 0.2);
  z-index: 1;
}

.ds .with_top_border:before,
.ds .with_bottom_border:after,
.ds .with_top_border_container .container:before,
.ds .with_bottom_border_container .container:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.row.with_top_border:before,
.row.width_bottom_border:after {
  left: 15px;
  right: 15px;
}

.ds.with_top_border:before,
.ds.with_bottom_border:after,
.ds.with_top_border_container .container:before,
.ds.with_bottom_border_container .container:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
  right: 15px;
  left: 15px;
}

.with_bottom_border:after,
.with_bottom_border_container .container:after,
.with_bottom_border_two_colors:after {
  top: auto;
  bottom: 0;
}

h2.section_header {
  margin-bottom: 0.5em;
  font-size: 32px;
  position: relative;
  word-wrap: break-word;
  line-height: 1;
  border-left: 2px solid #e7a93b;
  padding: 10px 0 10px 20px;
}

h2.section_header.hive-secondary-blc {
  border-left-color: #d26308;
}

h2.section_header.hive-primary-blc {
  border-left-color: #e7a93b;
}

h2.section_header.dark {
  border-left-color: #003b53;
}

.text-center h2.section_header,
h2.section_header.text-center {
  border-left: none;
  padding: 0;
}

@media (min-width: 1200px) {
  h2.section_header {
    font-size: 40px;
    padding: 15px 0 15px 30px;
    margin-bottom: 0.75em;
  }
}
h2.section_header + .section-excerpt {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  h2.section_header + .section-excerpt {
    margin-top: 30px;
  }
}
h2.section_header:last-child {
  margin-bottom: 0;
}

.section-excerpt {
  font-size: 20px;
  font-style: italic;
}

/* animated elements */
.to_animate {
  visibility: hidden;
}

.no-js .to_animate,
.lt-ie10 .to_animate,
.lt-ie9 .to_animate {
  visibility: visible;
}

@media (min-width: 1230px) {
  .container {
    width: 1200px;
  }
}
.section_404 {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

/*
**utilites
*/
/*
**Helper Utilites
*/
/* layout */
.clear,
.clearfix {
  clear: both;
}

.alignleft,
.alignright {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .alignleft {
    float: left;
    margin: 5px 30px 15px 0;
    max-width: 50%;
  }

  .alignright {
    float: right;
    margin: 5px 0 15px 30px;
    max-width: 50%;
  }

  .alignright.one-third,
.alignleft.one-third {
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .alignright.one-third,
.alignleft.one-third {
    max-width: 33.33333%;
  }
}
@media (min-width: 1200px) {
  .col-xs-12 .alignleft {
    margin: 5px 50px 30px 0;
  }

  .col-xs-12 .alignright {
    margin: 5px 0 30px 50px;
  }
}
.round {
  border-radius: 50%;
}

.rounded {
  border-radius: 10px;
}

.top_rounded {
  border-radius: 10px 10px 0 0;
}

.bottom_rounded {
  border-radius: 0 0 10px 10px;
}

.overflow_hidden {
  overflow: hidden;
  z-index: 1;
}

.inline-block {
  display: inline-block;
  max-width: 100%;
}

.display-block {
  display: block;
}

.with_padding {
  padding: 20px;
}

@media (min-width: 400px) {
  .with_padding {
    padding: 35px;
  }
}
@media (min-width: 400px) {
  .with_padding.small_padding {
    padding: 25px;
  }
}
@media (min-width: 1200px) {
  .with_padding.big-padding {
    padding: 55px 60px;
  }
}
.with_background {
  background-color: #f2f2f2;
}

.with_background .with_background {
  background-color: #ffffff;
}

.muted_background {
  background-color: #f2f2f2;
}

.with-border {
  border: 1px solid #e4e4e4;
}

.with-border.thick_border {
  border-width: 10px;
}

.with_shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.with_big_shadow {
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.1);
}

.no_top_border {
  border-top: none;
}

.no_bottom_border {
  border-bottom: none;
}

.bottom-color-border {
  position: relative;
}

.bottom-color-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #e7a93b;
  z-index: 1;
}

.bottom-color-border.with-border:after {
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.bottom-color-border.color2:after {
  background-color: #d26308;
}

.top_color_border {
  border-top: 3px solid #e7a93b;
}

@media (max-width: 399px) {
  .with_abs_button.with_padding {
    padding-bottom: 35px;
  }
}
.bottom-border {
  position: relative;
}

.bottom-border:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover {
  overflow: hidden;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
  position: relative;
  z-index: 2;
}

.before_cover:before,
.after_cover:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-corner {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}

.big {
  font-size: 32px;
  line-height: 1;
}

strong.big {
  font-size: 24px;
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
}

/* borders, colors and backgrouns */
.response,
.highlight,
.highlight1 {
  color: #e7a93b;
  border-color: #e7a93b;
}

.highlight2 {
  color: #d26308;
  border-color: #d26308;
}

.highlight3 {
  color: #0d163d;
  border-color: #0d163d;
}

.highlight4 {
  color: #003b53;
  border-color: #003b53;
}

.red,
.required {
  color: #fa5c5d;
}

.lightgrey {
  color: #bfbfbf;
}

.black {
  color: #1f232b;
  border-color: #1f232b;
}

.grey {
  color: #595d6f;
  border-color: #595d6f;
}

.light {
  color: #ffffff;
}

.fontcolor {
  color: #787878;
}

.greylinks a {
  color: #787878;
}

.greylinks a.social-icon {
  color: rgba(120, 120, 120, 0.6);
}

.greylinks a.social-icon:hover,
.greylinks a.social-icon:focus,
.greylinks a.social-icon:active {
  color: #e7a93b;
}

.darklinks a {
  color: #595d6f;
}

.darklinks a:hover {
  color: #e7a93b;
}

.darklinks.color2 a:hover {
  color: #d26308;
}

.colorlinks a {
  color: #d26308;
}

.colorlinks a:hover {
  color: #e7a93b;
}

.underlined-links a {
  text-decoration: underline;
}

a.underline-link {
  text-decoration: underline;
}

.thin {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.weight-black {
  font-weight: 900;
}

.success_color {
  color: #72b946;
}

.info_color {
  color: #003b53;
}

.warning_color {
  color: #d26308;
}

.danger_color {
  color: #e7a93b;
}

.light_bg_color {
  background-color: #ffffff;
}

.transp_bg {
  background-color: transparent !important;
}

.theme_bg {
  background-color: #ffffff;
}

/*dividers*/
.divider_20 {
  margin: 20px 0;
}

.divider_30 {
  margin: 30px 0;
}

.divider_40 {
  margin: 40px 0;
}

.divider_60_2 {
  width: 60px;
  height: 2px;
}

.text-center hr[class*=divider_],
.text-center hr[class*=_divider] {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.fontsize_12 {
  font-size: 12px;
}

.fontsize_14 {
  font-size: 14px;
}

.fontsize_16 {
  font-size: 16px;
}

.fontsize_18 {
  font-size: 18px;
}

.fontsize_20 {
  font-size: 20px;
  line-height: 30px;
}

.fontsize_24 {
  font-size: 24px;
}

.fontsize_26 {
  font-size: 26px;
}

.fontsize_28 {
  font-size: 28px;
}

.fontsize_30 {
  font-size: 30px;
}

.fontsize_32 {
  font-size: 32px;
}

/*alignment*/
.display_table {
  display: table;
  min-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.display_table_cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
  overflow: hidden;
  zoom: 1;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .pull-sm-right {
    float: right;
  }

  .pull-sm-left {
    float: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-left {
    text-align: left;
  }

  .text-sm-center {
    text-align: center;
  }

  .display_table_sm {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_sm {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
    zoom: 1;
    box-sizing: border-box;
  }
}
@media (min-width: 992px) {
  .pull-md-right {
    float: right;
  }

  .pull-md-left {
    float: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-left {
    text-align: left;
  }

  .text-md-center {
    text-align: center;
  }

  .display_inline_md {
    display: inline;
  }

  .display_table_md {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_md {
    display: table-cell;
    vertical-align: middle;
    float: none;
    zoom: 1;
    box-sizing: border-box;
  }
}
@media (min-width: 1200px) {
  .pull-lg-right {
    float: right;
  }

  .pull-lg-left {
    float: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-left {
    text-align: left;
  }

  .text-lg-center {
    text-align: center;
  }

  .display_table_lg {
    display: table;
    min-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  .display_table_cell_lg {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
    zoom: 1;
    box-sizing: border-box;
  }
}
@media (max-width: 767px) {
  .text-xs-right {
    text-align: right;
  }

  .text-xs-left {
    text-align: left;
  }

  .text-xs-center {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .hidden-xxs {
    display: none;
  }
}
.inline-dropdown {
  list-style: none;
  margin: 0;
}

.inline-dropdown > li {
  display: inline-block;
  margin: 0 -2px;
}

.small-text {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.small-text.small {
  font-size: 12px;
}

.hero-text {
  font-size: 40px;
  font-weight: bold;
  line-height: 0.8em;
}

@media (min-width: 992px) {
  .hero-text {
    font-size: 80px;
  }
}
.nowrap {
  white-space: nowrap;
}

.media.inline-block {
  display: inline-block;
}

.media.inline-block .media-body {
  width: auto;
}

.content-justify {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-justify.content-margins > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-justify.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap:before,
.flex-wrap:after {
  display: none;
}

.row.flex-wrap.v-center {
  -ms-flex-align: center;
  align-items: center;
}

.row.flex-wrap.v-center-content {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row.flex-wrap.v-center-content > [class*=col-] {
  display: -ms-flexbox;
  display: flex;
}

.row.flex-wrap.v-center-content > [class*=col-] > * {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.inline-content {
  margin-right: -9px;
  margin-left: -9px;
}

.inline-content > * {
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
  margin-bottom: 0;
  margin-left: 9px;
}

.inline-content > *.media {
  margin-top: 10px;
  margin-bottom: 10px;
}

.inline-content.v-spacing > * {
  margin-bottom: 10px;
}

.inline-content.v-spacing > *:last-child {
  margin-right: 5px;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .inline-content.big-spacing {
    margin-right: -13px;
    margin-left: -13px;
  }

  .inline-content.big-spacing > * {
    margin-right: 13px;
    margin-left: 13px;
  }
}
@media (min-width: 1600px) {
  .inline-content.big-spacing {
    margin-right: -21px;
    margin-left: -21px;
  }

  .inline-content.big-spacing > * {
    margin-right: 21px;
    margin-left: 21px;
  }
}
.item-meta .inline-content > * {
  margin-right: 10px;
  margin-left: 10px;
}

.display-flex {
  display: -ms-flexbox;
  display: flex;
}

.v-center {
  -ms-flex-align: center;
  align-items: center;
}

.content-3lines-ellipsis {
  display: block;
  display: -webkit-box;
  font-size: 1rem;
  height: 5.625rem;
  -webkit-line-clamp: 3;
  line-height: 1.875em;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .md-content-3lines-ellipsis {
    display: block;
    display: -webkit-box;
    font-size: 1rem;
    height: 5.625rem;
    -webkit-line-clamp: 3;
    line-height: 1.875em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .two-cols-text {
    column-count: 2;
  }

  .two-cols-text ul {
    margin: 0;
  }
}
/* media items - common side and vertical items */
.with-border .item-media,
.with-border .entry-thumbnail {
  margin: -1px;
  z-index: 2;
}

.with-border .item-media + header,
.with-border .entry-thumbnail + header {
  margin: 0 -1px;
}

.with-border.full-padding .item-media {
  margin: 0;
}

/* side and vertical items paddings */
.side-item .row {
  margin: 0;
}

.side-item .row [class*=col-] {
  padding: 0;
  margin: 0;
}

.side-item.side-sm .item-content {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .side-item.side-sm .row {
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 992px) {
  .side-item.side-md .row {
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-md .row [class*=col-] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .side-item.side-md .col-lg-4 {
    width: 36%;
  }

  .side-item.side-md .col-lg-8 {
    width: 64%;
  }
}
@media (min-width: 992px) {
  .side-item.side-md.bottom-color-border:after {
    top: -1px;
    height: auto;
    width: 3px;
  }

  .side-item.side-md.bottom-color-border.left:after {
    left: auto;
  }
}
@media (min-width: 992px) {
  .side-item.side-md.right {
    display: -ms-flexbox;
    display: flex;
  }

  .side-item.side-md.right [class*=col-]:first-child {
    -ms-flex-order: 2;
    order: 2;
  }

  .side-item.side-md.right [class*=col-]:nth-child(2) {
    -ms-flex-order: 1;
    order: 1;
  }
}
/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
  .no-content-padding [class*=col-xs-][class*=col-xs-pull] .item-content,
.full-padding [class*=col-xs-][class*=col-xs-pull] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-xs-12 .item-content,
.full-padding .col-xs-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
  .no-content-padding [class*=col-sm-] .item-content,
.full-padding [class*=col-sm-] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*=col-sm-][class*=col-sm-pull] .item-content,
.full-padding [class*=col-sm-][class*=col-sm-pull] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-sm-12 .item-content,
.full-padding .col-sm-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
  .no-content-padding [class*=col-md-] .item-content,
.full-padding [class*=col-md-] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*=col-md-][class*=col-md-pull] .item-content,
.full-padding [class*=col-md-][class*=col-md-pull] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-md-12 .item-content,
.full-padding .col-md-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
  .no-content-padding [class*=col-lg-] .item-content,
.full-padding [class*=col-lg-] .item-content {
    padding-left: 30px;
  }

  .no-content-padding [class*=col-lg-][class*=col-lg-pull] .item-content,
.full-padding [class*=col-lg-][class*=col-lg-pull] .item-content {
    padding-left: 0;
    padding-right: 30px;
  }

  .no-content-padding .col-lg-12 .item-content,
.full-padding .col-lg-12 .item-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
.vertical-item .item-content {
  position: relative;
}

.vertical-item.post .item-content {
  padding-top: 0;
}

.vertical-item.content-padding .item-content {
  padding: 30px 20px;
}

@media (min-width: 400px) {
  .vertical-item.content-padding .item-content {
    padding: 35px 40px;
  }
}
@media (min-width: 1200px) {
  .vertical-item.big-padding .item-content {
    padding: 55px;
  }
}
@media (min-width: 1200px) {
  .vertical-item.big-vertical-padding .item-content {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
.vertical-item.content-absolute {
  position: relative;
}

.vertical-item.content-absolute .item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 2;
}

@media (min-width: 400px) {
  .vertical-item.content-absolute .item-content {
    padding: 35px 40px;
  }
}
@media (min-width: 1200px) {
  .vertical-item.content-absolute .item-content.big-padding {
    padding: 55px 60px;
  }
}
.vertical-item.content-absolute .item-content.ds {
  background-color: rgba(31, 35, 43, 0.8);
}

.vertical-item.content-absolute.v-center .item-content {
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.vertical-item.content-absolute.hover-content .item-content {
  transform: scaleY(0);
  transition: transform 0.3s ease 0s;
}

.vertical-item.content-absolute.hover-content:hover .item-content {
  transform: scaleY(1);
}

.vertical-item + .item-title {
  padding: 10px 30px;
}

.vertical-item + .item-title h2,
.vertical-item + .item-title h3,
.vertical-item + .item-title h4 {
  margin: 0;
}

.side-item.content-padding .item-content {
  padding: 25px 20px;
}

@media (min-width: 400px) {
  .side-item.content-padding .item-content {
    padding: 35px 40px;
  }
}
@media (min-width: 1200px) {
  .side-item.big-padding .item-content {
    padding: 55px 60px;
  }
}
.item-media-wrap {
  position: relative;
}

/* item media links */
.item-media-wrap {
  line-height: 0;
}

.item-media-wrap > * {
  line-height: 1.625rem;
}

.item-media {
  position: relative;
  overflow: hidden;
}

.item-media img {
  width: auto;
}

.media-links {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent !important;
}

.media-links div {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -25px;
  z-index: 4;
}

.media-links:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 35, 43, 0.6);
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.2s linear;
}

.media-links.color-overlay:before {
  opacity: 1;
}

[class*=-item]:hover .media-links.color-overlay:before {
  opacity: 0;
}

[class*=-item]:hover .media-links:before {
  opacity: 1;
}

.media-links.no-overlay:before {
  display: none;
}

.media-links.inverse:before {
  opacity: 1;
}

[class*=-item]:hover .media-links.inverse:before {
  opacity: 0;
}

.media-links a.abs-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

[class*=-item]:hover .media-links a.abs-link {
  opacity: 1;
}

[class*=-item] .links-wrap a,
[class*=-item] .links-wrap > span {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: -100px;
  border-radius: 30px;
  display: inline-block;
  z-index: 5;
  font-size: 16px;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*=-item] .links-wrap a.p-view {
  color: #ffffff;
}

[class*=-item] .links-wrap a.p-view:hover {
  color: #e7a93b;
}

[class*=-item] .links-wrap a.p-link {
  color: #595d6f;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

[class*=-item] .links-wrap a.p-link:hover {
  color: #e7a93b;
}

[class*=-item] .links-wrap a:before {
  font-family: "FontAwesome";
  content: "";
}

[class*=-item] .links-wrap a.p-link:before {
  content: "";
  font-size: 14px;
}

[class*=-item] .links-wrap > span {
  font-size: 24px;
}

.media-links .bottom-links {
  top: auto;
  bottom: -20px;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*=-item]:hover .links-wrap a,
[class*=-item]:hover .links-wrap > span {
  top: 0px;
  filter: alpha(opacity=100);
  opacity: 1;
}

[class*=-item]:hover .media-links div {
  filter: alpha(opacity=100);
  opacity: 1;
}

[class*=-item]:hover .media-links .bottom-links {
  bottom: 30px;
  opacity: 1;
}

.featured-post {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 0 0 100% 0;
}

.featured-post i {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #fff;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .featured-post {
    width: 60px;
    height: 60px;
  }

  .featured-post i {
    top: 19px;
    left: 19px;
  }
}
/*
** Header
*/
.header_white {
  color: #787878;
  background-color: #ffffff;
}

.header_white.affix {
  box-shadow: 0px 1px 15px 0 rgba(0, 0, 0, 0.07);
}

.header_darkgrey {
  color: #ffffff;
  background-color: #595d6f;
}

.header_darkgrey.affix {
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
}

.header_darkgrey a[class*=socicon-] {
  color: #ffffff;
}

.header_darkgrey .grey,
.header_darkgrey .black {
  color: #ffffff;
}

.header_darkgrey.with_top_border:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds:not(.ms) + .header_darkgrey > header:not(.ms) {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.affix.page_header [class*=container]:before {
  background-color: transparent !important;
}

.header_color {
  background-color: #e7a93b;
}

@media (min-width: 768px) {
  .header_transparent_wrap {
    position: relative;
    height: 0 !important;
  }

  .header_transparent_wrap .header_transparent:not(.affix).header_darkgrey {
    background-color: rgba(31, 35, 43, 0.75);
  }

  .transparent_wrapper {
    position: relative;
    height: 0 !important;
  }

  .transparent_wrapper > *:not(.page_header_wrapper) {
    position: relative;
    z-index: 3;
    background-color: transparent;
  }

  .transparent_wrapper .page_header:not(.affix) {
    background-color: transparent;
  }
}
.page_header {
  transition: background 0.2s ease-in-out 0s, margin 0.6s ease-in-out 0s;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  z-index: 1000;
  margin: 0;
  padding: 0;
  height: auto;
}

.page_header.affix {
  position: fixed;
  z-index: 1001;
}

.page_header.mobile-active {
  z-index: 1002;
}

.page_header.affix-bottom {
  top: 0 !important;
  position: fixed;
}

.page_header [class*=display_table_cell] {
  overflow: visible;
}

.page_header [class*=col-] {
  min-height: 0;
}

@media (max-width: 1599px) {
  .page_header .header_right_buttons h5 {
    font-size: 16px;
  }

  .page_header .header_right_buttons .big {
    font-size: 24px;
  }
}
.page_header .header-button {
  line-height: 60px;
}

.header_right_buttons .inline-list.small-padding > li {
  padding-top: 0;
  padding-bottom: 0;
}

/* header floats */
.page_header:before,
.page_header:after {
  content: "";
  display: block;
  clear: both;
}

.page_header {
  height: auto;
}

.header_mainmenu {
  overflow: visible;
  width: 10000px;
  padding: 0 15px;
}

.header_right_buttons {
  white-space: nowrap;
  padding: 0 15px;
  text-align: right;
  overflow: visible;
}

.header_right_buttons.ls {
  background-color: transparent !important;
}

/*
** Side Header
*/
/* side header layout */
body {
  position: relative;
  left: 0;
}

body:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
}

body,
body:after,
.page_header_side {
  transition: all 0.6s ease-in-out;
}

body {
  transition-property: left, right;
}

.page_header_side {
  z-index: 1002;
  position: fixed;
  width: 270px;
  left: -270px;
  top: 0;
  bottom: 0;
}

.page_header_side.active-slide-side-header {
  box-shadow: 0px 0px 4px 3px rgba(50, 50, 50, 0.05);
}

.page_header_side ~ * .toggle_menu {
  display: none;
}

.page_header_side .widget {
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.page_header_side .social-icons {
  display: block;
  margin: 20px 30px 0;
}

.side_header_inner {
  padding: 10px 0 60px;
}

.toggle_menu_side {
  position: absolute;
  top: 5px;
  right: -65px;
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px rgba(50, 50, 50, 0.05);
  border-radius: 0;
  z-index: 2;
}

.toggle_menu_side:before,
.toggle_menu_side span,
.toggle_menu_side span:before,
.toggle_menu_side span:after {
  content: "";
  display: block;
  width: 24px;
  position: absolute;
  background-color: #595d6f;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  height: 2px;
}

.toggle_menu_side:before,
.toggle_menu_side span {
  left: 18px;
  top: 50%;
}

.toggle_menu_side span:before {
  opacity: 1;
  top: -6px;
}

.toggle_menu_side span:after {
  opacity: 1;
  top: 6px;
}

.active-slide-side-header .toggle_menu_side:before {
  transform: rotate(45deg);
}

.active-slide-side-header .toggle_menu_side span {
  transform: rotate(-45deg);
}

.active-slide-side-header .toggle_menu_side span:before,
.active-slide-side-header .toggle_menu_side span:after {
  opacity: 0;
}

.ds .toggle_menu_side {
  background-color: #3d3d47;
}

.ds .toggle_menu_side span,
.ds .toggle_menu_side span:before,
.ds .toggle_menu_side span:after,
.ds .toggle_menu_side:before {
  background-color: #ffffff;
}

.bs .toggle_menu_side {
  background-color: #00202b;
}

@media (max-width: 350px) {
  .toggle_menu_side {
    top: 0;
    right: -50px;
  }
}
.page_header_side.page_header_side_sticked .toggle_menu_side {
  transition: 0.6s ease-in-out;
  right: -65px;
  top: 5px;
}

.page_header_side.active-slide-side-header {
  left: 0;
}

.container {
  max-width: 100%;
}

@media (max-width: 767px) {
  .container {
    max-width: 500px;
  }
}
@media (min-width: 600px) {
  .page_header_side.page_header_side_sticked.active-slide-side-header {
    left: 0;
  }

  .page_header_side_sticked ~ * {
    transition: margin 0.6s ease-in-out;
  }

  .page_header_side_sticked ~ div .page_header {
    padding-left: 35px;
  }

  .page_header_side_sticked.active-slide-side-header ~ div .page_header {
    padding-left: 0;
    margin-left: 270px;
  }

  .page_header_side_sticked.active-slide-side-header ~ * {
    margin-left: 270px;
  }

  .page_header_side_sticked ~ .page_header_wrapper {
    margin-left: 0;
  }
}
.ts {
  background-color: rgba(0, 0, 0, 0);
}

/*
** Light Section - .ls
*/
.ls {
  background-color: #ffffff;
  color: #787878;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
  color: #1f232b;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a {
  color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover {
  color: #e7a93b;
}

.ls [class*=color_bg] .highlight {
  color: #ffffff;
}

.ls .light_bg_color {
  color: #595d6f;
}

.ls a {
  color: #e7a93b;
}

.ls a:hover {
  color: #595d6f;
}

.ls .grey {
  color: #595d6f;
}

.ls .highlight,
.ls .highlight1 {
  color: #e7a93b;
  border-color: #e7a93b;
}

.ls .highlight2 {
  color: #d26308;
  border-color: #d26308;
}

.ls .greylinks a {
  color: #787878;
}

.ls .greylinks a:hover,
.ls .greylinks a:focus,
.ls .greylinks a:active {
  color: #e7a93b;
}

.ls .greylinks a.social-icon:not(:hover) {
  color: #bfbfbf;
}

.ls .greylinks.color2 a:hover,
.ls .greylinks.color2 a:focus,
.ls .greylinks.color2 a:active {
  color: #d26308;
}

.ls .darklinks a {
  color: #595d6f;
}

.ls .darklinks a:hover {
  color: #e7a93b;
}

.ls .color2.darklinks a:hover {
  color: #d26308;
}

.ls .highlightlinks a,
.ls .highlightlink {
  color: #e7a93b;
}

.ls .highlightlinks a:hover,
.ls .highlightlink:hover {
  color: #595d6f;
}

.ls .highlight2links a,
.ls .highlight2link {
  color: #d26308;
}

.ls .highlight2links a:hover,
.ls .highlight2link:hover {
  color: #595d6f;
}

.ls .highlight3links a,
.ls .highlight3link {
  color: #e7a93b;
}

.ls .highlight3links a:hover,
.ls .highlight3link:hover {
  color: #595d6f;
}

.ls .highlight4links a,
.ls .highlight4link {
  color: #003b53;
}

.ls .highlight4links a:hover,
.ls .highlight4link:hover {
  color: #595d6f;
}

.ls .colorlinks a {
  color: #d26308;
}

.ls .colorlinks a:hover {
  color: #e7a93b;
}

.ls .breadcrumb > li + li:before {
  color: #787878;
}

.ls .breadcrumb > .active {
  color: #595d6f;
}

.ls .nav-unstyled .active a {
  color: #e7a93b;
}

.ls .nav-unstyled.color2 .active a {
  color: #d26308;
}

.ls .theme_buttons a,
.ls .theme_button {
  color: #ffffff;
}

.ls .theme_buttons a:hover,
.ls .theme_buttons a:focus,
.ls .theme_buttons a:active,
.ls .theme_button:hover,
.ls .theme_button:focus,
.ls .theme_button:active {
  color: #e7a93b;
}

.ls .theme_buttons.inverse a,
.ls .theme_button.inverse {
  color: #595d6f;
}

.ls .theme_buttons.inverse a:hover,
.ls .theme_buttons.inverse a:focus,
.ls .theme_buttons.inverse a:active,
.ls .theme_button.inverse:hover,
.ls .theme_button.inverse:focus,
.ls .theme_button.inverse:active {
  color: #e7a93b;
}

.ls .theme_buttons.color1.iverse a,
.ls .theme_button.color1.inverse {
  color: #e7a93b;
}

.ls .theme_buttons.color2.iverse a,
.ls .theme_button.color2.inverse {
  color: #d26308;
}

.ls .theme_buttons[class*=color],
.ls .theme_button[class*=color] {
  color: #ffffff;
}

.ls .theme_buttons[class*=color]:hover,
.ls .theme_buttons[class*=color]:focus,
.ls .theme_buttons[class*=color]:active,
.ls .theme_button[class*=color]:hover,
.ls .theme_button[class*=color]:focus,
.ls .theme_button[class*=color]:active {
  color: #595d6f;
}

.ls:not(.ms) + .page_copyright.ls {
  background-color: #f7f7f7;
}

.ls .select-group:hover select + .theme_button,
.ls .select-group:hover select + i {
  color: #e7a93b;
}

/*
** Grey Section - light section, muted section - .ls.ms
*/
.ls.ms {
  background-color: #f7f7f7;
}

.ls.ms.dark {
  background-color: #eaeaea;
}

.ls.ms .with_background {
  background-color: #ffffff;
}

.ls.ms .theme_bg .with_background {
  background-color: #f2f2f2;
}

.ls.ms hr {
  background-color: rgba(120, 120, 120, 0.2);
}

.ls.ms .with_background input[type=text],
.ls.ms .with_background input[type=email],
.ls.ms .with_background input[type=url],
.ls.ms .with_background input[type=password],
.ls.ms .with_background input[type=search],
.ls.ms .with_background input[type=tel],
.ls.ms .with_background input[type=number],
.ls.ms .with_background textarea,
.ls.ms .with_background select,
.ls.ms .with_background .form-control {
  border-color: rgba(120, 120, 120, 0.2);
}

.ls.ms td,
.ls.ms th,
.ls.ms .with-border {
  border-color: #d7d7d7;
}

.ls.ms .bottom-color-border {
  border-bottom-color: #e7a93b;
}

.ls.ms .bottom_color2_border {
  border-bottom-color: #d26308;
}

.ls.ms .widget_calendar tbody {
  background-color: #ffffff;
}

.ls.ms .owl-nav > div {
  background-color: #fff;
}

/*
** Dark Section and Dark Muted sections - .ds and .ds.ms
*/
/* darkgrey section - .ds */
.ds {
  background-color: #595d6f;
  background-color: #373945;
  color: #b3b3bb;
}

.ds.parallax .theme-divider,
.ds.background_cover .theme-divider {
  background-color: #ffffff;
}

/* dark section - .ds.ms */
.ds.ms {
  background-color: #1f232b;
}

/* dark section blue - .ds.bs */
.ds.bs {
  background-color: #00202b;
}

.ls .inverse-bg {
  background-color: #595d6f;
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
  color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
  color: inherit;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
  color: #e7a93b;
}

.ds .ls {
  background-color: #ffffff;
  color: #787878;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
  color: #595d6f;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
  color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
  color: #e7a93b;
}

.ds .ls a {
  color: #e7a93b;
}

.ds .ls a:hover {
  color: #595d6f;
}

.ds .ls .darklinks a {
  color: #595d6f;
}

.ds .ls .darklinks a:hover {
  color: #e7a93b;
}

.ds .ls .greylinks a {
  color: #787878;
}

.ds .ls .greylinks a:hover {
  color: #d26308;
}

.ds .ls .highlightlinks a:hover {
  color: #595d6f;
}

.ds .ls hr {
  background-color: #e4e4e4;
}

.ds .ls .highlight {
  color: #e7a93b;
}

.ds .ls .grey {
  color: #595d6f;
}

.ds label {
  color: #ffffff;
}

.ds a {
  color: #ffffff;
}

.ds a:hover {
  color: #e7a93b;
}

.ds hr {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .social-icon.color-icon:hover {
  color: #ffffff;
}

.ds .muted_background,
.ds .with_background,
.ds .well {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .inverse-bg {
  background-color: #ffffff;
}

.ds .theme_bg {
  background-color: #000;
}

.ds.muted_section:before {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .with-border {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .highlight,
.ds .highlight1 {
  color: #e7a93b;
  border-color: #e7a93b;
}

.ds .highlight2 {
  color: #d26308;
  border-color: #d26308;
}

.ds .grey,
.ds .black {
  color: #ffffff;
  border-color: #ffffff;
}

.ds .lightgrey {
  color: #e0e0e1;
}

.ds .darkgrey {
  color: #787878;
}

.ds .colorlinks a {
  color: #d26308;
}

.ds .colorlinks a:hover {
  color: #e7a93b;
}

.ds .greylinks a {
  color: #e0e0e1;
}

.ds .greylinks a:hover {
  color: #e7a93b;
}

.ds .greylinks a.social-icon {
  color: #e0e0e1;
}

.ds .darklinks a,
.ds .darklink {
  color: #ffffff;
}

.ds .darklinks a:hover,
.ds .darklink:hover {
  color: #e7a93b;
}

.ds .highlightlinks a,
.ds .highlightlink {
  color: #e7a93b;
}

.ds .highlightlinks a:hover,
.ds .highlightlink:hover {
  color: #ffffff;
}

.ds .highlight2links a,
.ds .highlight2link {
  color: #d26308;
}

.ds .highlight2links a:hover,
.ds .highlight2link:hover {
  color: #ffffff;
}

.ds.with_top_border:before,
.ds.with_bottom_border:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds input[type=text],
.ds input[type=email],
.ds input[type=url],
.ds input[type=password],
.ds input[type=search],
.ds input[type=tel],
.ds input[type=number],
.ds textarea,
.ds select,
.ds .form-control,
.ds .select-group {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.5);
}

.ds .with_background .widget_search [type=text],
.ds .with_background .widget_search [type=search],
.ds .with_background .widget_product_search [type=search],
.ds .with_background .widget_mailchimp [type=email] {
  background-color: #595d6f;
  border-color: rgba(255, 255, 255, 0.2);
}

.ds form label + [class*=icon-] {
  color: #787878;
}

.ds option {
  color: #595d6f;
}

.ds .form-group-select:before {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .form-control:-moz-placeholder {
  color: #e0e0e1;
}

.ds .form-control::-moz-placeholder {
  color: #e0e0e1;
}

.ds .form-control:-ms-input-placeholder {
  color: #e0e0e1;
}

.ds .form-control::-webkit-input-placeholder {
  color: #e0e0e1;
}

.ds .form-control:focus:-moz-placeholder {
  color: transparent;
}

.ds .form-control:focus::-moz-placeholder {
  color: transparent;
}

.ds .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.ds .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.ds .contact-form.transparent-background .form-control {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td,
.ds table th,
.ds table td {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds table th {
  color: #ffffff;
}

.ds .table_template th,
.ds .table_template td {
  border-color: transparent;
}

.ds .table_template th {
  color: inherit;
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon:hover {
  color: #ffffff;
}

.ds .color-bg-icon:hover {
  background-color: #e7a93b;
}

.ds .nav-unstyled {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .nav-tabs > li > a {
  border-color: #ffffff;
  color: #ffffff;
}

.ds .nav-tabs > li > a:hover,
.ds .nav-tabs > li.active > a,
.ds .nav-tabs > li.active > a:hover,
.ds .nav-tabs > li.active > a:focus {
  border-color: transparent;
  background-color: #787878;
  color: #ffffff;
}

.ds .price-table.style1 {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .plan-price p {
  color: #ffffff;
}

.ds .features-list .disabled:before {
  color: #ffffff;
}

.ds .features-list li + li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds ul.list1 li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .testimonials-carousel .media + p {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .post-adds .share_button > i,
.ds .post-adds .like_button > i {
  color: #ffffff;
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_nav_menu ul ul {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_popular_entries .media-heading {
  color: #ffffff;
}

.ds .tweet_list li + li .tweet_right {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_tag_cloud a {
  color: #ffffff;
}

.ds .widget_shopping_cart li a + a {
  color: #ffffff;
}

.ds .widget_shopping_cart li a + a:hover {
  color: #e7a93b;
}

.ds .widget .woocommerce-Price-amount {
  color: #ffffff;
}

.ds .summary .price {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .summary .product_meta {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_price_filter .price_label {
  color: #ffffff;
}

.ds .widget_calendar table {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar tfoot td a {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar caption {
  color: #ffffff;
}

.ds .widget_calendar thead {
  background-color: #f2f2f2;
}

.ds .widget_calendar tbody {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar th {
  color: #595d6f;
}

.ds .widget_calendar tbody td a:hover {
  color: #ffffff;
}

.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_pages li,
.ds .widget_nav_menu li,
.ds .widget_popular_entries li,
.ds .widget_recent_posts li {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_recent_entries > ul > li:last-child,
.ds .widget_recent_comments > ul > li:last-child,
.ds .widget_archive > ul > li:last-child,
.ds .widget_categories > ul > li:last-child,
.ds .widget_meta > ul > li:last-child,
.ds .widget_popular_entries > ul > li:last-child,
.ds .widget_nav_menu ul.menu > li:last-child,
.ds .widget_pages > ul > li:last-child {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.ds .grid-view h3:before,
.ds .grid-view h3:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel .owl-nav > div {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel .owl-nav > div:after {
  color: #ffffff;
}

.ds .owl-carousel .owl-nav > div:hover:after {
  color: #e7a93b;
}

.ds .ui-slider {
  background-color: #ffffff;
}

.ds .ui-slider .ui-slider-handle {
  background-color: #ffffff;
}

.ds .widget_layered_nav .color-filters a:before {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .table.cart a {
  color: #ffffff;
}

.ds .thumbnail {
  background-color: transparent;
}

.ds .thumbnail h3 a {
  color: #ffffff;
}

.ds .thumbnail .caption {
  border-color: transparent;
  background-color: rgba(252, 252, 252, 0.05);
}

.ds .progress {
  background-color: #f7f7f7;
}

.ds .panel-body {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds div .fw-shortcode-calendar-wrapper .page-header h3,
.ds div .btn-group button[data-calendar-nav],
.ds div .btn-group button[data-calendar-nav*=today] {
  color: #ffffff;
}

.ds .text-nav .owl-nav > div {
  background-color: #595d6f;
}

.ds .text-nav .owl-nav > div:after {
  color: #ffffff;
}

.ds .text-nav .owl-nav > div:hover:after {
  color: #d26308;
}

.ds a.border-icon {
  border-color: #787878;
}

.ds .nav-unstyled .active a {
  color: #ffffff;
}

.ds .nav-unstyled .active a {
  color: #e7a93b;
}

.ds .theme_buttons a,
.ds .theme_button {
  color: #ffffff;
  background-color: #515660;
}

.ds .theme_buttons a:hover,
.ds .theme_buttons a:focus,
.ds .theme_buttons a:active,
.ds .theme_button:hover,
.ds .theme_button:focus,
.ds .theme_button:active {
  color: #e7a93b;
}

.ds .theme_buttons.inverse a,
.ds .theme_button.inverse {
  color: #515660;
  background-color: #ffffff;
  border-color: #515660;
}

.ds .theme_buttons.inverse a:hover,
.ds .theme_buttons.inverse a:focus,
.ds .theme_buttons.inverse a:active,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus,
.ds .theme_button.inverse:active {
  color: #e7a93b;
}

.ds .theme_buttons.color1 a,
.ds .theme_button.color1,
.ds .button.alt,
.ds .btn-accent,
.ds .wc-proceed-to-checkout .checkout-button,
.ds .form-submit [type=submit] {
  color: #ffffff;
  background-color: #e7a93b;
  border-color: #e7a93b;
}

.ds .theme_buttons.color1 a:hover,
.ds .theme_buttons.color1 a:focus,
.ds .theme_buttons.color1 a:active,
.ds .theme_button.color1:hover,
.ds .theme_button.color1:focus,
.ds .theme_button.color1:active,
.ds .button.alt:hover,
.ds .button.alt:focus,
.ds .button.alt:active,
.ds .btn-accent:hover,
.ds .btn-accent:focus,
.ds .btn-accent:active,
.ds .wc-proceed-to-checkout .checkout-button:hover,
.ds .wc-proceed-to-checkout .checkout-button:focus,
.ds .wc-proceed-to-checkout .checkout-button:active,
.ds .form-submit [type=submit]:hover,
.ds .form-submit [type=submit]:focus,
.ds .form-submit [type=submit]:active {
  color: #595d6f;
}

.ds .theme_buttons.color1.inverse a,
.ds .theme_button.color1.inverse {
  color: #e7a93b;
  background-color: #ffffff;
}

.ds .theme_buttons.color1.inverse a:hover,
.ds .theme_buttons.color1.inverse a:focus,
.ds .theme_buttons.color1.inverse a:active,
.ds .theme_button.color1.inverse:hover,
.ds .theme_button.color1.inverse:focus,
.ds .theme_button.color1.inverse:active {
  color: #ffffff;
  background-color: #e7a93b;
}

.ds .theme_buttons.color2 a,
.ds .theme_button.color2 {
  background-color: #d26308;
}

.ds .theme_buttons.color2.inverse a,
.ds .theme_button.color2.inverse {
  color: #d26308;
  border-color: #d26308;
}

.ds .theme_buttons[class*=color]:hover,
.ds .theme_buttons[class*=color]:active,
.ds .theme_buttons[class*=color]:focus,
.ds .theme_button[class*=color]:hover,
.ds .theme_button[class*=color]:active,
.ds .theme_button[class*=color]:focus {
  color: #b3b3bb;
}

.ds .theme_buttons.inverse[class*=color]:hover,
.ds .theme_buttons.inverse[class*=color]:active,
.ds .theme_buttons.inverse[class*=color]:focus,
.ds .theme_button.inverse[class*=color]:hover,
.ds .theme_button.inverse[class*=color]:active,
.ds .theme_button.inverse[class*=color]:focus {
  color: #ffffff;
}

.ds .dropdown-menu {
  background-color: #595d6f;
}

.ds .content-padding footer:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.ds .item-content + footer.item-meta > div + div,
.ds .item-content + footer.item-meta {
  border-color: rgba(255, 255, 255, 0.2);
}

.ds .teaser .dashed_border {
  background-image: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.ds .format-chat.with_background .entry-content p:nth-child(2n) {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds h2.section_header + hr + .section-excerpt {
  font-weight: 300;
  color: #ffffff;
}

.ds .media_items_list .media {
  background-color: rgba(255, 255, 255, 0.05);
}

.ds .media_items_list .media .media-body {
  border-color: rgba(255, 255, 255, 0.2);
}

/*
** Color Section - .cs
*/
.cs.main_color2 {
  background-color: #d26308;
}

.cs.main_color2 .highlight {
  color: #e7a93b;
}

.cs.main_color2 .theme_button.color2 {
  color: #d26308;
  background-color: #fff;
}

.cs.main_color2 .theme_button.color2:hover,
.cs.main_color2 .theme_button.color2:focus,
.cs.main_color2 .theme_button.color2:active {
  color: #595d6f;
}

.cs.gradient {
  background: linear-gradient(-90deg, #d26308, #e7a93b);
}

.cs.gradient .breadcrumb > .active {
  color: #595d6f;
}

.cs {
  background-color: #e7a93b;
  color: #fff;
}

.cs.parallax {
  color: rgba(255, 255, 255, 0.8);
}

.cs .ls .greylinks a {
  color: #787878;
}

.cs .ls .greylinks a:hover,
.cs .ls .greylinks a:focus,
.cs .ls .greylinks a:active {
  color: #787878;
}

.cs .ls h1 a:hover,
.cs .ls h2 a:hover,
.cs .ls h3 a:hover,
.cs .ls h4 a:hover,
.cs .ls h5 a:hover,
.cs .ls h6 a:hover {
  color: #e7a93b;
}

.cs .ds .greylinks a {
  color: #787878;
}

.cs .ds .greylinks a:hover,
.cs .ds .greylinks a:focus,
.cs .ds .greylinks a:active {
  color: #787878;
}

.cs .ds h1,
.cs .ds h2,
.cs .ds h3,
.cs .ds h4,
.cs .ds h5,
.cs .ds h6 {
  color: #ffffff;
}

.cs .ds h1 a,
.cs .ds h2 a,
.cs .ds h3 a,
.cs .ds h4 a,
.cs .ds h5 a,
.cs .ds h6 a {
  color: inherit;
}

.cs .ds h1 a:hover,
.cs .ds h2 a:hover,
.cs .ds h3 a:hover,
.cs .ds h4 a:hover,
.cs .ds h5 a:hover,
.cs .ds h6 a:hover {
  color: #e7a93b;
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6 {
  color: #ffffff;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a {
  color: inherit;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover {
  color: #595d6f;
}

.cs h2.section_header {
  border-left-color: #fff;
}

.cs a {
  color: #ffffff;
}

.cs a:hover,
.cs a:active,
.cs a:focus {
  color: rgba(255, 255, 255, 0.5);
}

.cs .with_background {
  background-color: rgba(255, 255, 255, 0.23);
}

.cs .with-border {
  border-color: rgba(255, 255, 255, 0.23);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after {
  background-color: #ee7c6f;
}

.cs .greylinks a,
.cs .greylinks a.social-icon {
  color: #595d6f;
}

.cs .greylinks a:hover,
.cs .greylinks a.social-icon:hover {
  color: #ffffff;
}

.cs .darklinks a {
  color: #ffffff;
}

.cs .darklinks a:hover {
  color: #d26308;
}

.cs .highlightlinks a,
.cs .highlightlinks a.social-icon {
  color: rgba(255, 255, 255, 0.5);
}

.cs .black {
  border-color: #ffffff;
  color: #ffffff;
}

.cs .grey {
  border-color: #595d6f;
  color: #595d6f;
}

.cs .lightgrey {
  color: rgba(255, 255, 255, 0.6);
}

.cs .response,
.cs .highlight {
  color: #fff;
}

.cs .breadcrumb > .active,
.cs .breadcrumb > li:last-child {
  color: #595d6f;
}

.cs .light_bg_color .highlight,
.cs .light_bg_color.highlight {
  color: #e7a93b;
}

.cs ul.list1 li {
  border-color: rgba(255, 255, 255, 0.23);
}

.cs [class*=socicon-] {
  border-color: rgba(255, 255, 255, 0.23);
}

.cs .color-icon.bg-icon {
  background-color: rgba(255, 255, 255, 0.23);
}

.cs a.social-icon.color-bg-icon:hover {
  background-color: #595d6f;
}

.cs .theme_buttons a:hover,
.cs .theme_buttons a:focus,
.cs .theme_buttons a:active,
.cs .theme_button:hover,
.cs .theme_button:focus,
.cs .theme_button:active {
  color: #e7a93b;
}

.cs .theme_buttons.inverse a,
.cs .theme_button.inverse {
  color: #595d6f;
  border-color: #595d6f;
}

.cs .theme_buttons.inverse a:hover,
.cs .theme_buttons.inverse a:focus,
.cs .theme_buttons.inverse a:active,
.cs .theme_button.inverse:hover,
.cs .theme_button.inverse:focus,
.cs .theme_button.inverse:active {
  color: #ffffff;
}

.cs .theme_buttons[class*=color],
.cs .theme_button[class*=color] {
  color: #ffffff;
}

.cs .theme_buttons[class*=color]:hover,
.cs .theme_buttons[class*=color]:focus,
.cs .theme_buttons[class*=color]:active,
.cs .theme_button[class*=color]:hover,
.cs .theme_button[class*=color]:focus,
.cs .theme_button[class*=color]:active {
  color: #595d6f;
}

.cs .theme_buttons.color1 a,
.cs .theme_button.color1 {
  color: #e7a93b;
  background-color: #ffffff;
}

.cs .theme_buttons.color1 a:hover,
.cs .theme_buttons.color1 a:focus,
.cs .theme_buttons.color1 a:active,
.cs .theme_button.color1:hover,
.cs .theme_button.color1:focus,
.cs .theme_button.color1:active {
  color: #595d6f;
}

.cs .theme_buttons.color1.inverse a,
.cs .theme_button.color1.inverse {
  color: #ffffff;
  border-color: #ffffff;
}

.cs .theme_buttons.color1.inverse a:hover,
.cs .theme_buttons.color1.inverse a:focus,
.cs .theme_buttons.color1.inverse a:active,
.cs .theme_button.color1.inverse:hover,
.cs .theme_button.color1.inverse:focus,
.cs .theme_button.color1.inverse:active {
  color: #595d6f;
}

.cs .theme_buttons.color2.inverse a,
.cs .theme_button.color2.inverse {
  color: #d26308;
  border-color: #d26308;
}

.cs .theme_buttons.color2.inverse a:hover,
.cs .theme_buttons.color2.inverse a:focus,
.cs .theme_buttons.color2.inverse a:active,
.cs .theme_button.color2.inverse:hover,
.cs .theme_button.color2.inverse:focus,
.cs .theme_button.color2.inverse:active {
  color: #ffffff;
  background-color: #d26308;
}

.cs .list3 li:after {
  color: #fff;
}

.cs .filters a,
.cs .filters a.selected,
.cs .filters a:hover {
  color: #ffffff;
}

.cs .filters a:after,
.cs .filters a.selected:after,
.cs .filters a:hover:after {
  background-color: #ffffff;
}

.cs.with_top_border:before,
.cs.with_bottom_border:after,
.cs.with_top_border_container .container:before,
.cs.with_bottom_border_container .container:after {
  background-color: rgba(255, 255, 255, 0.23);
}

.cs .flex-control-nav.owl-carousel:after {
  background-color: #ffffff;
}

/* title section (breadcrumbs) styles */
.page_breadcrumbs .breadcrumbs_logo {
  margin: 0 0 15px;
}

.page_breadcrumbs .breadcrumbs_logo img {
  opacity: 0.3;
}

.page_breadcrumbs h2 {
  display: inline;
  font-size: 30px;
  font-weight: 500;
  line-height: 0.9em;
  word-wrap: break-word;
}

.page_breadcrumbs h2 + .breadcrumb {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .page_breadcrumbs h2 {
    font-size: 40px;
  }
}
.page_breadcrumbs h2.small {
  font-size: 30px;
}

@media screen and (min-width: 992px) {
  .page_breadcrumbs .display_table_md {
    width: auto;
    min-width: 0;
  }

  .page_breadcrumbs h2.display_table_cell_md {
    padding-right: 20px;
    padding-left: 15px;
  }

  .page_breadcrumbs .breadcrumb.display_table_cell_md {
    border-left: 1px solid rgba(255, 255, 255, 0.23);
    padding-left: 25px;
  }
}
.page_breadcrumbs .bottom_breadcrumbs {
  text-align: center;
  float: right;
  padding: 4px 20px 0;
  margin: 0 0 -25px 0;
}

@media (max-width: 1199px) {
  .page_breadcrumbs.section_padding_top_65.section_padding_bottom_65 > [class*=container] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
/* template parallax sections */
.page_breadcrumbs.background_cover {
  background-image: url(/assets/images/parallax/breadcrumbs.jpg);
}

.page_footer.parallax,
.page_footer .parallax.parallax,
.page_copyright.parallax,
.page_contacts.parallax {
  background-image: url(/assets/images/parallax/bottom.jpg);
}

@media (max-width: 1199px) {
  .page_footer.parallax + .page_copyright.parallax {
    background-image: none;
  }

  .page_footer.parallax + .page_copyright.parallax > [class*=container]:before,
.page_footer.parallax + .page_copyright.parallax:before {
    display: none !important;
  }
}
/* Other */
@media (min-width: 1200px) and (max-width: 1599px) {
  .section_intro_overlap .teaser[class*=_bg_color],
.section_intro_overlap .teaser[class*=with_] {
    padding: 30px;
  }

  .section_intro_overlap .teaser_icon.thick_border_icon[class*=_bg_color].big_wrapper,
.section_intro_overlap .teaser_icon.thick_border_icon.with_background.big_wrapper {
    width: calc(2em + 20px);
    height: calc(2em + 20px);
  }

  .section_intro_overlap .teaser_icon.thick_border_icon[class*=_bg_color].big_wrapper > *,
.section_intro_overlap .teaser_icon.thick_border_icon.with_background.big_wrapper > * {
    line-height: 2em;
  }
}
/*
** Shortcodes
*/
/*
** Teasers
*/
.media.inline-block {
  margin-top: 10px;
}

.media.inline-block.teaser:first-child {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .media.topmargin_40 {
    margin-top: 80px;
  }
}
.media-left i,
.meida-right i {
  display: block;
  width: 1.1em;
  line-height: 1.625;
}

.media-left i.fontsize_24,
.meida-right i.fontsize_24 {
  line-height: 1;
}

.inline-teasers-wrap {
  display: inline-block;
  margin-left: -15px;
  margin-right: -15px;
}

.inline-teasers-wrap > * {
  vertical-align: middle;
  margin: 10px 15px;
}

@media (min-width: 1200px) {
  .inline-teasers-wrap {
    margin-left: -20px;
    margin-right: -20px;
  }

  .inline-teasers-wrap > * {
    margin: 10px 20px;
  }
}
/* teaser */
.teaser {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.teaser.media h2,
.teaser.media h3,
.teaser.media h4,
.teaser.media h5,
.teaser.media h6 {
  line-height: 1em;
}

.teaser h2,
.teaser h3,
.teaser h4,
.teaser h5,
.teaser h6 {
  margin-bottom: 0.4em;
}

.teaser[class*=_bg_color],
.teaser[class*=with_] {
  padding: 30px 20px;
}

@media (min-width: 400px) {
  .teaser[class*=_bg_color],
.teaser[class*=with_] {
    padding: 40px;
  }
}
@media (min-width: 1600px) {
  .teaser[class*=_bg_color].big-padding,
.teaser[class*=with_].big-padding {
    padding: 50px;
  }
}
.teaser i {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

.teaser .media-body {
  line-height: 1.5em;
}

.teaser .media-body h3 {
  margin-bottom: 10px;
}

.teaser h3[class*=count] {
  font-size: 48px;
  font-weight: 700;
  margin: 10px 0 0;
}

.teaser h3[class*=count] + p {
  font-size: 20px;
  font-weight: 600;
}

.teaser .theme_button {
  margin: 22px 0 0;
}

.teaser .counter-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 48%;
  font-size: 180px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.1;
  transform: translateY(-50%);
}

.teaser .icon-background {
  position: absolute;
  opacity: 0.1;
  top: 50%;
  left: 50%;
  right: 0;
  font-size: 300px;
  transform: translate(-50%, -50%);
  z-index: -1;
  max-width: 100%;
  width: 100%;
}

.teaser .icon-background.small {
  font-size: 84px;
}

.teaser i.icon-background {
  margin-top: 0;
  margin-bottom: 0;
}

.teaser.font-icon-background-teaser {
  padding: 48px 25px 48px;
  overflow: hidden;
}

.teaser.counter-background-teaser {
  overflow: hidden;
  padding: 98px 25px 98px;
}

.teaser.counter-background-teaser .small-text {
  line-height: 1.4em;
}

.teaser.inner-border:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.teaser_icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  transition: all 0.2s ease-in-out 0s;
}

.teaser_icon[class*=_bg_color],
.teaser_icon.with_background {
  width: 2em;
  height: 2em;
  text-align: center;
  vertical-align: bottom;
}

.teaser_icon[class*=_bg_color] > *,
.teaser_icon.with_background > * {
  line-height: 2em;
}

.teaser_icon[class*=_bg_color].big_wrapper,
.teaser_icon.with_background.big_wrapper {
  width: 2.6667em;
  height: 2.6667em;
}

.teaser_icon[class*=_bg_color].big_wrapper > *,
.teaser_icon.with_background.big_wrapper > * {
  line-height: 2.6667em;
}

.teaser_icon.border_icon {
  width: 2em;
  height: 2em;
  border-width: 2px;
  border-style: solid;
}

.teaser_icon.border_icon + h3,
.teaser_icon.border_icon + h4 {
  margin-top: 28px;
}

.teaser_icon.border_icon * {
  line-height: 2em;
  margin-top: -2px;
}

.teaser_icon[class*=label-] {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  vertical-align: bottom;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
}

.teaser_icon + h2 {
  margin-top: 40px;
}

.teaser_icon.big_wrapper + h4 {
  margin-top: 33px;
}

.teaser_icon + p {
  margin-top: 27px;
}

.teaser_icon.thick_border_icon {
  border: 10px solid rgba(31, 35, 43, 0.07);
  background-clip: content-box;
}

.teaser_icon.thick_border_icon[class*=_bg_color],
.teaser_icon.thick_border_icon.with_background {
  width: calc(2em + 20px);
  height: calc(2em + 20px);
}

.teaser_icon.thick_border_icon[class*=_bg_color].big_wrapper,
.teaser_icon.thick_border_icon.with_background.big_wrapper {
  width: calc(2.6667em + 20px);
  height: calc(2.6667em + 20px);
}

.size_small {
  font-size: 30px;
}

[class*=color_bg] {
  color: #ffffff;
}

.teaser.media.after_cover {
  padding: 25px 30px;
}

.teaser.media.after_cover i {
  opacity: 0.3;
}

.teaser.media.after_cover p {
  text-transform: uppercase;
}

/* contact forms */
.contact-form {
  position: relative;
  z-index: 3;
}

.contact-form label[for] {
  display: none;
}

@media (min-width: 768px) {
  .contact-form-message textarea {
    min-height: 200px;
  }
}
.contact-form.parallax {
  padding: 15px 20px 5px;
  background-image: url(/assets/images/parallax/map.jpg);
}

@media (min-width: 400px) {
  .contact-form.parallax {
    padding: 35px 40px 25px;
  }
}
@media (min-width: 1200px) {
  .contact-form.parallax {
    padding: 55px 60px 45px;
  }
}
.contact-form-respond {
  display: block;
  font-weight: 800;
}

.contact-form-respond:before {
  font-family: "rt-icons-2";
  content: "";
  padding-right: 10px;
}

.contact-form p.form-group {
  margin-bottom: 15px;
}

.contact-form [class*=col-] .form-group {
  margin: 0;
}

.contact-form [class*=col-] .form-group + .form-group {
  margin-top: 10px;
}

.contact-form.transparent-background .form-control {
  background-color: transparent;
}

.contact-email {
  font-size: 40px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .contact-email {
    font-size: 20px;
    margin-bottom: 6px;
  }

  .contact-form.parallax {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
}
.light-shadow-text {
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

.dark-shadow-text {
  text-shadow: 0px 3px 3px black;
}

.clickable {
  cursor: pointer;
}

.clickable-text {
  cursor: pointer;
}