@import 'variables.scss';

$colors: (
  'hive-dark': $hive-dark,
  'hive-darker': $hive-darker,
  'hive-light': $hive-light,
  'hive-lighter': $hive-lighter,
  'hive-primary': $hive-primary,
  'hive-secondary': $hive-secondary,
  'hive-white': $hive-white,
  'hive-blue': $hive-blue
);

@each $name, $value in $colors {
  .#{$name} {
    color: #{$value} !important;
  }
  .#{$name}-darken5 {
    color: darken($value, 5%) !important;
  }
  .#{$name}-darken10 {
    color: darken($value, 10%) !important;
  }
  .#{$name}-darken15 {
    color: darken($value, 15%) !important;
  }
  .#{$name}-lighten5 {
    color: lighten($value, 5%) !important;
  }
  .#{$name}-lighten10 {
    color: lighten($value, 10%) !important;
  }
  .#{$name}-lighten15 {
    color: lighten($value, 15%) !important;
  }

  .#{$name}-bg {
    background-color: #{$value} !important;
  }
  .#{$name}-bg-darken5 {
    background-color: darken($value, 5%) !important;
  }
  .#{$name}-bg-darken10 {
    background-color: darken($value, 10%) !important;
  }
  .#{$name}-bg-darken15 {
    background-color: darken($value, 15%) !important;
  }
  .#{$name}-bg-lighten5 {
    background-color: lighten($value, 5%) !important;
  }
  .#{$name}-bg-lighten10 {
    background-color: lighten($value, 10%) !important;
  }
  .#{$name}-bg-lighten15 {
    background-color: lighten($value, 15%) !important;
  }
}
