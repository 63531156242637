@import 'variables.scss';
/*
** Social Icons
*/
/* layout */
a[class*='socicon-']:before {
  font-family: 'socicon';
  font-size: 14px;
  line-height: 1;
}

a.social-icon {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

a.social-icon span {
  opacity: 0.7;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}

a.social-icon span:first-child {
  font-size: 24px;
  margin-top: 8px;
  font-weight: bold;
}

a.social-icon [class*='rt-icon'] + span {
  font-size: 24px;
  margin-top: 8px;
  font-weight: bold;
}

a.social-icon + a.social-icon,
.tooltip + a.social-icon {
  margin-left: 25px;
}

a.social-icon.bg-icon + a.social-icon.bg-icon,
.tooltip + a.social-icon.bg-icon,
a.social-icon.color-bg-icon + a.social-icon.color-bg-icon,
.tooltip + a.social-icon.color-bg-icon,
a.social-icon.border-icon + a.social-icon.border-icon,
.tooltip + a.social-icon.border-icon {
  margin-left: 6px;
}

a.social-icon.block-icon {
  width: 100%;
}

a[class*='socicon-'].rounded-icon {
  border-radius: 50%;
}

a.big-icon {
  width: 112px;
  height: 112px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

a.big-icon[class*='socicon-']:before {
  font-size: 32px;
}

a.big-icon [class*='rt-icon']:before {
  font-size: 32px;
}

/* corporate colors */
a.color-icon.socicon-twitter {
  color: #1da1f2;
}

a.color-icon.socicon-facebook {
  color: #3b5998;
}

a.color-icon.socicon-google {
  color: #4285f4;
}

a.color-icon.socicon-googleplus {
  color: #db4437;
}

a.color-icon.socicon-pinterest {
  color: #bd081c;
}

a.color-icon.socicon-foursquare {
  color: #f94877;
}

a.color-icon.socicon-yahoo {
  color: #430297;
}

a.color-icon.socicon-skype {
  color: #00aff0;
}

a.color-icon.socicon-yelp {
  color: #c83218;
}

a.color-icon.socicon-feedburner {
  color: #ffcc00;
}

a.color-icon.socicon-linkedin {
  color: #007bb5;
}

a.color-icon.socicon-viadeo {
  color: #e4a000;
}

a.color-icon.socicon-xing {
  color: #005a60;
}

a.color-icon.socicon-myspace {
  color: #323232;
}

a.color-icon.socicon-soundcloud {
  color: #ff5500;
}

a.color-icon.socicon-spotify {
  color: #1ed760;
}

a.color-icon.socicon-grooveshark {
  color: #f67e03;
}

a.color-icon.socicon-lastfm {
  color: #d41316;
}

a.color-icon.socicon-youtube {
  color: #ff0000;
}

a.color-icon.socicon-vimeo {
  color: #1ab7ea;
}

a.color-icon.socicon-dailymotion {
  color: #004e72;
}

a.color-icon.socicon-vine {
  color: #00b489;
}

a.color-icon.socicon-flickr {
  color: #f40083;
}

a.color-icon.socicon-500px {
  color: #0099e5;
}

a.color-icon.socicon-instagram {
  color: #c32aa3;
}

a.color-icon.socicon-wordpress {
  color: #464646;
}

a.color-icon.socicon-tumblr {
  color: #35465d;
}

a.color-icon.socicon-blogger {
  color: #ec661c;
}

a.color-icon.socicon-technorati {
  color: #5cb030;
}

a.color-icon.socicon-reddit {
  color: #ff4500;
}

a.color-icon.socicon-dribbble {
  color: #ea4c89;
}

a.color-icon.socicon-stumbleupon {
  color: #e64011;
}

a.color-icon.socicon-digg {
  color: #1d1d1b;
}

a.color-icon.socicon-envato {
  color: #597c3a;
}

a.color-icon.socicon-behance {
  color: #00bf8f;
}

a.color-icon.socicon-delicious {
  color: #31a9ff;
}

a.color-icon.socicon-deviantart {
  color: #05cc47;
}

a.color-icon.socicon-forrst {
  color: #5b9a68;
}

a.color-icon.socicon-play {
  color: #5acdbd;
}

a.color-icon.socicon-zerply {
  color: #9dbc7a;
}

a.color-icon.socicon-wikipedia {
  color: #000000;
}

a.color-icon.socicon-apple {
  color: #a6b1b7;
}

a.color-icon.socicon-flattr {
  color: #f67c1a;
}

a.color-icon.socicon-github {
  color: #00bf8f;
}

a.color-icon.socicon-chimein {
  color: #888688;
}

a.color-icon.socicon-friendfeed {
  color: #2f72c4;
}

a.color-icon.socicon-newsvine {
  color: #00b489;
}

a.color-icon.socicon-identica {
  color: #000000;
}

a.color-icon.socicon-bebo {
  color: #ef1011;
}

a.color-icon.socicon-zynga {
  color: #dc0606;
}

a.color-icon.socicon-steam {
  color: #171a21;
}

a.color-icon.socicon-xbox {
  color: #92c83e;
}

a.color-icon.socicon-windows {
  color: #00bdf6;
}

a.color-icon.socicon-outlook {
  color: #0072c6;
}

a.color-icon.socicon-coderwall {
  color: #3e8dcc;
}

a.color-icon.socicon-tripadvisor {
  color: #000000;
}

a.color-icon.socicon-appnet {
  color: #494949;
}

a.color-icon.socicon-goodreads {
  color: #1abc9c;
}

a.color-icon.socicon-tripit {
  color: #1982c3;
}

a.color-icon.socicon-lanyrd {
  color: #3c80c9;
}

a.color-icon.socicon-slideshare {
  color: #e68523;
}

a.color-icon.socicon-buffer {
  color: #000000;
}

a.color-icon.socicon-rss {
  color: #f26109;
}

a.color-icon.socicon-vkontakte {
  color: #4a76a8;
}

a.color-icon.socicon-disqus {
  color: #2e9fff;
}

a.color-icon.socicon-houzz {
  color: #7cc04b;
}

a.color-icon.socicon-mail {
  color: #000000;
}

a.color-icon.socicon-patreon {
  color: #e44727;
}

a.color-icon.socicon-paypal {
  color: #009cde;
}

a.color-icon.socicon-playstation {
  color: #000000;
}

a.color-icon.socicon-smugmug {
  color: #acfd32;
}

a.color-icon.socicon-swarm {
  color: #ffa633;
}

a.color-icon.socicon-triplej {
  color: #e53531;
}

a.color-icon.socicon-yammer {
  color: #1175c4;
}

a.color-icon.socicon-stackoverflow {
  color: #fd9827;
}

a.color-icon.socicon-drupal {
  color: #00598e;
}

a.color-icon.socicon-odnoklassniki {
  color: #f48420;
}

a.color-icon.socicon-android {
  color: #a4c639;
}

a.color-icon.socicon-meetup {
  color: #e2373c;
}

a.color-icon.socicon-persona {
  color: #e6753d;
}

a.color-icon.socicon-amazon {
  color: #ff9900;
}

a.color-icon.socicon-ello {
  color: #000000;
}

a.color-icon.socicon-mixcloud {
  color: #314359;
}

a.color-icon.socicon-8tracks {
  color: #122c4b;
}

a.color-icon.socicon-twitch {
  color: #6441a5;
}

a.color-icon.socicon-airbnb {
  color: #ff5a5f;
}

a.color-icon.socicon-pocket {
  color: #ee4056;
}

a[class*='socicon-'].socicon-twitter:hover {
  color: #1da1f2;
  border-color: #1da1f2;
}

a[class*='socicon-'].socicon-facebook:hover {
  color: #3b5998;
  border-color: #3b5998;
}

a[class*='socicon-'].socicon-google:hover {
  color: #4285f4;
  border-color: #4285f4;
}

a[class*='socicon-'].socicon-googleplus:hover {
  color: #db4437;
  border-color: #db4437;
}

a[class*='socicon-'].socicon-pinterest:hover {
  color: #bd081c;
  border-color: #bd081c;
}

a[class*='socicon-'].socicon-foursquare:hover {
  color: #f94877;
  border-color: #f94877;
}

a[class*='socicon-'].socicon-yahoo:hover {
  color: #430297;
  border-color: #430297;
}

a[class*='socicon-'].socicon-skype:hover {
  color: #00aff0;
  border-color: #00aff0;
}

a[class*='socicon-'].socicon-yelp:hover {
  color: #c83218;
  border-color: #c83218;
}

a[class*='socicon-'].socicon-feedburner:hover {
  color: #ffcc00;
  border-color: #ffcc00;
}

a[class*='socicon-'].socicon-linkedin:hover {
  color: #007bb5;
  border-color: #007bb5;
}

a[class*='socicon-'].socicon-viadeo:hover {
  color: #e4a000;
  border-color: #e4a000;
}

a[class*='socicon-'].socicon-xing:hover {
  color: #005a60;
  border-color: #005a60;
}

a[class*='socicon-'].socicon-myspace:hover {
  color: #323232;
  border-color: #323232;
}

a[class*='socicon-'].socicon-soundcloud:hover {
  color: #ff5500;
  border-color: #ff5500;
}

a[class*='socicon-'].socicon-spotify:hover {
  color: #1ed760;
  border-color: #1ed760;
}

a[class*='socicon-'].socicon-grooveshark:hover {
  color: #f67e03;
  border-color: #f67e03;
}

a[class*='socicon-'].socicon-lastfm:hover {
  color: #d41316;
  border-color: #d41316;
}

a[class*='socicon-'].socicon-youtube:hover {
  color: #ff0000;
  border-color: #ff0000;
}

a[class*='socicon-'].socicon-vimeo:hover {
  color: #1ab7ea;
  border-color: #1ab7ea;
}

a[class*='socicon-'].socicon-dailymotion:hover {
  color: #004e72;
  border-color: #004e72;
}

a[class*='socicon-'].socicon-vine:hover {
  color: #00b489;
  border-color: #00b489;
}

a[class*='socicon-'].socicon-flickr:hover {
  color: #f40083;
  border-color: #f40083;
}

a[class*='socicon-'].socicon-500px:hover {
  color: #0099e5;
  border-color: #0099e5;
}

a[class*='socicon-'].socicon-instagram:hover {
  color: #c32aa3;
  border-color: #c32aa3;
}

a[class*='socicon-'].socicon-wordpress:hover {
  color: #464646;
  border-color: #464646;
}

a[class*='socicon-'].socicon-tumblr:hover {
  color: #35465d;
  border-color: #35465d;
}

a[class*='socicon-'].socicon-blogger:hover {
  color: #ec661c;
  border-color: #ec661c;
}

a[class*='socicon-'].socicon-technorati:hover {
  color: #5cb030;
  border-color: #5cb030;
}

a[class*='socicon-'].socicon-reddit:hover {
  color: #ff4500;
  border-color: #ff4500;
}

a[class*='socicon-'].socicon-dribbble:hover {
  color: #ea4c89;
  border-color: #ea4c89;
}

a[class*='socicon-'].socicon-stumbleupon:hover {
  color: #e64011;
  border-color: #e64011;
}

a[class*='socicon-'].socicon-digg:hover {
  color: #1d1d1b;
  border-color: #1d1d1b;
}

a[class*='socicon-'].socicon-envato:hover {
  color: #597c3a;
  border-color: #597c3a;
}

a[class*='socicon-'].socicon-behance:hover {
  color: #00bf8f;
  border-color: #00bf8f;
}

a[class*='socicon-'].socicon-delicious:hover {
  color: #31a9ff;
  border-color: #31a9ff;
}

a[class*='socicon-'].socicon-deviantart:hover {
  color: #05cc47;
  border-color: #05cc47;
}

a[class*='socicon-'].socicon-forrst:hover {
  color: #5b9a68;
  border-color: #5b9a68;
}

a[class*='socicon-'].socicon-play:hover {
  color: #5acdbd;
  border-color: #5acdbd;
}

a[class*='socicon-'].socicon-zerply:hover {
  color: #9dbc7a;
  border-color: #9dbc7a;
}

a[class*='socicon-'].socicon-wikipedia:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-apple:hover {
  color: #a6b1b7;
  border-color: #a6b1b7;
}

a[class*='socicon-'].socicon-flattr:hover {
  color: #f67c1a;
  border-color: #f67c1a;
}

a[class*='socicon-'].socicon-github:hover {
  color: #00bf8f;
  border-color: #00bf8f;
}

a[class*='socicon-'].socicon-chimein:hover {
  color: #888688;
  border-color: #888688;
}

a[class*='socicon-'].socicon-friendfeed:hover {
  color: #2f72c4;
  border-color: #2f72c4;
}

a[class*='socicon-'].socicon-newsvine:hover {
  color: #00b489;
  border-color: #00b489;
}

a[class*='socicon-'].socicon-identica:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-bebo:hover {
  color: #ef1011;
  border-color: #ef1011;
}

a[class*='socicon-'].socicon-zynga:hover {
  color: #dc0606;
  border-color: #dc0606;
}

a[class*='socicon-'].socicon-steam:hover {
  color: #171a21;
  border-color: #171a21;
}

a[class*='socicon-'].socicon-xbox:hover {
  color: #92c83e;
  border-color: #92c83e;
}

a[class*='socicon-'].socicon-windows:hover {
  color: #00bdf6;
  border-color: #00bdf6;
}

a[class*='socicon-'].socicon-outlook:hover {
  color: #0072c6;
  border-color: #0072c6;
}

a[class*='socicon-'].socicon-coderwall:hover {
  color: #3e8dcc;
  border-color: #3e8dcc;
}

a[class*='socicon-'].socicon-tripadvisor:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-appnet:hover {
  color: #494949;
  border-color: #494949;
}

a[class*='socicon-'].socicon-goodreads:hover {
  color: #1abc9c;
  border-color: #1abc9c;
}

a[class*='socicon-'].socicon-tripit:hover {
  color: #1982c3;
  border-color: #1982c3;
}

a[class*='socicon-'].socicon-lanyrd:hover {
  color: #3c80c9;
  border-color: #3c80c9;
}

a[class*='socicon-'].socicon-slideshare:hover {
  color: #e68523;
  border-color: #e68523;
}

a[class*='socicon-'].socicon-buffer:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-rss:hover {
  color: #f26109;
  border-color: #f26109;
}

a[class*='socicon-'].socicon-vkontakte:hover {
  color: #4a76a8;
  border-color: #4a76a8;
}

a[class*='socicon-'].socicon-disqus:hover {
  color: #2e9fff;
  border-color: #2e9fff;
}

a[class*='socicon-'].socicon-houzz:hover {
  color: #7cc04b;
  border-color: #7cc04b;
}

a[class*='socicon-'].socicon-mail:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-patreon:hover {
  color: #e44727;
  border-color: #e44727;
}

a[class*='socicon-'].socicon-paypal:hover {
  color: #009cde;
  border-color: #009cde;
}

a[class*='socicon-'].socicon-playstation:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-smugmug:hover {
  color: #acfd32;
  border-color: #acfd32;
}

a[class*='socicon-'].socicon-swarm:hover {
  color: #ffa633;
  border-color: #ffa633;
}

a[class*='socicon-'].socicon-triplej:hover {
  color: #e53531;
  border-color: #e53531;
}

a[class*='socicon-'].socicon-yammer:hover {
  color: #1175c4;
  border-color: #1175c4;
}

a[class*='socicon-'].socicon-stackoverflow:hover {
  color: #fd9827;
  border-color: #fd9827;
}

a[class*='socicon-'].socicon-drupal:hover {
  color: #00598e;
  border-color: #00598e;
}

a[class*='socicon-'].socicon-odnoklassniki:hover {
  color: #f48420;
  border-color: #f48420;
}

a[class*='socicon-'].socicon-android:hover {
  color: #a4c639;
  border-color: #a4c639;
}

a[class*='socicon-'].socicon-meetup:hover {
  color: #e2373c;
  border-color: #e2373c;
}

a[class*='socicon-'].socicon-persona:hover {
  color: #e6753d;
  border-color: #e6753d;
}

a[class*='socicon-'].socicon-amazon:hover {
  color: #ff9900;
  border-color: #ff9900;
}

a[class*='socicon-'].socicon-ello:hover {
  color: #000000;
  border-color: #000000;
}

a[class*='socicon-'].socicon-mixcloud:hover {
  color: #314359;
  border-color: #314359;
}

a[class*='socicon-'].socicon-8tracks:hover {
  color: #122c4b;
  border-color: #122c4b;
}

a[class*='socicon-'].socicon-twitch:hover {
  color: #6441a5;
  border-color: #6441a5;
}

a[class*='socicon-'].socicon-airbnb:hover {
  color: #ff5a5f;
  border-color: #ff5a5f;
}

a[class*='socicon-'].socicon-pocket:hover {
  color: #ee4056;
  border-color: #ee4056;
}

/*corporate background colors*/
.color-bg-icon {
  color: #ffffff;
}

.color-bg-icon.socicon-twitter,
a.socicon-twitter.bg-icon:hover,
.socicon-twitter.color-icon.border-icon:hover {
  background-color: #1da1f2;
}

.color-bg-icon.socicon-facebook,
a.socicon-facebook.bg-icon:hover,
.socicon-facebook.color-icon.border-icon:hover {
  background-color: #3b5998;
}

.color-bg-icon.socicon-google,
a.socicon-google.bg-icon:hover,
.socicon-google.color-icon.border-icon:hover {
  background-color: #4285f4;
}

.color-bg-icon.socicon-googleplus,
a.socicon-googleplus.bg-icon:hover,
.socicon-googleplus.color-icon.border-icon:hover {
  background-color: #db4437;
}

.color-bg-icon.socicon-pinterest,
a.socicon-pinterest.bg-icon:hover,
.socicon-pinterest.color-icon.border-icon:hover {
  background-color: #bd081c;
}

.color-bg-icon.socicon-foursquare,
a.socicon-foursquare.bg-icon:hover,
.socicon-foursquare.color-icon.border-icon:hover {
  background-color: #f94877;
}

.color-bg-icon.socicon-yahoo,
a.socicon-yahoo.bg-icon:hover,
.socicon-yahoo.color-icon.border-icon:hover {
  background-color: #430297;
}

.color-bg-icon.socicon-skype,
a.socicon-skype.bg-icon:hover,
.socicon-skype.color-icon.border-icon:hover {
  background-color: #00aff0;
}

.color-bg-icon.socicon-yelp,
a.socicon-yelp.bg-icon:hover,
.socicon-yelp.color-icon.border-icon:hover {
  background-color: #c83218;
}

.color-bg-icon.socicon-feedburner,
a.socicon-feedburner.bg-icon:hover,
.socicon-feedburner.color-icon.border-icon:hover {
  background-color: #ffcc00;
}

.color-bg-icon.socicon-linkedin,
a.socicon-linkedin.bg-icon:hover,
.socicon-linkedin.color-icon.border-icon:hover {
  background-color: #007bb5;
}

.color-bg-icon.socicon-viadeo,
a.socicon-viadeo.bg-icon:hover,
.socicon-viadeo.color-icon.border-icon:hover {
  background-color: #e4a000;
}

.color-bg-icon.socicon-xing,
a.socicon-xing.bg-icon:hover,
.socicon-xing.color-icon.border-icon:hover {
  background-color: #005a60;
}

.color-bg-icon.socicon-myspace,
a.socicon-myspace.bg-icon:hover,
.socicon-myspace.color-icon.border-icon:hover {
  background-color: #323232;
}

.color-bg-icon.socicon-soundcloud,
a.socicon-soundcloud.bg-icon:hover,
.socicon-soundcloud.color-icon.border-icon:hover {
  background-color: #ff5500;
}

.color-bg-icon.socicon-spotify,
a.socicon-spotify.bg-icon:hover,
.socicon-spotify.color-icon.border-icon:hover {
  background-color: #1ed760;
}

.color-bg-icon.socicon-grooveshark,
a.socicon-grooveshark.bg-icon:hover,
.socicon-grooveshark.color-icon.border-icon:hover {
  background-color: #f67e03;
}

.color-bg-icon.socicon-lastfm,
a.socicon-lastfm.bg-icon:hover,
.socicon-lastfm.color-icon.border-icon:hover {
  background-color: #d41316;
}

.color-bg-icon.socicon-youtube,
a.socicon-youtube.bg-icon:hover,
.socicon-youtube.color-icon.border-icon:hover {
  background-color: #ff0000;
}

.color-bg-icon.socicon-vimeo,
a.socicon-vimeo.bg-icon:hover,
.socicon-vimeo.color-icon.border-icon:hover {
  background-color: #1ab7ea;
}

.color-bg-icon.socicon-dailymotion,
a.socicon-dailymotion.bg-icon:hover,
.socicon-dailymotion.color-icon.border-icon:hover {
  background-color: #004e72;
}

.color-bg-icon.socicon-vine,
a.socicon-vine.bg-icon:hover,
.socicon-vine.color-icon.border-icon:hover {
  background-color: #00b489;
}

.color-bg-icon.socicon-flickr,
a.socicon-flickr.bg-icon:hover,
.socicon-flickr.color-icon.border-icon:hover {
  background-color: #f40083;
}

.color-bg-icon.socicon-500px,
a.socicon-500px.bg-icon:hover,
.socicon-500px.color-icon.border-icon:hover {
  background-color: #0099e5;
}

.color-bg-icon.socicon-instagram,
a.socicon-instagram.bg-icon:hover,
.socicon-instagram.color-icon.border-icon:hover {
  background-color: #c32aa3;
}

.color-bg-icon.socicon-wordpress,
a.socicon-wordpress.bg-icon:hover,
.socicon-wordpress.color-icon.border-icon:hover {
  background-color: #464646;
}

.color-bg-icon.socicon-tumblr,
a.socicon-tumblr.bg-icon:hover,
.socicon-tumblr.color-icon.border-icon:hover {
  background-color: #35465d;
}

.color-bg-icon.socicon-blogger,
a.socicon-blogger.bg-icon:hover,
.socicon-blogger.color-icon.border-icon:hover {
  background-color: #ec661c;
}

.color-bg-icon.socicon-technorati,
a.socicon-technorati.bg-icon:hover,
.socicon-technorati.color-icon.border-icon:hover {
  background-color: #5cb030;
}

.color-bg-icon.socicon-reddit,
a.socicon-reddit.bg-icon:hover,
.socicon-reddit.color-icon.border-icon:hover {
  background-color: #ff4500;
}

.color-bg-icon.socicon-dribbble,
a.socicon-dribbble.bg-icon:hover,
.socicon-dribbble.color-icon.border-icon:hover {
  background-color: #ea4c89;
}

.color-bg-icon.socicon-stumbleupon,
a.socicon-stumbleupon.bg-icon:hover,
.socicon-stumbleupon.color-icon.border-icon:hover {
  background-color: #e64011;
}

.color-bg-icon.socicon-digg,
a.socicon-digg.bg-icon:hover,
.socicon-digg.color-icon.border-icon:hover {
  background-color: #1d1d1b;
}

.color-bg-icon.socicon-envato,
a.socicon-envato.bg-icon:hover,
.socicon-envato.color-icon.border-icon:hover {
  background-color: #597c3a;
}

.color-bg-icon.socicon-behance,
a.socicon-behance.bg-icon:hover,
.socicon-behance.color-icon.border-icon:hover {
  background-color: #00bf8f;
}

.color-bg-icon.socicon-delicious,
a.socicon-delicious.bg-icon:hover,
.socicon-delicious.color-icon.border-icon:hover {
  background-color: #31a9ff;
}

.color-bg-icon.socicon-deviantart,
a.socicon-deviantart.bg-icon:hover,
.socicon-deviantart.color-icon.border-icon:hover {
  background-color: #05cc47;
}

.color-bg-icon.socicon-forrst,
a.socicon-forrst.bg-icon:hover,
.socicon-forrst.color-icon.border-icon:hover {
  background-color: #5b9a68;
}

.color-bg-icon.socicon-play,
a.socicon-play.bg-icon:hover,
.socicon-play.color-icon.border-icon:hover {
  background-color: #5acdbd;
}

.color-bg-icon.socicon-zerply,
a.socicon-zerply.bg-icon:hover,
.socicon-zerply.color-icon.border-icon:hover {
  background-color: #9dbc7a;
}

.color-bg-icon.socicon-wikipedia,
a.socicon-wikipedia.bg-icon:hover,
.socicon-wikipedia.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-apple,
a.socicon-apple.bg-icon:hover,
.socicon-apple.color-icon.border-icon:hover {
  background-color: #a6b1b7;
}

.color-bg-icon.socicon-flattr,
a.socicon-flattr.bg-icon:hover,
.socicon-flattr.color-icon.border-icon:hover {
  background-color: #f67c1a;
}

.color-bg-icon.socicon-github,
a.socicon-github.bg-icon:hover,
.socicon-github.color-icon.border-icon:hover {
  background-color: #00bf8f;
}

.color-bg-icon.socicon-chimein,
a.socicon-chimein.bg-icon:hover,
.socicon-chimein.color-icon.border-icon:hover {
  background-color: #888688;
}

.color-bg-icon.socicon-friendfeed,
a.socicon-friendfeed.bg-icon:hover,
.socicon-friendfeed.color-icon.border-icon:hover {
  background-color: #2f72c4;
}

.color-bg-icon.socicon-newsvine,
a.socicon-newsvine.bg-icon:hover,
.socicon-newsvine.color-icon.border-icon:hover {
  background-color: #00b489;
}

.color-bg-icon.socicon-identica,
a.socicon-identica.bg-icon:hover,
.socicon-identica.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-bebo,
a.socicon-bebo.bg-icon:hover,
.socicon-bebo.color-icon.border-icon:hover {
  background-color: #ef1011;
}

.color-bg-icon.socicon-zynga,
a.socicon-zynga.bg-icon:hover,
.socicon-zynga.color-icon.border-icon:hover {
  background-color: #dc0606;
}

.color-bg-icon.socicon-steam,
a.socicon-steam.bg-icon:hover,
.socicon-steam.color-icon.border-icon:hover {
  background-color: #171a21;
}

.color-bg-icon.socicon-xbox,
a.socicon-xbox.bg-icon:hover,
.socicon-xbox.color-icon.border-icon:hover {
  background-color: #92c83e;
}

.color-bg-icon.socicon-windows,
a.socicon-windows.bg-icon:hover,
.socicon-windows.color-icon.border-icon:hover {
  background-color: #00bdf6;
}

.color-bg-icon.socicon-outlook,
a.socicon-outlook.bg-icon:hover,
.socicon-outlook.color-icon.border-icon:hover {
  background-color: #0072c6;
}

.color-bg-icon.socicon-coderwall,
a.socicon-coderwall.bg-icon:hover,
.socicon-coderwall.color-icon.border-icon:hover {
  background-color: #3e8dcc;
}

.color-bg-icon.socicon-tripadvisor,
a.socicon-tripadvisor.bg-icon:hover,
.socicon-tripadvisor.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-appnet,
a.socicon-appnet.bg-icon:hover,
.socicon-appnet.color-icon.border-icon:hover {
  background-color: #494949;
}

.color-bg-icon.socicon-goodreads,
a.socicon-goodreads.bg-icon:hover,
.socicon-goodreads.color-icon.border-icon:hover {
  background-color: #1abc9c;
}

.color-bg-icon.socicon-tripit,
a.socicon-tripit.bg-icon:hover,
.socicon-tripit.color-icon.border-icon:hover {
  background-color: #1982c3;
}

.color-bg-icon.socicon-lanyrd,
a.socicon-lanyrd.bg-icon:hover,
.socicon-lanyrd.color-icon.border-icon:hover {
  background-color: #3c80c9;
}

.color-bg-icon.socicon-slideshare,
a.socicon-slideshare.bg-icon:hover,
.socicon-slideshare.color-icon.border-icon:hover {
  background-color: #e68523;
}

.color-bg-icon.socicon-buffer,
a.socicon-buffer.bg-icon:hover,
.socicon-buffer.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-rss,
a.socicon-rss.bg-icon:hover,
.socicon-rss.color-icon.border-icon:hover {
  background-color: #f26109;
}

.color-bg-icon.socicon-vkontakte,
a.socicon-vkontakte.bg-icon:hover,
.socicon-vkontakte.color-icon.border-icon:hover {
  background-color: #4a76a8;
}

.color-bg-icon.socicon-disqus,
a.socicon-disqus.bg-icon:hover,
.socicon-disqus.color-icon.border-icon:hover {
  background-color: #2e9fff;
}

.color-bg-icon.socicon-houzz,
a.socicon-houzz.bg-icon:hover,
.socicon-houzz.color-icon.border-icon:hover {
  background-color: #7cc04b;
}

.color-bg-icon.socicon-mail,
a.socicon-mail.bg-icon:hover,
.socicon-mail.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-patreon,
a.socicon-patreon.bg-icon:hover,
.socicon-patreon.color-icon.border-icon:hover {
  background-color: #e44727;
}

.color-bg-icon.socicon-paypal,
a.socicon-paypal.bg-icon:hover,
.socicon-paypal.color-icon.border-icon:hover {
  background-color: #009cde;
}

.color-bg-icon.socicon-playstation,
a.socicon-playstation.bg-icon:hover,
.socicon-playstation.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-smugmug,
a.socicon-smugmug.bg-icon:hover,
.socicon-smugmug.color-icon.border-icon:hover {
  background-color: #acfd32;
}

.color-bg-icon.socicon-swarm,
a.socicon-swarm.bg-icon:hover,
.socicon-swarm.color-icon.border-icon:hover {
  background-color: #ffa633;
}

.color-bg-icon.socicon-triplej,
a.socicon-triplej.bg-icon:hover,
.socicon-triplej.color-icon.border-icon:hover {
  background-color: #e53531;
}

.color-bg-icon.socicon-yammer,
a.socicon-yammer.bg-icon:hover,
.socicon-yammer.color-icon.border-icon:hover {
  background-color: #1175c4;
}

.color-bg-icon.socicon-stackoverflow,
a.socicon-stackoverflow.bg-icon:hover,
.socicon-stackoverflow.color-icon.border-icon:hover {
  background-color: #fd9827;
}

.color-bg-icon.socicon-drupal,
a.socicon-drupal.bg-icon:hover,
.socicon-drupal.color-icon.border-icon:hover {
  background-color: #00598e;
}

.color-bg-icon.socicon-odnoklassniki,
a.socicon-odnoklassniki.bg-icon:hover,
.socicon-odnoklassniki.color-icon.border-icon:hover {
  background-color: #f48420;
}

.color-bg-icon.socicon-android,
a.socicon-android.bg-icon:hover,
.socicon-android.color-icon.border-icon:hover {
  background-color: #a4c639;
}

.color-bg-icon.socicon-meetup,
a.socicon-meetup.bg-icon:hover,
.socicon-meetup.color-icon.border-icon:hover {
  background-color: #e2373c;
}

.color-bg-icon.socicon-persona,
a.socicon-persona.bg-icon:hover,
.socicon-persona.color-icon.border-icon:hover {
  background-color: #e6753d;
}

.color-bg-icon.socicon-amazon,
a.socicon-amazon.bg-icon:hover,
.socicon-amazon.color-icon.border-icon:hover {
  background-color: #ff9900;
}

.color-bg-icon.socicon-ello,
a.socicon-ello.bg-icon:hover,
.socicon-ello.color-icon.border-icon:hover {
  background-color: #000000;
}

.color-bg-icon.socicon-mixcloud,
a.socicon-mixcloud.bg-icon:hover,
.socicon-mixcloud.color-icon.border-icon:hover {
  background-color: #314359;
}

.color-bg-icon.socicon-8tracks,
a.socicon-8tracks.bg-icon:hover,
.socicon-8tracks.color-icon.border-icon:hover {
  background-color: #122c4b;
}

.color-bg-icon.socicon-twitch,
a.socicon-twitch.bg-icon:hover,
.socicon-twitch.color-icon.border-icon:hover {
  background-color: #6441a5;
}

.color-bg-icon.socicon-airbnb,
a.socicon-airbnb.bg-icon:hover,
.socicon-airbnb.color-icon.border-icon:hover {
  background-color: #ff5a5f;
}

.color-bg-icon.socicon-pocket,
a.socicon-pocket.bg-icon:hover,
.socicon-pocket.color-icon.border-icon:hover {
  background-color: #ee4056;
}

a.social-icon {
  color: #b2b2b2;
}

a.border-icon {
  border: 1px solid #e4e4e4;
  padding: 11px 12px;
  margin-bottom: 4px;
  margin-top: 4px;
  border-radius: 0;
}

a.border-icon:before {
  line-height: 16px;
}

a.bg-icon {
  color: #ffffff;
  background-color: #e4e4e4;
  padding: 12px 14px;
  margin-bottom: 4px;
}

a.bg-icon:before {
  font-size: 12px;
  line-height: 16px;
}

.ds a.bg-icon {
  background-color: rgba(255, 255, 255, 0.05);
}

a.social-icon.color-icon:hover {
  color: #3a3d44;
}

a.social-icon.bg-icon:hover {
  color: #ffffff;
}

a.color-bg-icon {
  color: #ffffff;
  background-color: #3a3d44;
  margin-bottom: 4px;
  padding: 12px 13px;
}

a.color-bg-icon:before {
  font-size: 14px;
  line-height: 16px;
}

a.social-icon.color-bg-icon:hover {
  background-color: rgba(120, 120, 120, 0.2);
}

a.color-icon:hover {
  color: #3a3d44;
}

a.color-icon.border-icon:hover {
  color: #ffffff;
  background-color: $hive-primary;
  border-color: transparent;
}

.big-icons a.border-icon {
  padding: 15px 14px 16px;
}

.big-icons a.border-icon:before {
  font-size: 20px;
}

.demo-icon {
  display: inline-block;
  text-align: center;
  border-bottom: 1px solid rgba(120, 120, 120, 0.2);
  padding: 15px 20px 30px;
  margin-bottom: 30px;
}

.demo-icon span {
  display: block;
}

.demo-icon .name {
  font-size: 1.01em;
  font-weight: 700;
}

.demo-icon a[class*='socicon-'] {
  margin: 10px 0 0;
}

.demo-icon a[class*='socicon-']:before {
  font-size: 24px;
}

[class*='socicon-'].text-icon {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

[class*='socicon-'].text-icon:before {
  display: none;
}

.text-icon {
  text-transform: uppercase;
}

.share_buttons > span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 10px;
}
